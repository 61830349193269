import { useTranslation } from 'react-i18next';
import { SparkAnalyze } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { ColorType, Icon, Label, LabelType } from '../../_ui';
import { SubmitLoading } from '../../_ui/Skeleton/SubmitLoading.component';

export const SparkLoading = () => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col gap-6 w-[400px] p-5 justify-center items-center text-center'>
      <Icon src={SparkAnalyze} size={IconSize.SIZE_100x100} className='mt-5' />
      <Label text={t('spark_processing_header')} type={LabelType.H3} />
      <Label
        text={t('spark_processing_content')}
        type={LabelType.BODY3}
        color={ColorType.GREY6}
      />
      <SubmitLoading className='py-5' isTransparentBg />
    </div>
  );
};
