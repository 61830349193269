import { useMemo } from 'react';
import { useFetchThemeSettingsQuery } from '../../../services/env.api';
import { ButtonSize, ButtonType } from '../Button.component';
import { ColorType, getHexColorByType } from '../shared/Colors.g';
import './SubmitLoading.css';

interface InputProps {
  size?: ButtonSize | string;
  type?: ButtonType;
  className?: string;
  isTransparentBg?: boolean;
}

export const SubmitLoading = ({
  size = '',
  className,
  type = ButtonType.PRIMARY,
  isTransparentBg = false,
}: InputProps) => {
  const { data: theme } = useFetchThemeSettingsQuery();

  const classes =
    size === ButtonSize.SMALL ? `w-16 ${className}` : `${className}`;

  const { bgColor, dotColor1, dotColor2 } = useMemo(() => {
    switch (type) {
      case ButtonType.PRIMARY:
      case ButtonType.SECONDARY:
        return {
          bgColor: theme?.brand_0,
          dotColor1: theme?.brand_2,
          dotColor2: theme?.brand_1,
        };
      case ButtonType.TERTIARY:
        return {
          bgColor: getHexColorByType(ColorType.GREY1),
          dotColor1: getHexColorByType(ColorType.GREY6),
          dotColor2: getHexColorByType(ColorType.GREY4),
        };
      case ButtonType.OUTLINE:
        return {
          bgColor: getHexColorByType(ColorType.GREY0),
          dotColor1: getHexColorByType(ColorType.GREY4),
          dotColor2: getHexColorByType(ColorType.GREY3),
        };
      case ButtonType.DESTRUCTIVE:
        return {
          bgColor: getHexColorByType(ColorType.NEGATIVE0),
          dotColor1: getHexColorByType(ColorType.NEGATIVE),
          dotColor2: getHexColorByType(ColorType.NEGATIVE),
        };
      default:
        return {
          bgColor: theme?.brand_0,
          dotColor1: theme?.brand_2,
          dotColor2: theme?.brand_1,
        };
    }
  }, [type]);
  return (
    <div
      className={`flex flex-row rounded items-center ${size} ${classes}`}
      style={{ backgroundColor: isTransparentBg ? 'transparent' : bgColor }}
    >
      <div
        className='flex my-0 mx-auto w-2 aspect-square rounded-full loading-dots'
        style={{
          ['--loadingDotColor1' as any]: dotColor1,
          ['--loadingDotColor2' as any]: dotColor2,
        }}
      />
    </div>
  );
};
