import { Trans } from 'react-i18next';
import { ErrorWarningCircle } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import {
  ColorType,
  Icon,
  Label,
  LabelType,
  getHexColorByType,
} from '../../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';

interface IProps {
  isWarning: boolean;
  label: string;
  error: string;
  breakerName: any;
}

const LimitExceededWarning = ({
  error,
  isWarning,
  label,
  breakerName,
}: IProps) => {
  if (!isWarning) return null;
  const renderTrigger = () => (
    <div
      style={{
        border: '1px solid #C62828', // Negative color tailwind config
        background: getHexColorByType(ColorType.NEGATIVE0),
        borderRadius: '16px',
      }}
      className='px-1 flex gap-2 items-center box-border'
    >
      <Icon src={ErrorWarningCircle} size={IconSize.SIZE_14x14} />
      <Label
        text={label}
        type={LabelType.LABEL_S_MEDIUM}
        color={ColorType.NEGATIVE}
      />
    </div>
  );

  const renderContent = () => (
    <span style={{ fontFamily: 'Inter' }}>
      <Trans
        i18nKey={error}
        components={{
          breakerName,
        }}
      />
    </span>
  );
  return (
    <Tooltip
      placement='top'
      tipOffset={{
        mainAxis: 10,
        crossAxis: 0,
      }}
    >
      <TooltipTrigger>{renderTrigger()}</TooltipTrigger>
      <TooltipContent>{renderContent()}</TooltipContent>
    </Tooltip>
  );
};

export default LimitExceededWarning;
