import { useTranslation } from 'react-i18next';
import { ErrorWarningtraiangle } from '../../../assets/icons';
import { Card, ColorType, Icon, Label, LabelType } from '../../_ui';

export const PMEditMessage = () => {
  const { t } = useTranslation();

  return (
    <div className='w-full mt-2 mb-4'>
      <Card className='border-2 border-warning mb-2' bg='bg-warning0'>
        <div className='flex flex-row gap-3'>
          <div className='flex flex-row'>
            <Icon color={ColorType.WARNING} src={ErrorWarningtraiangle} />
          </div>
          <div className='flex flex-col'>
            <Label
              text={t('cant_edit_pm_charger')}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
