import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { formatDateTimeRange } from '../../../utils/Date.Util';
import { ColorType, Divider, Label, LabelType } from '../../_ui';

export const SparkResultViewer = () => {
  const { t } = useTranslation();

  const sparkResultData = sessionStorage.getItem('sparkResultData');
  const parsedSparkData = sparkResultData ? JSON.parse(sparkResultData) : null;

  const charger = sessionStorage.getItem('charger');
  const parsedCharger = charger ? JSON.parse(charger) : null;

  const dateTimeRange = sessionStorage.getItem('dateTimeRange');
  const parsedDateTimeRange = dateTimeRange ? JSON.parse(dateTimeRange) : null;

  const renderDiagnosticSummery = () => {
    if (!parsedSparkData?.diagnostic) {
      return null;
    }
    return (
      <div className='flex flex-col gap-1'>
        <Label
          type={LabelType.LABEL_M}
          text={t('spark_result_header_1')}
          color={ColorType.BLACK2}
        />
        <Label
          type={LabelType.BODY3}
          text={parsedSparkData.diagnostic}
          color={ColorType.GREY6}
        />
      </div>
    );
  };

  const renderIssues = () => {
    if (!parsedSparkData.issues) {
      return null;
    }
    return (
      <div className='flex flex-col gap-1'>
        <Label
          type={LabelType.LABEL_M}
          text={t('spark_result_header_2')}
          color={ColorType.BLACK2}
        />
        <Label
          type={LabelType.BODY3}
          text={parsedSparkData.issues}
          color={ColorType.GREY6}
        />
      </div>
    );
  };

  const renderActionableInsights = () => {
    if (!parsedSparkData?.insights?.length) {
      return null;
    }
    return (
      <div className='flex flex-col gap-1'>
        <Label
          type={LabelType.LABEL_M}
          text={t('spark_result_header_3')}
          color={ColorType.BLACK2}
        />
        {parsedSparkData.insights.map((insight: string, indexNo: number) => {
          return (
            <div key={uniqueId()} className='flex flex-row gap-2'>
              <Label
                type={LabelType.BODY3}
                text={`${indexNo + 1}. `}
                color={ColorType.GREY6}
              />
              <Label
                type={LabelType.BODY3}
                text={insight}
                color={ColorType.GREY6}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderReminders = () => {
    if (!parsedSparkData?.reminders?.length) {
      return null;
    }
    return (
      <div className='flex flex-col gap-1'>
        <Label
          type={LabelType.LABEL_S_MEDIUM}
          text={t('spark_result_header_4')}
          color={ColorType.BLACK2}
        />
        {parsedSparkData.reminders.map((insight: string, indexNo: number) => {
          return (
            <div key={uniqueId()} className='flex flex-row gap-2'>
              <Label
                type={LabelType.BODY3}
                text={`${indexNo + 1}. `}
                color={ColorType.GREY6}
              />
              <Label
                type={LabelType.BODY3}
                text={insight}
                color={ColorType.GREY6}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderResultResult = () => {
    if (!sparkResultData) {
      return null;
    }
    return (
      <div className='flex flex-col gap-4'>
        {renderDiagnosticSummery()}
        {renderIssues()}
        {renderActionableInsights()}
        {renderReminders()}
      </div>
    );
  };

  if (!parsedSparkData) {
    return <div>{t('spark_results_not_found')}</div>;
  }

  return (
    <div className='flex flex-col gap-4 pb-4 m-5' id='print-container'>
      <div className='flex flex-row gap-8'>
        <Label
          text={t('charger_id_label')}
          type={LabelType.LABEL_S}
          className='min-w-[80px]'
        />
        <Label text={parsedCharger.name} type={LabelType.BODY3} />
      </div>
      <div className='flex flex-row gap-8'>
        <Label
          text={t('spark_time_range_label')}
          type={LabelType.LABEL_S}
          className='min-w-[80px]'
        />
        <Label
          text={formatDateTimeRange([
            new Date(parsedDateTimeRange.selDates[0]),
            new Date(parsedDateTimeRange.selDates[1]),
          ])}
          type={LabelType.BODY3}
        />
      </div>
      <Divider />
      {renderResultResult()}
    </div>
  );
};
