import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorWarningtraiangle, Info } from '../../../assets/icons';
import { payterContext } from '../../../hooks/usePayter';
import {
  useFetchTerminalsQuery,
  useUnlinkPayterAccountMutation,
  useUpdatePayterIdentifierMutation,
} from '../../../services/payouts.api';
import {
  ButtonSize,
  ButtonType,
  ColorType,
  Label,
  LabelType,
  MODAL_TYPES,
  MenuItem,
  Snackbar,
  getHexColorByType,
  useGlobalModalContext,
} from '../../_ui';
import {
  AlertPosition,
  AlertType,
} from '../../_ui/snack-bar/Snack-Bar.component';

const UnlinkAccount = () => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const payterConsumer = useContext(payterContext);

  const { company } = payterConsumer;

  const [updatePayterIdentifier] = useUpdatePayterIdentifierMutation();

  const { data: terminalData } = useFetchTerminalsQuery({});

  const handleUnlinkAccount = () => {
    updatePayterIdentifier({
      body: {
        ...company,
        payterAccountIdentifier: '',
      },
      toast: {
        successTrans: 'successfully_unlinked_payter_account',
        failedTrans: 'unable_to_unlink_payter_account',
      },
    });
  };

  const showFailedToast = (message: string) => {
    Snackbar.show({
      message,
      position: AlertPosition.BOTTOM_LEFT,
      alertType: AlertType.DEFAULT,
      messageLabelColor: ColorType.BLACK,
      messageLabelType: LabelType.LABEL_S_MEDIUM,
      duration: 5000,
      icon: Info,
      height: 'h-auto',
      iconColor: getHexColorByType(ColorType.NEGATIVE),
    });
  };

  const handleClose = (result: any) => {
    if (result?.isError) {
      showFailedToast(t('unable_to_unlink_terminal_atleast_one_connected'));
    }
    if (result?.isSuccess) {
      handleUnlinkAccount();
    }
  };

  const mutationObject = useMemo(() => {
    if (terminalData?.totalCount) {
      return {
        mutationHook: useUnlinkPayterAccountMutation,
        onClose: handleClose,
        mutationParams: {},
      };
    }
    return {
      mutationHook: useUpdatePayterIdentifierMutation,
      mutationParams: {
        body: {
          ...company,
          payterAccountIdentifier: '',
        },
        toast: {
          successTrans: 'successfully_unlinked_payter_account',
          failedTrans: 'unable_to_unlink_payter_account',
        },
      },
    };
  }, [terminalData]);

  const unlinkPayterAccount = () => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title: t('are_you_sure'),
      icon: ErrorWarningtraiangle,
      iconColor: ColorType.NEGATIVE,
      ...mutationObject,
      message: (
        <>
          <Label
            text={t('current_domain_name', {
              domainName: company?.payterAccountIdentifier,
            })}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.BLACK}
            className='mb-4 mt-2'
          />
          <Label
            text={t('unlink_connected_payter_desc1')}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
          <Label
            className='mt-4'
            text={t('unlink_connected_payter_desc2')}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </>
      ),
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('unlink_payter_account'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          disableDelayTime: 3000,
          disableDelayLabel: t('wait_3_sec'),
          className: 'min-w-fit w-20',
          isSubmit: true,
        },
      ],
    });
  };

  return (
    <MenuItem
      label={t('unlink_payter_account')}
      onClick={unlinkPayterAccount}
      dataTestId='reset_terminal'
      labelColor={ColorType.NEGATIVE}
    />
  );
};

export default UnlinkAccount;
