import { useTranslation } from 'react-i18next';
import { Calculator } from '../../assets/icons';
import { ColorType, Label, LabelType, Pill } from '../_ui';
import { SessionDetailProps } from './SessionDetail.component';

const SessionViaPaymentTerminal = ({ sessionData }: SessionDetailProps) => {
  const { paymentTerminalSerialNumber, paymentTerminalSessionId } = sessionData;
  const { t } = useTranslation();

  const renderTerminal = () => {
    return (
      <Pill
        iconLeft={Calculator}
        labelClass='mt-[1px]'
        label={`${paymentTerminalSerialNumber}`}
      />
    );
  };

  return (
    <div>
      <Label
        text={t('payment_terminal')}
        type={LabelType.BODY3}
        color={ColorType.BLACK}
      />
      <div className='my-2'>{renderTerminal()}</div>
      <div className='flex items-center'>
        <Label
          text={`${t('session_id')}:`}
          type={LabelType.LABEL_XS_MEDIUM}
          color={ColorType.GREY6}
        />
        <Label
          className='ml-1'
          text={paymentTerminalSessionId}
          type={LabelType.BODY4}
          color={ColorType.GREY6}
        />
      </div>

      {/* Masked pan is not part of current ticket butrequire in future */}

      {/* <div className='flex items-center'>
        <Label
          text={`${t('masked_pan')}:`}
          type={LabelType.LABEL_XS_MEDIUM}
          color={ColorType.GREY6}
        />
        <Label
          className='ml-1'
          text='1212432******949'
          type={LabelType.BODY3_G4}
          color={ColorType.GREY6}
        />
      </div> */}
    </div>
  );
};

export default SessionViaPaymentTerminal;
