import _ from 'lodash';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Info, Vehicle } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { useAuth } from '../../hooks/useAuth';
import { useCompany } from '../../hooks/useCompany';
import { useFetchCompanyDetailQuery } from '../../services/company.api';
import {
  useEditRfidCardMutation,
  useLazyValidateRfidCardQuery,
  useRegisterRfidCardMutation,
} from '../../services/rfid.api';
import { useFetchVehiclesQuery } from '../../services/vehicles.api';
import { getWhiteLabellingCompanyName } from '../../stores/selectors/env.selector';
import { RfidCard } from '../../stores/types/rfidcard.interface';
import { formatCardNumber } from '../Vehicles/utils';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Dropdown,
  Icon,
  Label,
  LabelType,
  useGlobalModalContext,
} from '../_ui';
import { Form } from '../_ui/Form.component';
import { FormInput, InputType, ValidationType } from '../_ui/Input.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';

export interface InputProps {
  rfid?: RfidCard;
  edit: boolean;
}
export const RfidEditor = ({ rfid, edit }: InputProps) => {
  const ERROR_CODE_RFID_ALREADY_USED = 409;
  const ERROR_CODE_RFID_NOT_FOUND = 403;
  const { t } = useTranslation();
  const auth = useAuth();
  const { hideModal } = useGlobalModalContext();
  const [_rfid, setRfid] = useState(
    rfid ? _.cloneDeep(rfid) : ({} as RfidCard),
  );
  const whiteLabelCompanyName: any = useSelector(getWhiteLabellingCompanyName);
  const { userScope } = useCompany();
  const { data: company } = useFetchCompanyDetailQuery({
    id: auth.user.attributes.profile?.lastActiveCompanyId,
    scope: userScope!,
  });
  const [registerRfidCard, registerRfidQueryResult] =
    useRegisterRfidCardMutation();
  const [editRfidCardData, editRfidQueryResult] = useEditRfidCardMutation();
  const [
    triggerRfidCardValidate,
    {
      error: rfidCardValidationError,
      isError: isRfidCardNotValid,
      isLoading: isRFIDCardLoading,
    },
  ] = useLazyValidateRfidCardQuery();
  const { vehicles } = useFetchVehiclesQuery(undefined, {
    selectFromResult: (endPoint) => ({
      vehicles: endPoint.data ? endPoint.data : [],
    }),
  });

  const vehicleDropdown = useMemo(() => {
    return vehicles.map((obj) => ({
      id: obj.vehicleId,
      label: obj.vehicleName,
      selected: obj.vehicleId === _rfid.vehicleId,
      secondLabel: obj.rfids.length > 0 && t('rfid_already_assigned'),
    }));
  }, [vehicles, _rfid.vehicleId]);

  const onButtonValidation = () => {
    return !isRFIDCardLoading && !isRfidCardNotValid;
  };

  const handleVehicleDropdownClick = (item: any) => {
    setRfid({ ..._rfid, vehicleId: item.id, vehicleName: item.label });
  };

  const handleRfidCardNumberChange = (value: any) => {
    triggerRfidCardValidate({ ..._rfid, cardNumber: value });

    setRfid({ ..._rfid, cardNumber: formatCardNumber(value) });
  };

  const handleNoteChange = (value: any) => {
    setRfid({ ..._rfid, note: value });
  };

  const onClickSave = () => {
    if (edit) {
      editRfidCardData(_rfid);
    } else {
      registerRfidCard({
        ..._rfid,
        virtualUserId:
          company && company.virtualUserIds ? company.virtualUserIds[0] : '',
      });
    }
  };

  const renderRdifCardError = () => {
    const errorObj: any = rfidCardValidationError;
    if (errorObj && errorObj.status === ERROR_CODE_RFID_ALREADY_USED) {
      return (
        <div className=''>
          <Label
            text={t('rfid_card_duplicate_validation')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE}
          />
        </div>
      );
    }
    if (
      errorObj &&
      errorObj.status === ERROR_CODE_RFID_NOT_FOUND &&
      _rfid &&
      _rfid.cardNumber
    ) {
      return (
        <div className=''>
          <Label
            text={t('rfid_card_invalid_validation')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE}
          />
        </div>
      );
    }
  };
  return (
    <Form
      onSubmit={onClickSave}
      queryResult={edit ? editRfidQueryResult : registerRfidQueryResult}
      onQuerySuccess={() => hideModal()}
      onQueryFailed={() => hideModal()}
    >
      <div className='divide-y divide-grey3'>
        <div className='text-sm text-black font-normal pb-4'>
          <Trans
            i18nKey='rfid_editor_message'
            values={{ networkName: whiteLabelCompanyName }}
          />
        </div>
        <div className='pt-4'>
          <div className='flex flex-col gap-2'>
            <FormInput
              label={t('rfid_card_number')}
              placeholder={t('rfid_card_number')}
              showErrorIcon
              onChange={(event: any) =>
                handleRfidCardNumberChange(event.target.value)
              }
              defaultValue={_rfid.cardNumber}
              validaitonQueryResult={isRfidCardNotValid}
              validationType={ValidationType.NOT_EMPTY}
              inputType={InputType.RFID}
              width='500px'
              disabled={edit}
            />
            {renderRdifCardError()}
            <div className='flex flex-col gap-2'>
              <div className='flex flex-row gap-1 items-center'>
                <Label
                  text={t('rfid_assigned_vehicle')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                />
                <Label text={`(${t('optional')})`} type={LabelType.BODY2} />
                <Tooltip>
                  <TooltipTrigger>
                    <Icon src={Info} size={IconSize.SIZE_20x20} />
                  </TooltipTrigger>
                  <TooltipContent>
                    <Label
                      text={t('rfid_assigned_vehicle_hint')}
                      type={LabelType.BODY3}
                      color={ColorType.WHITE}
                    />
                  </TooltipContent>
                </Tooltip>
              </div>
              <Dropdown
                placeholder={t('rfid_assigned_vehicle_placeholder')}
                placeholderLabelType={LabelType.DROPDOWN_HEADER}
                items={vehicleDropdown}
                headerWidth='500px'
                contentDivWidth={500}
                showPillHeader
                pillIsButton={false}
                pillIcon={Vehicle}
                pillIconAlt={t('vehicles')}
                onItemClick={handleVehicleDropdownClick}
                showFooter={vehicleDropdown?.length === 0}
                renderFooter={
                  vehicleDropdown?.length === 0 && (
                    <div className='flex pl-2 pr-12 h-12 text-left items-center text-sm text-grey4 font-normal'>
                      <Trans
                        i18nKey='rfid_no_assigned_vehicle_message'
                        values={{ company: whiteLabelCompanyName }}
                      />
                    </div>
                  )
                }
                contentDivHeight={300}
              />
            </div>
            <div className='flex flex-col gap-2'>
              <div className='flex flex-row gap-1 items-baseline'>
                <Label
                  text={t('rfid_note')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                />
                <Label text={`(${t('optional')})`} type={LabelType.BODY2} />
              </div>
              <FormInput
                placeholder={t('rfid_note_placeholder')}
                showErrorIcon
                onChange={(event: any) => handleNoteChange(event.target.value)}
                defaultValue={_rfid.note}
                inputType={InputType.TEXT}
                width='500px'
              />
            </div>
          </div>
          <div className='pt-4 pb-5'>
            <div className='h-12'>
              <Button
                label={t('save')}
                type={ButtonType.PRIMARY}
                disabled={!onButtonValidation()}
                size={ButtonSize.MEDIUM_FULL}
                isSumbit
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
