import { useTranslation } from 'react-i18next';
import { Info } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { ColorType, Icon, Label, LabelType } from '../../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';

interface IProps {
  message: string;
  className: string;
  show?: boolean;
}
const NonPowerManagedWarning = ({
  message,
  className = '',
  show = false,
}: IProps) => {
  const { t } = useTranslation();
  return show ? (
    <Tooltip
      placement='top'
      tipOffset={{
        mainAxis: 10,
        crossAxis: 0,
      }}
      enablePopover={false}
    >
      <TooltipTrigger>
        <Icon
          src={Info}
          color={ColorType.WARNING}
          size={IconSize.SIZE_20x20}
          className={className}
        />
      </TooltipTrigger>
      <TooltipContent>
        <Label text={message} type={LabelType.BODY3} color={ColorType.WHITE} />
      </TooltipContent>
    </Tooltip>
  ) : null;
};
export default NonPowerManagedWarning;
