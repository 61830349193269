import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { More, breaker, electricMeter } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { USER_ROLE, useAuth } from '../../hooks';
import { useAllowedFeatures } from '../../hooks/useFeaturePersonalization';
import { useDeleteUnmanagedLoadMutation } from '../../services/pm.api';
import { SCOPE_TYPE } from '../../services/utils';
import {
  Breaker,
  UnmanagedLoad as UnmanagedLoadObj,
} from '../../stores/types/pm.interface';
import {
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  Menu,
  MenuItem,
  useGlobalModalContext,
} from '../_ui';
import { Limit } from './LocationDetailsHeader/Limit';
import { Phase } from './LocationDetailsHeader/Phase';
import { UnmanagedLoadDetailsModal } from './UnmanagedLoadDetailsModal';
import { checkIfLLWiring } from './utils';

interface UnmanagedLoadProps {
  data: { unmanagedLoadData: UnmanagedLoadObj; breakerData: Breaker };
  index?: number;
  isSplitPhase: boolean;
  editLoad: () => void;
  showWarning: Function;
  locationId: string;
}

export const UnmanagedLoad = ({
  data,
  index,
  isSplitPhase,
  editLoad,
  showWarning,
  locationId,
}: UnmanagedLoadProps) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [companyId] = useQueryParam('companyId', StringParam);
  const [deleteUnmanagedLoad] = useDeleteUnmanagedLoadMutation();
  const { allowPowermanEdit } = useAllowedFeatures();

  const handleViewUnmanagedLoadDetailsBtn = (unmanagedLoadModalData: {
    unmanagedLoad: UnmanagedLoadObj;
    breaker: Breaker;
    isSplitPhase: boolean;
  }) => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('unmanaged_load_modal_title'),
      width: '540px',
      height: 'max-content',
      shouldCloseOnOverlayClick: false,
      onRenderBody: () => (
        <UnmanagedLoadDetailsModal data={unmanagedLoadModalData} />
      ),
    });
  };

  const handleDeleteUnmanagedLoad = () => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      title: `${t('pm_delete_load_menu')}?`,
      width: '400px',
      height: 'max-content',
      message: t('pm_delete_load_warning_2'),
      messageHeader: t('pm_delete_load_warning_1'),
      messageHeaderType: LabelType.LABEL_S,
      messageHeaderColor: ColorType.GREY6,
      iconColor: ColorType.NEGATIVE,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
        },
        {
          label: t('delete'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          onClick: () => {
            deleteUnmanagedLoad({
              breakerId: data.breakerData.id,
              deviceId: data.unmanagedLoadData.id,
              scope: SCOPE_TYPE.COMPANY,
              companyId: companyId || '',
            });
          },
          dataTestId: 'deleteConfirm',
        },
      ],
    });
  };

  return (
    <div
      className='px-4 py-2'
      style={
        data.breakerData.dedicatedBreaker
          ? {}
          : {
              borderBottom: '1px solid #D1D6DB',
              borderTop: index === 0 ? '1px solid #D1D6DB' : '',
            }
      }
    >
      <div className='flex justify-between items-center'>
        <div className='flex'>
          <div className='pr-2 pt-1'>
            <Icon src={electricMeter} />
          </div>
          <div className='flex flex-col'>
            <Label
              text={data.unmanagedLoadData.name}
              type={LabelType.LABEL_M_MEDIUM}
              color={ColorType.BLACK}
              dataTestId={data.unmanagedLoadData.name}
            />
            <div className='flex gap-x-4'>
              <div>
                <Limit value={data.unmanagedLoadData.fixedAmperage} />
              </div>
              <div className='flex flex-row gap-x-4'>
                {!checkIfLLWiring(
                  isSplitPhase,
                  data.unmanagedLoadData.wiringPhase,
                ) && (
                  <Phase
                    value={data.unmanagedLoadData.wiringPhase}
                    isElectricSupply={false}
                  />
                )}
                <Label
                  type={LabelType.LABEL_XS}
                  color={ColorType.GREY5}
                  text={t('unmanaged_load')}
                  className='flex items-center pt-1'
                />
                {data.breakerData.dedicatedBreaker && (
                  <div className='flex flex-row gap-x-1.5 items-center'>
                    <Icon src={breaker} size={IconSize.SIZE_20x20} />
                    <Label
                      text={`${data.breakerData.breakerRating} A dedicated breaker`}
                      type={LabelType.LABEL_XS}
                      color={ColorType.GREY5}
                      className='pt-1'
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Menu
          contentClassName='w-[204px]'
          placement='bottom-end'
          icon={More}
          iconAlt={t('icon_alt_more_options')}
          dataTestId='menuWrapper'
        >
          <MenuItem
            label={t('unmanaged_load_popup_menu_label_1')}
            onClick={() =>
              handleViewUnmanagedLoadDetailsBtn({
                unmanagedLoad: data.unmanagedLoadData,
                breaker: data.breakerData,
                isSplitPhase,
              })
            }
          />
          {(auth.role === USER_ROLE.SUPPORT || allowPowermanEdit) && (
            <>
              <MenuItem
                label={t('pm_edit_load_details')}
                dataTestId='editLoad'
                onClick={() => {
                  showWarning(locationId, (actionValue: boolean) => {
                    if (actionValue) {
                      editLoad();
                    }
                  });
                }}
              />
              <MenuItem
                label={t('pm_delete_load_menu')}
                labelColor={ColorType.NEGATIVE}
                dataTestId='deleteLoad'
                onClick={() => {
                  showWarning(locationId, (actionValue: boolean) => {
                    if (actionValue) {
                      handleDeleteUnmanagedLoad();
                    }
                  });
                }}
              />
            </>
          )}
        </Menu>
      </div>
    </div>
  );
};
