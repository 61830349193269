/* eslint-disable react/jsx-wrap-multilines */
import { enUS, es, fr } from 'date-fns/locale';
import posthog from 'posthog-js';
import { useEffect, useRef } from 'react';
import { registerLocale } from 'react-datepicker';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import './App.css';
import { Login } from './components/Login/Login.component';
import { NoMatch } from './components/NoMatch/NoMatch.component';
import { NoRoutePermission } from './components/NoPermissionAccess/NoRoutePermission';
import {
  AppHeader,
  AppSideBar,
  ColorType,
  Label,
  LabelType,
  Scrollable,
  Wiki,
  useGlobalModalContext,
} from './components/_ui';
import NoCompanyAccess from './components/_ui/No-company-access.component';
import { Overview } from './components/overview';
import { Register } from './components/register/Register.component';
import { USER_ROLE, useAuth } from './hooks';
import {
  RoutePath,
  multiCompanyRoutes,
  routes,
  supportUserMultiCompanyRoutes,
} from './routes';
import {
  useFetchDeploymentQuery,
  useFetchEnvironmentQuery,
} from './services/deployment.api';
import {
  useFetchReceiptSettingsQuery,
  useFetchThemeSettingsQuery,
} from './services/env.api';
import {
  GA_LOCAL_STORAGE_KEY_CLIENT_ID,
  GA_LOCAL_STORAGE_KEY_SESSION_ID,
} from './utils/GoogleAnalytics';

import { SparkResultViewer } from './components/Charger/charger-detail/SparkResultViewer.component';
import ChargerMessageDetaits from './components/Charger/charger-message/ChargerMessageDetails.component';
import { SubmitLoading } from './components/_ui/Skeleton/SubmitLoading.component';
import { GlobalModal } from './components/_ui/modal';
import { CompanyProvider, useCompany } from './hooks/useCompany';
import {
  AllowedFeaturesProvider,
  useAllowedFeatures,
} from './hooks/useFeaturePersonalization';
import { i18n } from './services/translation/i18n';

function App() {
  const auth = useAuth();
  const { data: theme } = useFetchThemeSettingsQuery();
  const { data: deploymentData } = useFetchDeploymentQuery();
  const receiptSettingResponse = useFetchReceiptSettingsQuery();
  const { data: env } = useFetchEnvironmentQuery();
  const { t } = useTranslation();

  useEffect(() => {
    if (!ReactGA.isInitialized && env?.googleAnalytics.measurementId) {
      ReactGA.initialize(env.googleAnalytics.measurementId);

      // client id
      ReactGA.ga((tracker: any) => {
        tracker.get('clientId');
        localStorage.setItem(
          GA_LOCAL_STORAGE_KEY_CLIENT_ID,
          tracker.get('clientId'),
        );
      });

      ReactGA.gtag(
        'get',
        env.googleAnalytics.measurementId,
        'session_id',
        (id: any) => {
          localStorage.setItem(GA_LOCAL_STORAGE_KEY_SESSION_ID, id);
        },
      );
    }
  }, [env]);

  useEffect(() => {
    registerLocale('es', es);
    registerLocale('fr', fr);
    registerLocale('en-US', enUS);
    if (document) {
      document.documentElement.lang = i18n.language;
    }
  }, []);

  useEffect(() => {
    if (deploymentData?.postHogApiKey && deploymentData?.postHogApiHost) {
      posthog.init(deploymentData.postHogApiKey, {
        api_host: deploymentData.postHogApiHost,
      });
    }
  }, [deploymentData?.postHogApiKey, deploymentData?.postHogApiHost]);

  useEffect(() => {
    if (receiptSettingResponse.isSuccess) {
      // tab title will be determined based on the domain
      document.title = receiptSettingResponse.data.companyName || '';
      const descMeta = document.getElementsByName('description');
      if (descMeta && descMeta.length > 0) {
        descMeta[0].textContent = receiptSettingResponse.data.companyName || '';
      }
      const touchIcon = document.getElementById(
        'touchicon',
      ) as HTMLAnchorElement | null;
      if (touchIcon) {
        touchIcon.href = `/assets?resourceId=favicon&domainName=${window.location.hostname}`;
      }
    }
  }, [receiptSettingResponse]);

  useEffect(() => {
    const element = document.getElementById(
      'favicon',
    ) as HTMLAnchorElement | null;
    if (element) {
      element.href = `/assets?resourceId=favicon&domainName=${window.location.hostname}`;
    }
  }, []);

  const Layout = (scrollbarRef: any) => {
    return (
      <div className='flex flex-row flex-nowrap w-full min-h-screen'>
        <div className='flex flex-none w-0 xl:w-60 invisible xl:visible h-screen sticky top-0 z-10'>
          <AppSideBar />
        </div>
        <Scrollable ref={scrollbarRef}>
          <div className='w-full bg-web_bg min-h-screen'>
            <div className='sticky top-0 z-[100] w-full'>
              <AppHeader />
            </div>
            <div className='p-[24px] place-self-center m-auto'>
              <Outlet />
            </div>
          </div>
        </Scrollable>
      </div>
    );
  };

  // Function checks user's auth as well as user's company access
  const ProtectedLayout = () => {
    const location = useLocation();
    const { hideModal } = useGlobalModalContext();
    const scrollbarRef = useRef(null);
    const {
      companyId,
      companies: companylist,
      isMultiViewActive,
    } = useCompany();
    const { allowMultiCompanyView } = useAllowedFeatures();

    useEffect(() => {
      // hide the modal if users click back button on browser
      hideModal();
      // scroll window to 0,0 after path changed
      scrollbarRef?.current && (scrollbarRef.current as any).scrollToTop();
    }, [location.pathname]);

    // Check user auth permission
    if (!auth.user) {
      return <Navigate to='/login' state={{ from: location }} replace />;
    }

    // Show loading dots until we get company list
    if (!companylist) {
      return (
        <div className='h-screen bg-[#E5E5E5]'>
          <div className='flex flex-col items-center gap-4 inset-2/4 fixed'>
            <SubmitLoading />
            <Label
              text={t('loading_dashboard')}
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.GREY4}
              className='w-max'
            />
          </div>
        </div>
      );
    }

    // User is already logged in & directly opens the root page
    if (location.pathname === '/' && !companyId) {
      return (
        <Navigate
          to={`/${RoutePath.OVERVIEW}?companyId=${
            auth.user?.attributes?.profile?.lastActiveCompanyId || ''
          }`}
          replace
        />
      );
    }

    // copyed link is valid.
    if (companyId && companylist?.find((c) => c.id === companyId)) {
      if (companyId !== auth?.user?.attributes?.profile?.lastActiveCompanyId) {
        auth.updateCompanyId(companyId, false);
      }
      return Layout(scrollbarRef);
    }

    // User have no access to any company
    if (location.pathname === `/${RoutePath.NO_ACCESS}`) {
      return Layout(scrollbarRef);
    }

    if (companyId?.toUpperCase() === 'ALL') {
      // For support users and non support users with multicompanyview access should be
      // able to access the allowed routes
      const isPathAllowed = (
        auth.role === USER_ROLE.SUPPORT
          ? supportUserMultiCompanyRoutes
          : multiCompanyRoutes
      )?.find(
        (route) =>
          route === location.pathname ||
          // regular expression for '/chargers/:chargerId' match
          location.pathname.match(/^\/chargers\/([^/]+)$/),
      );

      // Redirect to NOT_FOUND if authorization or path is not allowed
      if (!allowMultiCompanyView || !isPathAllowed) {
        return <Navigate to={RoutePath.NOT_FOUND} replace />;
      }
    }

    if (isMultiViewActive) {
      return Layout(scrollbarRef);
    }

    // not allowed to see particular route
    return <Navigate to='/noRoutePermission' replace />;
  };

  if (!deploymentData || !theme) {
    return null;
  }
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <CompanyProvider>
        <AllowedFeaturesProvider>
          <GlobalModal>
            <Routes>
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/wiki' element={<Wiki />} />
              <Route
                path='/noRoutePermission'
                element={<NoRoutePermission />}
              />
              <Route
                path={RoutePath.CHARGER_MESSAGE}
                element={<ChargerMessageDetaits />}
              />
              <Route
                path={RoutePath.SPARK_RESULTS}
                element={<SparkResultViewer />}
              />
              <Route path='/' element={<ProtectedLayout />}>
                <Route index element={<Overview />} />
                <Route
                  key={RoutePath.NO_ACCESS}
                  path={RoutePath.NO_ACCESS}
                  element={<NoCompanyAccess />}
                />

                {routes
                  .filter(
                    (route) =>
                      route.path !== RoutePath.NO_ACCESS &&
                      route.path !== RoutePath.NOT_FOUND,
                  )
                  .map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<route.component />}
                    />
                  ))}
              </Route>
              <Route
                key={RoutePath.NOT_FOUND}
                path={RoutePath.NOT_FOUND}
                element={<NoMatch />}
              />
              <Route path='*' element={<NoMatch />} />
            </Routes>
          </GlobalModal>
        </AllowedFeaturesProvider>
      </CompanyProvider>
    </QueryParamProvider>
  );
}

export default App;
