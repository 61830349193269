import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import { USER_ROLE, useAuth } from '../../hooks';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { RoutePath } from '../../routes';
import { useLazyFetchCompaniesQuery } from '../../services/company.api';
import { SCOPE_TYPE } from '../../services/utils';
import { getWhiteLabellingCompanyName } from '../../stores/selectors/env.selector';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { getbrandname } from '../../utils/SearchParam.Utils';
import { ColorType, getHexColorByType } from '../_ui';
import { Label, LabelType } from '../_ui/Label.component';
import { SubmitLoading } from '../_ui/Skeleton/SubmitLoading.component';
import { LoginUserNameStep } from './LoginUserNameStep.component';
import { LoginVerifyCodeStep } from './LoginVerifyCodeStep.component';

export const Login = () => {
  const theme = useSelector(getCurrentTheme);
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteId = searchParams.get('inviteId');
  const uuid = searchParams.get('adminUserGroupId');
  const email = searchParams.get('email');

  const auth = useAuth();
  const [step, setStep] = useState(1);
  const { navigateWithCompanyId, navigate } = useNavigateWithSearchParam();
  const whiteLabelCompanyName: any = useSelector(getWhiteLabellingCompanyName);
  const [
    triggerFetchCompanies,
    {
      data: companies,
      isSuccess: isFetchCompaniesSuccess,
      isError: isFetchCompaniesError,
      isFetching: isFetchCompanies,
      isLoading: isCompaniesLoading,
    },
  ] = useLazyFetchCompaniesQuery();
  const [loadingDots, setLoadingDots] = useState(false);

  useEffect(() => {
    if (auth.user && step === 1) {
      triggerFetchCompanies(
        auth.role == USER_ROLE.SUPPORT ? SCOPE_TYPE.ALL : SCOPE_TYPE.COMPANY,
      );
    }
  }, [auth.user]);

  useEffect(() => {
    if (auth.user && companies && companies.length > 0 && step === 1) {
      const matchedCompany = companies.find(
        (company) => company.adminUserGroupId === uuid,
      );

      if (!uuid) {
        if (matchedCompany) {
          navigateWithCompanyId(
            `../${RoutePath.OVERVIEW}`,
            matchedCompany?.id,
            {
              replace: true,
            },
          );
        } else {
          navigate('../', {
            replace: true,
          });
        }
      }
    }
  }, [isFetchCompaniesSuccess]);

  const handleStepChange = (stepChange: any) => {
    setStep(stepChange.activeStep);
  };

  // Changes state of loadingDots
  // Passed to LoginVerifyCodeStep component
  // loadingDots - true : when auth.user is present and company fetching is started
  // loadingDots - false : when auth.user is present and company fetching is success / error
  const handleLoading = (loading: boolean) => {
    setLoadingDots(loading);
  };

  if (!isFetchCompanies) {
    return (
      <div className='h-screen bg-[#E5E5E5]'>
        <div
          className={`flex flex-col items-center ${
            loadingDots ? 'hidden' : ''
          }`}
        >
          <div className='pt-10 pb-10'>
            <img
              src={theme.networkLogo}
              alt={t('icon_alt_brand_logo', { brandName: getbrandname() })}
              style={{ width: 'auto', height: '54px' }}
            />
          </div>
          <div className='w-[440px] bg-white rounded p-10 border-grey0'>
            <StepWizard
              transitions={{
                enterRight: '',
                enterLeft: '',
                exitRight: '',
                exitLeft: '',
                intro: '',
              }}
              onStepChange={handleStepChange}
            >
              <LoginUserNameStep email={email} />
              <LoginVerifyCodeStep
                inviteId={inviteId}
                uuid={uuid}
                onLoading={handleLoading}
              />
              {/* <LoginNoAccessCompany /> */}
            </StepWizard>
          </div>
          <div className='w-[440px] px-4 py-2'>
            <div
              className='font-normal text-grey5 font-inter'
              style={{ fontSize: '12px', lineHeight: '20px' }}
              data-testid='div-terms-of-service'
            >
              <Trans
                i18nKey='terms_of_service_login_msg_1'
                values={{ whiteLabelCompanyName }}
              >
                <a
                  target='_blank'
                  href='https://chargelab.co/driver-and-site-host-terms#site-host'
                  rel='noreferrer'
                  style={{
                    color: getHexColorByType(ColorType.DENIM),
                  }}
                  className='text-xs text-black leading-5 font-semibold'
                  data-testid='terms-of-service'
                >
                  This text will be replaced by actual link text during
                  translation
                </a>
                <a
                  target='_blank'
                  href='https://chargelab.co/driver-and-site-host-terms#privacy-policy'
                  rel='noreferrer'
                  style={{
                    color: getHexColorByType(ColorType.DENIM),
                  }}
                  className='text-xs text-black leading-5 font-semibold'
                  data-testid='privacy-policy'
                >
                  This text will be replaced by actual link text during
                  translation
                </a>
                <a
                  target='_blank'
                  href='https://chargelab.co/driver-and-site-host-terms#privacy-policy'
                  rel='noreferrer'
                  style={{
                    color: getHexColorByType(ColorType.DENIM),
                  }}
                  className='text-xs text-black leading-5 font-semibold'
                >
                  This text will be replaced by actual link text during
                  translation
                </a>
              </Trans>
            </div>
            <div className='pt-3'>
              <Label
                text={t('terms_of_service_login_msg_2')}
                type={LabelType.BODY4}
                color={ColorType.GREY5}
              />
            </div>
          </div>
        </div>
        <div
          className={`${
            loadingDots
              ? 'flex flex-col items-center gap-4 inset-x-2/4 inset-y-2/4 absolute'
              : 'hidden'
          }`}
        >
          <SubmitLoading />
          <Label
            text={t('loading_dashboard')}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.GREY4}
            className='w-max'
          />
        </div>
      </div>
    );
  }
  return null;
};
