import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Dropdown,
  DropdownType,
  FormInput,
  Icon,
  Label,
  LabelType,
  Pill,
  Snackbar,
  getHexColorByType,
} from '../_ui';
import { useGlobalModalContext } from '../_ui/modal/GlobalModal.component';

import { CheckMark, CompanyBuilding, Info, User } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { useCompany } from '../../hooks/useCompany';
import {
  ACCESS_EDIT_TYPE,
  useLazyCheckIfUserEmailExistsQuery,
  useUpdateAccessMutation,
} from '../../services/access.api';
import { AccessRule } from '../../stores/types/access/accessRule.interface';
import { UserGroup } from '../../stores/types/access/userGroup.interface';
import { UserGroupType } from '../../stores/types/access/userGroupType.enum';
import { Form } from '../_ui/Form.component';
import { InputType, ValidationType } from '../_ui/Input.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import { validateDomainName, validateEmail } from '../_ui/modal/utils';
import { AlertPosition, AlertType } from '../_ui/snack-bar/Snack-Bar.component';

interface AccessEditorProp {
  chargersGroupByLocation: any;
  chargerIds: string[];
  accessRules: AccessRule[];
  isAdd: boolean;
}
export const AccessEditor = ({
  chargersGroupByLocation,
  chargerIds,
  accessRules,
  isAdd,
}: AccessEditorProp) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [triggerEditAccess, editAccessQueryResult] = useUpdateAccessMutation();
  const [_privateAccessRule, setPrivateAccessRule] = useState({
    chargerIds: [] as string[],
    accessRules: [
      {
        actions: [{ action: 'charger-start-session' }],
        userGroups: [],
      } as AccessRule,
    ],
  });
  const [_publicAccessRule, setPublicAccessRule] = useState({
    chargerIds: [] as string[],
    accessRules: [
      {
        actions: [{ action: 'charger-start-session' }],
        userGroups: [{ userGroupId: 'everyone' }],
      } as AccessRule,
    ],
  });
  const [emailValid, setEmailValid] = useState(true);
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const [driverEmail, setDriverEmail] = useState<string>('');
  const [companyDomain, setCompanyDomain] = useState<string>('');
  const [triggerCheckIfUserEmailExist, { isError: emailDoesNotExist }] =
    useLazyCheckIfUserEmailExistsQuery();

  const showToast = (message: string) => {
    Snackbar.show({
      message,
      position: AlertPosition.BOTTOM_LEFT,
      alertType: AlertType.DEFAULT,
      duration: 5000,
      messageLabelType: LabelType.LABEL_S_MEDIUM,
      messageLabelColor: ColorType.BLACK,
      icon: CheckMark,
      iconColor: getHexColorByType(ColorType.POSITIVE),
    });
  };

  useEffect(() => {
    if (!isAdd) {
      setPrivateAccessRule({
        chargerIds,
        accessRules,
      });
    }
  }, []);

  const chargersDropdownList = useMemo(() => {
    const data = Object.entries(chargersGroupByLocation).map((key: any) => {
      // For creating, we should only display public chargers
      // For editing, we need all chargers to get selected ones.
      const filteredChargers = isAdd
        ? key[1].filter(
            (charger: any) => charger.access.toUpperCase() === 'PUBLIC',
          )
        : key[1].filter(
            (charger: any) =>
              charger.access.toUpperCase() === 'PUBLIC' ||
              chargerIds.includes(charger.id),
          );
      const children = filteredChargers.map((charger: any) => {
        return {
          autoStartEnabled: charger.autoStartEnabled,
          id: charger.id,
          label: charger.name,
          selected: _privateAccessRule.chargerIds.includes(charger.id),
        };
      });

      return {
        id: key[0],
        label: key[0],
        selected: children?.every((child: any) => child.selected) || false,
        children: children || [],
      };
    });
    return data.filter((charger: any) => charger.children.length);
  }, [_privateAccessRule.chargerIds, chargersGroupByLocation]);

  const handleChargersChange = (items: any) => {
    let willShowWarningMessage = false;
    const currentPrivateChargers = chargerIds;
    const newChargersToMakePrivate: string[] = [];
    const newChargersToMakePublic: string[] = [];
    items.forEach((item: any) => {
      item.children.forEach((child: any) => {
        if (child.selected) {
          newChargersToMakePrivate.push(child.id);
          if (child.autoStartEnabled) {
            willShowWarningMessage = true;
          }
        } else if (
          !child.selected &&
          _.includes(currentPrivateChargers, child.id)
        ) {
          newChargersToMakePublic.push(child.id);
        }
      });
    });

    setPrivateAccessRule({
      chargerIds: newChargersToMakePrivate,
      accessRules: _privateAccessRule.accessRules,
    });
    setPublicAccessRule({
      chargerIds: _publicAccessRule.chargerIds.concat(newChargersToMakePublic),
      accessRules: _publicAccessRule.accessRules,
    });
    setShowWarningMsg(willShowWarningMessage);
  };
  const { userScope } = useCompany();
  const handleSaveClick = () => {
    const updateAccessRequest = {
      batch: [_privateAccessRule],
    };
    if (_publicAccessRule.chargerIds.length > 0) {
      updateAccessRequest.batch.push(_publicAccessRule);
    }
    triggerEditAccess({
      body: updateAccessRequest,
      scope: userScope!,
      updateType: isAdd ? ACCESS_EDIT_TYPE.ADD : ACCESS_EDIT_TYPE.EDIT,
    }).finally(() => {
      setPrivateAccessRule({
        chargerIds: [] as string[],
        accessRules: [
          {
            actions: [{ action: 'charger-start-session' }],
            userGroups: [],
          } as AccessRule,
        ],
      });
      setPublicAccessRule({
        chargerIds: [] as string[],
        accessRules: [
          {
            actions: [{ action: 'charger-start-session' }],
            userGroups: [{ userGroupId: 'everyone' }],
          } as AccessRule,
        ],
      });
    });
  };

  const doesEmailOrDomainAlreadyAdded = (emailAddressOrDomain: string) => {
    return _privateAccessRule.accessRules.find((x) =>
      x.userGroups.find(
        (z) => z.userGroupSelector?.value === emailAddressOrDomain,
      ),
    );
  };

  const handleGiveAccessClick = async (
    type: string,
    emailAddressOrDomain: string,
  ) => {
    let userGroupType = '';
    if (UserGroupType[UserGroupType.INDIVIDUAL] === type) {
      userGroupType = UserGroupType[UserGroupType.INDIVIDUAL];
      const validationResult = await triggerCheckIfUserEmailExist(
        emailAddressOrDomain,
      );
      if (!validationResult.isSuccess) {
        setEmailValid(false);
        return;
      }
      setEmailValid(true);
      setDriverEmail('');
    } else {
      userGroupType = UserGroupType[UserGroupType.DOMAIN_NAME];
      setCompanyDomain('');
    }
    setPrivateAccessRule({
      chargerIds: _privateAccessRule.chargerIds,
      accessRules: [
        {
          actions: _privateAccessRule.accessRules[0].actions,
          userGroups: [
            ..._privateAccessRule.accessRules[0].userGroups,
            {
              userGroupSelector: {
                type: userGroupType,
                value: emailAddressOrDomain,
              },
            },
          ],
        },
      ],
    });
  };

  const onButtonValidation = () => {
    return (
      _privateAccessRule.chargerIds.length > 0 &&
      _privateAccessRule.accessRules[0].userGroups.length > 0
    );
  };

  const handleRemoveUserEmailOrDomain = (
    emailAddressOrDomain: string | undefined,
  ) => {
    if (emailAddressOrDomain) {
      setPrivateAccessRule({
        chargerIds: _privateAccessRule.chargerIds,
        accessRules: [
          {
            actions: _privateAccessRule.accessRules[0].actions,
            userGroups: [
              ..._privateAccessRule.accessRules[0].userGroups.filter(
                (x) => x.userGroupSelector?.value !== emailAddressOrDomain,
              ),
            ],
          },
        ],
      });
    }
  };

  const handleCompanyDomain = (event: any) => {
    if (event.target.value.includes('@')) {
      return;
    }
    setCompanyDomain(event.target.value);
  };

  return (
    <Form
      onSubmit={handleSaveClick}
      queryResult={editAccessQueryResult}
      onQuerySuccess={() => {
        hideModal();
      }}
      onQueryFailed={() => {
        hideModal();
      }}
    >
      <div className='flex flex-col justify-between h-full'>
        <div className='divide-y divide-grey2'>
          <div className='flex flex-col gap-4 pb-4'>
            <Label
              text={t('pricing_chargers')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <Dropdown
              placeholder={t('pricing_chargers_holder')}
              placeholderLabelType={LabelType.DROPDOWN_HEADER}
              headerWidth={505}
              items={chargersDropdownList}
              type={DropdownType.CHECKBOX_TREE}
              showPillHeader
              onItemClick={(items: any, event: any) =>
                handleChargersChange(items)
              }
              pillCloseIconAlt={t('icon_alt_remove_charger')}
              showFooter
              iconAlt={t('icon_alt_downward_arrow')}
              contentDivHeight={250}
              dataTestId='button-select-charger'
            />
            {showWarningMsg && (
              <div className='flex flex-row gap-2'>
                <Icon src={Info} size={IconSize.SIZE_20x20} />
                <Label
                  text={t('access_charger_warnings')}
                  type={LabelType.BODY4}
                  color={ColorType.GREY6}
                  className='leading-[20px]'
                />
              </div>
            )}
            <div className='flex flex-col gap-2'>
              <Label
                text={t('access_add_drivers_label')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                labelHtmlFor='access_add_drivers_label'
              />
              <div className='flex flex-row gap-2'>
                <FormInput
                  inputId='access_add_drivers_label'
                  dataTestId='access-user-email'
                  validationType={ValidationType.EMAIL}
                  placeholder='user@email.com'
                  defaultValue={driverEmail}
                  onChange={(event: any) => setDriverEmail(event.target.value)}
                  width='385px'
                  showErrorIcon={emailDoesNotExist}
                  validaitonQueryResult={emailDoesNotExist}
                  errorLabel={
                    emailDoesNotExist
                      ? t('access_email_validation_message')
                      : ''
                  }
                />
                <Button
                  dataTestId='access-add-email'
                  label={t('access_give_access_button_label')}
                  type={ButtonType.SECONDARY}
                  disabled={
                    !(
                      validateEmail(driverEmail) &&
                      !doesEmailOrDomainAlreadyAdded(driverEmail)
                    )
                  }
                  size={ButtonSize.SMALL}
                  onClick={() =>
                    handleGiveAccessClick(
                      UserGroupType[UserGroupType.INDIVIDUAL],
                      driverEmail,
                    )
                  }
                />
              </div>
            </div>

            <div className='flex flex-col gap-2'>
              <Label
                text={t('access_add_companies_label')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                labelHtmlFor='access_add_companies_label'
              />
              <div className='flex flex-row gap-2'>
                <div
                  className='flex font-medium'
                  style={{ fontSize: '14px', paddingTop: '10px' }}
                >
                  @
                </div>
                <FormInput
                  inputId='access_add_companies_label'
                  dataTestId='access-company-domain'
                  validationType={ValidationType.DOMAIN_NAME}
                  placeholder='companydomain.com'
                  defaultValue={companyDomain}
                  onChange={(event: any) => {
                    handleCompanyDomain(event);
                  }}
                  inputType={InputType.DOMAIN}
                  width='362px'
                  showErrorIcon={!validateDomainName(companyDomain)}
                  errorLabel={
                    !validateDomainName(companyDomain)
                      ? t('access_domain_validation_message')
                      : ''
                  }
                />
                <Button
                  dataTestId='access-add-domain'
                  label={t('access_give_access_button_label')}
                  type={ButtonType.SECONDARY}
                  disabled={
                    !(
                      companyDomain &&
                      validateDomainName(companyDomain) &&
                      !doesEmailOrDomainAlreadyAdded(companyDomain)
                    )
                  }
                  size={ButtonSize.SMALL}
                  onClick={() =>
                    handleGiveAccessClick(
                      UserGroupType[UserGroupType.DOMAIN_NAME],
                      `@${companyDomain}`,
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className='pt-4'>
            <div className='flex flex-row gap-2'>
              <Label
                text={t('access_who_can_access')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Tooltip>
                <TooltipTrigger>
                  <Icon src={Info} />
                </TooltipTrigger>
                <TooltipContent>
                  <Label
                    text={t('access_tippy_info')}
                    type={LabelType.BODY3}
                    color={ColorType.WHITE}
                  />
                </TooltipContent>
              </Tooltip>
            </div>
            <div className='pt-2'>
              {_privateAccessRule.accessRules[0].userGroups.length === 0 && (
                <Label
                  text={t('access_no_users_assigned_label')}
                  type={LabelType.BODY3}
                  color={ColorType.GREY6}
                />
              )}
              <div className='flex flex-row gap-1 py-2 px-2 flex-wrap'>
                {_privateAccessRule.accessRules[0].userGroups.map(
                  (userGroup: UserGroup) => {
                    const isUserGroupIndividual =
                      userGroup?.userGroupSelector?.type ===
                      UserGroupType[UserGroupType.INDIVIDUAL];
                    return (
                      <Pill
                        key={userGroup.userGroupSelector?.value}
                        iconLeft={
                          isUserGroupIndividual ? User : CompanyBuilding
                        }
                        iconLeftAlt={
                          isUserGroupIndividual
                            ? t('icon_alt_user')
                            : t('icon_alt_company')
                        }
                        closeButtonAlt={
                          isUserGroupIndividual
                            ? t('icon_alt_remove_x_user', {
                                userName: userGroup?.userGroupSelector?.value,
                              })
                            : t('icon_alt_remove_x_compnay', {
                                companyName:
                                  userGroup?.userGroupSelector?.value,
                              })
                        }
                        label={userGroup.userGroupSelector?.value}
                        hasCloseButton
                        onClick={() =>
                          handleRemoveUserEmailOrDomain(
                            userGroup.userGroupSelector?.value,
                          )
                        }
                      />
                    );
                  },
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='pb-[20px] pt-4 sticky bottom-0 bg-white'>
          <Button
            dataTestId='access-save-button'
            label={t('save')}
            type={ButtonType.PRIMARY}
            disabled={!onButtonValidation()}
            size={ButtonSize.SMALL_FULL}
            isSumbit
          />
        </div>
      </div>
    </Form>
  );
};
