import {
  ButtonType,
  CheckBox,
  CheckBoxData,
  CheckBoxGroup,
  CheckBoxTree,
  ColorType,
  DoughnutChart,
  Dropdown,
  DropdownType,
  GroupDirection,
  Icon,
  LabelType,
  MODAL_TYPES,
  RadioGroup,
  Snackbar,
  VerticalBarChart,
  getHexColorByType,
  useGlobalModalContext,
} from '.';
import { ErrorWarningCircle } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { Card } from './Card.component';
import { AlertPosition } from './snack-bar/Snack-Bar.component';

const renderChart = () => {
  const status = [
    {
      label: 'Available',
      value: 4,
      color: getHexColorByType(ColorType.POSITIVE),
    },
    {
      label: 'Charging',
      value: 1,
      color: getHexColorByType(ColorType.DENIM),
    },
    {
      label: 'Offline',
      value: 3,
      color: getHexColorByType(ColorType.WARNING),
    },
    {
      label: 'Coming soon',
      value: 2,
      color: getHexColorByType(ColorType.GREY4),
    },
  ];
  const feeCollected = [
    {
      date: new Date('2022-01-01'),
      value: 0,
    },
    {
      date: new Date('2022-02-01'),
      value: 170,
    },
    {
      date: new Date('2022-03-01'),
      value: 720,
    },
    {
      date: new Date('2022-04-01'),
      value: 530,
    },
    {
      date: new Date('2022-05-01'),
      value: 600,
    },
    {
      date: new Date('2022-06-01'),
      value: 20,
    },
    {
      date: new Date('2022-07-01'),
      value: 880,
    },
    {
      date: new Date('2022-08-01'),
      value: 1000,
    },
    {
      date: new Date('2022-09-01'),
      value: 940,
    },
    {
      date: new Date('2022-10-01'),
      value: 30,
    },
    {
      date: new Date('2022-11-01'),
      value: 500,
    },
    {
      date: new Date('2022-12-01'),
      value: 1200,
    },
  ];
  return (
    <div>
      <Card className='w-1/3 h-52' title='Charger Status'>
        <DoughnutChart
          items={status}
          className='flex h-32'
          valueField='value'
          labelField='label'
          colorField='color'
        />
      </Card>
      <br />
      <Card className='flex h-60'>
        <VerticalBarChart
          items={feeCollected}
          className='flex h-52 w-full'
          dateField='date'
          valueField='value'
        />
      </Card>
      <br />
    </div>
  );
};

const renderDropdown = () => {
  const locations = [
    {
      label: 'All locations',
      selected: false,
    },
    {
      label: 'Hilton Kennedy',
      selected: false,
    },
    {
      label: 'Hilton Spring field',
      selected: false,
    },
    {
      label: 'A very loooooooooooooooong location',
      selected: false,
    },
  ];
  const Status = [
    {
      label: 'Available',
      selected: false,
    },
    {
      label: 'Charging',
      selected: false,
    },
    {
      label: 'Coming soon',
      selected: false,
    },
    {
      label: 'Out of order',
      selected: false,
    },
    {
      label: 'Offline',
      selected: false,
    },
    {
      label: 'Scheduled',
      selected: false,
    },
  ];
  const chargers = [
    { label: 'AD-21', selected: true },
    { label: 'AD-22', selected: false },
    { label: 'AD-23', selected: false },
    { label: 'AD-24', selected: false },
    { label: 'AD-25', selected: false },
    { label: 'AD-26', selected: false },
    { label: 'AD-27', selected: false },
    { label: 'AD-28', selected: false },
    { label: 'AD-29', selected: false },
    { label: 'AD-30', selected: false },
    { label: 'AD-31', selected: false },
    { label: 'AD-32', selected: false },
    { label: 'AD-33', selected: false },
    { label: 'AD-34', selected: false },
    { label: 'AD-35', selected: false },
  ];
  const chargerTree = [
    {
      label: 'Hilton Kennedy',
      selected: false,
      children: [
        { id: '1', label: 'FG-21', selected: true },
        { id: '2', label: 'FG-22' },
        { id: '3', label: 'FG-23' },
      ],
    },
    {
      label: 'Hilton Springfield',
      selected: false,
      children: [
        { id: '1', label: 'BD-31', selected: true },
        { id: '2', label: 'BD-32' },
        { id: '3', label: 'BD-33' },
      ],
    },
    {
      label: 'Park A',
      selected: false,
      children: [
        { id: '1', label: 'A-31', selected: true },
        { id: '2', label: 'A-32' },
        { id: '3', label: 'A-33' },
      ],
    },
    {
      label: 'Park B',
      selected: false,
      children: [
        { label: 'B-31', selected: true },
        { label: 'B-32' },
        { label: 'B-33' },
      ],
    },
    {
      label: 'Park C',
      selected: false,
      children: [
        { label: 'C-31', selected: false },
        { label: 'C-32' },
        { label: 'C-33' },
      ],
    },
  ];
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row gap-4'>
        <Dropdown
          placeholder='Location'
          placeholderLabelType={LabelType.DROPDOWN_HEADER}
          headerWidth='auto'
          items={locations}
          onItemClick={(items: any, item: any, index: number) =>
            console.log(item, index)
          }
        />
        <Dropdown
          placeholder='Status'
          placeholderLabelType={LabelType.DROPDOWN_HEADER}
          items={Status}
          type={DropdownType.CHECKBOX}
          onItemClick={(items: any) => console.log(items)}
          showFooter
        />
        <Dropdown
          placeholder='Charger'
          placeholderLabelType={LabelType.DROPDOWN_HEADER}
          items={chargers}
          type={DropdownType.CHECKBOX}
          onItemClick={(items: any) => console.log(items)}
          showFooter
        />
      </div>
      <div>
        <Dropdown
          placeholder='Select Charger'
          placeholderLabelType={LabelType.DROPDOWN_HEADER}
          headerWidth={340}
          items={chargerTree}
          type={DropdownType.CHECKBOX_TREE}
          showPillHeader
          onItemClick={(items: any) => console.log(items)}
          showFooter
        />
      </div>
    </div>
  );
};

const renderCheckBox = () => (
  <div className='flex flex-row w-full'>
    <div className='flex flex-col basis-1/2 gap-5'>
      <CheckBox
        label='Time-of-use price'
        onChange={(checked: boolean) => console.log(checked)}
      />
      <CheckBoxGroup
        defaultItems={[
          { id: '1', label: 'FG-21', selected: true },
          { id: '2', label: 'FG-22', selected: true },
          { id: '3', label: 'FG-23' },
        ]}
        onChange={(items: CheckBoxData[]) => {
          console.log(items);
        }}
      />
      <RadioGroup
        direction={GroupDirection.Vertical}
        defaultItems={[
          { id: '1', label: 'Toronto', selected: true },
          { id: '2', label: 'Calgary' },
          { id: '3', label: 'Vancouver' },
        ]}
        onChange={(items: CheckBoxData[]) => {
          console.log(items);
        }}
      />
    </div>
    <div className='basis-1/2'>
      <CheckBoxTree
        defaultNodes={[
          {
            id: '1',
            label: 'Hilton Kennedy',
            selected: false,
            children: [
              { id: '1', label: 'FG-21', selected: true },
              { id: '2', label: 'FG-22' },
              { id: '3', label: 'FG-23' },
            ],
          },
          {
            id: '2',
            label: 'Hilton Springfield',
            selected: false,
            children: [
              { id: '1', label: 'BD-31', selected: true },
              { id: '2', label: 'BD-32' },
              { id: '3', label: 'BD-33' },
            ],
          },
        ]}
        onChange={(nodes: CheckBoxData[]) => console.log('onChange:', nodes)}
      />
    </div>
  </div>
);

export const Wiki = () => {
  const { showModal } = useGlobalModalContext();

  const history = [
    { title: 'Coming soon', date: new Date() },
    { title: 'Available', date: new Date() },
    { title: 'Preparing', date: new Date() },
    { title: 'Charging', date: new Date() },
  ];

  const showSnackbar = () => {
    Snackbar.show({
      message:
        'Snackbar successfully shown up with close button at right pls make sure you have all the details',
      position: AlertPosition.TOP,
    });
  };

  const showSnackbarBottom = () => {
    Snackbar.show({
      message:
        'Snackbar successfully shown up with close button at right pls make sure you have all the details',
      position: AlertPosition.BOTTOM,
    });
  };

  const showConfirmModal = () => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      title: 'Stop session',
      iconn: 2,
      buttons: [
        {
          label: 'Cancel',
          type: ButtonType.TERTIARY,
          onClick: () => {},
        },
        {
          label: 'Confirm',
          type: ButtonType.PRIMARY,
          onClick: () => {},
        },
      ],
    });
  };

  const handleShowModal = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: 'Session detail',
      small: false,
      body: <Wiki />,
    });
  };
  return (
    <div>
      <Icon src={ErrorWarningCircle} size={IconSize.SIZE_20x20} />
    </div>
  );
  // return (
  //   <>
  //     <Icon src={SignoutLogout} />
  //     <FormInput />
  //     {renderChart()}
  //     <br />
  //     <div className='block mb-4'>
  //       <div className='inline-block'>
  //         <Label
  //           text='Medium'
  //           type={LabelType.LABEL_M}
  //           color={ColorType.BLACK}
  //         />
  //       </div>
  //       <div className='inline-block ml-4'>
  //         <Label text='H3' type={LabelType.H3} color={ColorType.BLACK} />
  //       </div>
  //       <div className='inline-block ml-4'>
  //         <Label text='H7' type={LabelType.LABEL_S} color={ColorType.GREY6} />
  //       </div>
  //       <div className='inline-block ml-4'>
  //         <Label text='H5' type={LabelType.H5} color={ColorType.BLACK} />
  //       </div>
  //       <div className='inline-block ml-4'>
  //         <Label text='H4' type={LabelType.H4} color={ColorType.BLACK} />
  //       </div>
  //       <div className='inline-block ml-4'>
  //         <Label
  //           text='Error'
  //           type={LabelType.LABEL_S}
  //           color={ColorType.NEGATIVE}
  //           className='pt-1 block'
  //         />
  //       </div>
  //       <div className='inline-block ml-4'>
  //         <Label text='BODY3' type={LabelType.BODY3} color={ColorType.BLACK} />
  //       </div>
  //       <div className='inline-block ml-4'>
  //         <Label text='BODY2' type={LabelType.BODY2} />
  //       </div>
  //     </div>
  //     <hr />
  //     <div className='block mt-4 mb-4 bg-white p-5'>{renderDropdown()}</div>
  //     <hr />
  //     <div className='block mt-4 mb-4'>
  //       <div className='inline-block'>
  //         Month Picker :
  //         <DateTimePicker
  //           showMonthYearPicker
  //           inputBG='bg-white'
  //           defaultDate={new Date()}
  //         />
  //       </div>

  //       <div className='inline-block'>
  //         Date Picker :
  //         <DateTimePicker
  //           inputBG='bg-white'
  //           format='LLL dd, yyyy'
  //           defaultDate={new Date()}
  //         />
  //       </div>

  //       <div className='inline-block'>
  //         Range Picker#1 :
  //         <DateRangePicker inputBG='bg-white' format='LLL dd yyyy' />
  //       </div>

  //       <div className='inline-block'>
  //         Range Picker#2:
  //         <DateRangePicker inputBG='bg-white' format='LLL dd yyyy' />
  //       </div>
  //     </div>
  //     <hr />
  //     <div className='flex pt-6 pb-4'>
  //       <Card title='Recent sessions'>
  //         <Grid
  //           columns={[
  //             { key: 'id', title: 'Charger Name' },
  //             { key: 'location', title: 'Location' },
  //             {
  //               key: 'status',
  //               title: 'Status',
  //               component: (row: any) => (
  //                 <Pill
  //                   label={row.status}
  //                   bgColor={
  //                     row.status === 'Available'
  //                       ? ColorType.POSITIVE
  //                       : ColorType.NEGATIVE
  //                   }
  //                 />
  //               ),
  //             },
  //           ]}
  //           data={[
  //             { id: 'AD-01', location: 'UAT', status: 'Available' },
  //             { id: 'AD-02', location: 'DEV', status: 'Hidden' },
  //           ]}
  //           totalPage={9}
  //           primaryKey='id'
  //         />
  //       </Card>
  //     </div>
  //     <hr />
  //     <div className='flex pt-6 pb-4 bg-white'>
  //       <Switch
  //         enableLabel='Enabled'
  //         disableLabel='Disabled'
  //         onChange={(checked: boolean) => console.log('Switch:', checked)}
  //       />

  //       <Segment
  //         className='ml-10'
  //         options={['Company', 'Individual', 'N/A']}
  //         onChange={(checked: boolean) => console.log('Switch:', checked)}
  //       />
  //     </div>
  //     <hr />
  //     <div className='block mt-4 mb-4'>
  //       <div className='inline-block'>
  //         <Pill label='Available' bgColor={ColorType.NEGATIVE} />
  //       </div>
  //       <div className='inline-block ml-4'>
  //         <Pill label='In Use' bgColor={ColorType.DENIM} />
  //       </div>
  //       <div className='inline-block ml-4'>
  //         <Pill label='Hidden' bgColor={ColorType.NEGATIVE} />
  //       </div>
  //       <div className='inline-block ml-4'>
  //         <Pill label='Button' hasCloseButton bgColor={ColorType.PURPLE} />
  //       </div>
  //     </div>
  //     <hr />
  //     <div className='block mt-6 mb-4'>
  //       <Grid
  //         columns={[
  //           { key: 'id', title: 'Charger Name' },
  //           { key: 'location', title: 'Location' },
  //           {
  //             key: 'status',
  //             title: 'Status',
  //             component: (row: any) => (
  //               <Pill
  //                 label={row.status}
  //                 bgColor={
  //                   row.status === 'Available'
  //                     ? ColorType.POSITIVE
  //                     : ColorType.NEGATIVE
  //                 }
  //               />
  //             ),
  //           },
  //         ]}
  //         data={[
  //           { id: 'AD-01', location: 'UAT', status: 'Available' },
  //           { id: 'AD-02', location: 'DEV', status: 'Hidden' },
  //         ]}
  //         totalPage={9}
  //         primaryKey='id'
  //       />
  //     </div>
  //     <hr />
  //     <div className='block mt-6 mb-4'>
  //       <SessionStatusHistory data={history} />
  //     </div>
  //     <hr />
  //     <div className='block mt-6 mb-4'>
  //       <input
  //         className='cursor-pointer'
  //         type='button'
  //         onClick={handleShowModal}
  //         value='Show Modal'
  //       />
  //       <input
  //         className='cursor-pointer ml-5'
  //         type='button'
  //         onClick={showConfirmModal}
  //         value='Confirmation Modal'
  //       />
  //     </div>
  //     <hr />

  //     <div className='flex felx-row mt-6 mb-4 items-center'>
  //       <Button
  //         label='Save'
  //         type={ButtonType.PRIMARY}
  //         onClick={() => console.log('Save clicked')}
  //       />
  //       <Button label='Cancel' type={ButtonType.TERTIARY} className='ml-2' />
  //       <Button
  //         label='INFO'
  //         type={ButtonType.SECONDARY}
  //         size={ButtonSize.SMALL}
  //         className='ml-2'
  //       />
  //       <Button label='Alert' type={ButtonType.DESTRUCTIVE} className='ml-2' />
  //       <Button
  //         label='Start'
  //         type={ButtonType.OUTLINE}
  //         icon={Play}
  //         iconColor={getHexColorByType(ColorType.POSITIVE)}
  //         className='ml-2'
  //       />
  //     </div>
  //     <hr />
  //     <div className='flex felx-row mt-6 mb-6'>
  //       <Button
  //         label='Snackbar Top'
  //         onClick={showSnackbar}
  //         type={ButtonType.PRIMARY}
  //       />

  //       <Button
  //         label='Snackbar Bottom'
  //         onClick={showSnackbarBottom}
  //         type={ButtonType.SECONDARY}
  //         className='ml-2'
  //       />
  //     </div>
  //     <hr />
  //     <div className='flex felx-row mt-6'>{renderCheckBox()}</div>

  //     <hr />
  //     <div className='flex felx-row mt-6 mb-4'>
  //       Empty
  //       <ImageViewer className='mr-6' />
  //       From URL
  //       <ImageViewer
  //         className='mr-6'
  //         width={120}
  //         src='https://chargelabservice.s3.us-east-2.amazonaws.com/stations/generic/Delta+AC+Mini.jpg'
  //       />
  //       Profile
  //       <ImageViewer width={40} className='mr-6' />
  //       Profile Selector
  //       <ImageViewer profile className='mr-6' />
  //       Circle Image
  //       <ImageViewer
  //         className='mr-6'
  //         width={120}
  //         circle
  //         src='https://chargelabservice.s3.us-east-2.amazonaws.com/stations/generic/Delta+AC+Mini.jpg'
  //       />
  //     </div>
  //   </>
  // );
};
