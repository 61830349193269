import _ from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Info, RFIDCard, Vehicle as VehicleIcon } from '../../assets/icons';
import { useAuth } from '../../hooks';
import { useCompany } from '../../hooks/useCompany';
import { useFetchCompanyDetailQuery } from '../../services/company.api';
import { useFetchLocationsQuery } from '../../services/location.api';
import {
  useFetchRfidCardsQuery,
  useLazyValidateRfidCardQuery,
  useRegisterRfidCardMutation,
} from '../../services/rfid.api';
import {
  useAddVehicleMutation,
  useLazyValidateVehicleNameQuery,
  useLazyValidateVehicleVinQuery,
} from '../../services/vehicles.api';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { Vehicle } from '../../stores/types/vehicles.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Dropdown,
  DropdownType,
  FormInput,
  Icon,
  Label,
  LabelType,
  useGlobalModalContext,
} from '../_ui';
import { Form } from '../_ui/Form.component';
import { InputType, ValidationType } from '../_ui/Input.component';
import { formatCardNumber } from './utils';

export interface Props {
  vehicles: Vehicle[];
  vehicle?: Vehicle;
}

const VIN_LEN = 17;
const RFID_CARD_LEN = 9;

export const VehicleEditor = memo(({ vehicles, vehicle }: Props) => {
  const { t } = useTranslation();
  const theme = useSelector(getCurrentTheme);
  const { userScope } = useCompany();
  const { hideModal } = useGlobalModalContext();
  const [triggerAddVehicle, addVehicleQueryResult] = useAddVehicleMutation();
  const { locations } = useFetchLocationsQuery(
    { scope: userScope! },
    {
      selectFromResult: (endPoint) => ({
        locations: endPoint.data ? endPoint.data?.entities : [],
      }),
    },
  );
  const [_vehicle, setVehicle] = useState(
    vehicle ? _.cloneDeep(vehicle) : ({} as Vehicle),
  );

  const [showRFIDRegister, setshowRFIDRegister] = useState(false);
  const [registerRFID, setRegisterRFID] = useState('');
  const { data: rfids } = useFetchRfidCardsQuery({
    includeLastUsedInfo: false,
  });

  const [validateVehicleName, { isError: isVehicleNameError }] =
    useLazyValidateVehicleNameQuery();
  const [validateVehicleVin, { isError: isVehicleVinError }] =
    useLazyValidateVehicleVinQuery();
  const [
    validateRfidCard,
    { isError: isRFIDCardError, isLoading: isRFIDCardLoading },
  ] = useLazyValidateRfidCardQuery();
  const [
    triggerRegRFIDCard,
    {
      isSuccess: isRegisterRfidSuccess,
      isError: isRegisterRfidError,
      originalArgs: registerRfidArgs,
    },
  ] = useRegisterRfidCardMutation();
  const auth = useAuth();
  const { data: company } = useFetchCompanyDetailQuery({
    id: auth.user.attributes.profile?.lastActiveCompanyId,
    scope: userScope!,
  });

  const handleVehicleChange = (key: string, value: any) => {
    setVehicle({
      ..._vehicle,
      [key]: value,
    });
  };

  const handleRegisterNewRFID = () => {
    setshowRFIDRegister(true);
  };
  const handleCancelClick = () => {
    setshowRFIDRegister(false);
    setRegisterRFID('');
  };
  const handleRegisterClick = () => {
    triggerRegRFIDCard({
      cardNumber: registerRFID,
      virtualUserId:
        company && company.virtualUserIds ? company.virtualUserIds[0] : '',
    });
    setshowRFIDRegister(false);
  };

  const renderRegisterNewRFIDError = () => {
    if (showRFIDRegister && registerRFID && registerRFID.length > 0) {
      if (showRFIDRegister && registerRFID.length !== RFID_CARD_LEN) {
        // The RFID length must be 8 and 1 space
        return (
          <Label
            text={t('vehicles_new_rfid_card_number_error')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE}
          />
        );
      }
      if (isRFIDCardError) {
        return (
          <Label
            text={t('rfid_card_already_registered')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE}
          />
        );
      }
    }
    return null;
  };
  const renderRegisterNewRFID = () => {
    if (showRFIDRegister) {
      return (
        <div className='flex flex-col gap-2'>
          <Label
            text={t('vehicles_new_rfid_card_number')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            labelHtmlFor='vehicles_new_rfid_card_number'
          />
          <div className='flex flex-row items-center gap-2'>
            <FormInput
              inputId='vehicles_new_rfid_card_number'
              onChange={(event: any) => {
                if (String(event.target.value).length === RFID_CARD_LEN) {
                  validateRfidCard({ cardNumber: event.target.value });
                }
                setRegisterRFID(formatCardNumber(event.target.value));
              }}
              placeholder='0000 0000'
              validationType={ValidationType.NOT_EMPTY}
              defaultValue={registerRFID}
              inputType={InputType.RFID}
              minLen={String(RFID_CARD_LEN)}
              maxLen={String(RFID_CARD_LEN)}
              width='315px'
              showErrorIcon
              validaitonQueryResult={isRFIDCardError}
            />
            <Button
              label={t('cancel')}
              type={ButtonType.TERTIARY}
              size={ButtonSize.SMALL}
              onClick={handleCancelClick}
            />
            <Button
              label={t('register')}
              type={ButtonType.SECONDARY}
              disabled={
                isRFIDCardError ||
                isRFIDCardLoading ||
                registerRFID.length !== RFID_CARD_LEN
              }
              size={ButtonSize.SMALL}
              onClick={handleRegisterClick}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  const locationsDropdown = useMemo(() => {
    return locations.map((location) => ({
      id: location.id,
      label: location.name,
      selected: _vehicle.locationId === location.id,
    }));
  }, [locations, _vehicle]);

  const rfidsDropdown = useMemo(() => {
    return (
      rfids?.entities?.map((rfid) => ({
        ...rfid,
        selected: _vehicle.rfids?.some(
          (_rfid) => _rfid.cardNumber === rfid.cardNumber,
        ),
        pillLabel:
          vehicles.find((vehicle1) => vehicle1.vehicleId === rfid.vehicleId)
            ?.vehicleName || '',
        pillIcon: VehicleIcon,
      })) || []
    );
  }, [_vehicle, rfids]);

  const hanldeRfidChange = (items: any) => {
    const cardNumbers =
      items
        .filter((item: any) => item.selected)
        .map((item1: any) => item1.cardNumber) || [];
    setVehicle({
      ..._vehicle,
      rfids: cardNumbers.map((cardNumber: string) => ({ cardNumber })),
    });
  };

  const handleSave = () => {
    triggerAddVehicle(_vehicle);
  };

  const handleValidateVin = (val: string) => {
    if (isVehicleVinError) {
      return false;
    }

    return !val || val.length === VIN_LEN;
  };

  const renderVinError = () => {
    if (vehicles?.some((vehicle1) => vehicle1.vin === _vehicle.vin)) {
      return (
        <div className='mt-2'>
          <Label
            text={t('vehicles_vin_error_dupilcate')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE}
          />
        </div>
      );
    }
    if (!handleValidateVin(_vehicle.vin)) {
      return (
        <div className='mt-2'>
          <Label
            text={t('vehicles_vin_error')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE}
          />
        </div>
      );
    }

    return null;
  };

  const handleValidateName = (val: string) => {
    return !isVehicleNameError;
  };

  const renderNameError = () => {
    if (!handleValidateName(_vehicle.vehicleName)) {
      return (
        <div className='mt-2'>
          <Label
            text={t('vehicles_name_exist_error')}
            type={LabelType.BODY3}
            color={ColorType.NEGATIVE}
          />
        </div>
      );
    }
    return null;
  };

  const renderRFIDCardWarn = () => {
    if (
      vehicles.some((vehicle1) =>
        vehicle1.rfids?.some((rfid1) =>
          _vehicle.rfids?.some(
            (rfid2) => rfid2.cardNumber === rfid1.cardNumber,
          ),
        ),
      )
    ) {
      return (
        <div className='mt-[8px]'>
          <Label
            icon={Info}
            text={t('vehicles_rfid_card_warn')}
            type={LabelType.LABEL_XS}
            color={ColorType.GREY6}
          />
        </div>
      );
    }

    return null;
  };

  const renderRegisterRfidLabel = () => {
    return (
      <>
        <Icon
          src={RFIDCard}
          color={theme.navigationSelectedColor}
          alt={t('icon_alt_rfid_card')}
        />
        <Label
          text={t('vehicles_register_new_rfid_card')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY5}
          style={{ color: theme.brand_2 }}
        />
      </>
    );
  };

  const renderRFID = () => {
    if (showRFIDRegister) {
      return null;
    }
    return (
      <div className='flex-1 flex flex-col'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row gap-2 items-baseline'>
            <Label
              text={t('vehicles_rfid_card')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <Label
              text={`(${t('optional')})`}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          </div>
          <div
            className='flex flex-row gap-[10px] pr-[3px] hover:cursor-pointer'
            onClick={handleRegisterNewRFID}
          >
            {renderRegisterRfidLabel()}
          </div>
        </div>

        <div className='mt-[4px]'>
          <Label
            text={t('vehicles_rfid_card_message')}
            type={LabelType.BODY3}
            color={ColorType.GREY5}
          />
        </div>

        <div className='mt-[8px]'>
          <Dropdown
            label='cardNumber'
            placeholder={t('vehicles_rfid_card_placeholder')}
            type={DropdownType.CHECKBOX}
            headerWidth={500}
            items={rfidsDropdown}
            onItemClick={(items: any) => hanldeRfidChange(items)}
            labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
            chevdownIconHighlightColor={ColorType.GREY6}
            headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
            contentDivHeight={300}
            contentDivWidth={500}
            showPillHeader
            pillIcon={RFIDCard}
            showFooter={rfidsDropdown?.length === 0}
            renderFooter={
              rfidsDropdown?.length === 0 && (
                <div className='flex pl-2 pr-12 h-12 text-left items-center text-sm text-grey4 font-normal'>
                  <Trans i18nKey='vehicles_no_rfid_cards' />
                </div>
              )
            }
            placement='top-start'
            dataTestId='rfidCard'
          />
        </div>

        {renderRFIDCardWarn()}
      </div>
    );
  };

  const renderBatteryCapacityError = () => {
    if (!_vehicle?.batteryCapacityKwh || _vehicle?.batteryCapacityKwh > 0) {
      return null;
    }
    return (
      <Label
        type={LabelType.LABEL_S}
        color={ColorType.NEGATIVE}
        className='pt-1 block'
        text={t('vehicle_battery_capacity_warn')}
      />
    );
  };

  const onSaveValidation = () => {
    if (
      isVehicleNameError ||
      isVehicleVinError ||
      !_vehicle?.vin ||
      _vehicle.vin.length !== VIN_LEN ||
      !_vehicle?.make ||
      !_vehicle?.model ||
      !_vehicle?.batteryCapacityKwh ||
      Number(_vehicle?.batteryCapacityKwh) <= 0
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isRegisterRfidSuccess) {
      hanldeRfidChange([
        { selected: true, cardNumber: registerRfidArgs?.cardNumber },
      ]);
    }
  }, [isRegisterRfidSuccess]);

  return (
    <Form
      onSubmit={handleSave}
      queryResult={addVehicleQueryResult}
      onQuerySuccess={() => hideModal()}
      onQueryFailed={() => hideModal()}
    >
      <div className='flex flex-col justify-between h-full'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-row'>
            <div className='flex-1 flex flex-col'>
              <FormInput
                inputId='vehicles_name_unit_id'
                placeholder={t('vehicles_name_unit_id_holder')}
                label={t('vehicles_name_unit_id')}
                onChange={(event: any) => {
                  validateVehicleName(event.target.value);
                  handleVehicleChange('vehicleName', event.target.value);
                }}
                validationType={ValidationType.NOT_EMPTY}
                defaultValue={_vehicle?.vehicleName}
                width='242px'
                validaitonQueryResult={isVehicleNameError}
                showErrorIcon
                dataTestId='vehicleName'
              />
              {renderNameError()}
            </div>
            <div className='flex-1'>
              <FormInput
                inputId='vehicles_vin'
                placeholder={t('vehicles_vin')}
                label={t('vehicles_vin')}
                onChange={(event: any) => {
                  if (String(event.target.value).length === VIN_LEN) {
                    validateVehicleVin(event.target.value);
                  }
                  handleVehicleChange('vin', event.target.value);
                }}
                validationType={ValidationType.NOT_EMPTY}
                defaultValue={_vehicle?.vin}
                width='242px'
                minLen={String(VIN_LEN)}
                maxLen={String(VIN_LEN)}
                onValidation={handleValidateVin}
                validaitonQueryResult={isVehicleVinError}
                showErrorIcon
                inputType={InputType.VIN}
                dataTestId='vehicleVIN'
              />
              {renderVinError()}
            </div>
          </div>
          <div className='flex flex-row'>
            <div className='flex-1'>
              <FormInput
                inputId='vehicles_make'
                placeholder={t('vehicles_make')}
                label={t('vehicles_make')}
                onChange={(event: any) =>
                  handleVehicleChange('make', event.target.value)
                }
                validationType={ValidationType.NOT_EMPTY}
                defaultValue={_vehicle?.make}
                width='242px'
                dataTestId='vehicleMake'
              />
            </div>
            <div className='flex-1'>
              <FormInput
                inputId='vehicles_model'
                placeholder={t('vehicles_model')}
                label={t('vehicles_model')}
                onChange={(event: any) =>
                  handleVehicleChange('model', event.target.value)
                }
                validationType={ValidationType.NOT_EMPTY}
                defaultValue={_vehicle?.model}
                width='242px'
                dataTestId='vehicleModel'
              />
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <Label
              text={t('vehicles_battery_capacity')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
              labelHtmlFor='batteryCapacityKwh'
            />
            <div className='flex flex-row gap-2 items-center'>
              <FormInput
                inputId='batteryCapacityKwh'
                onChange={(event: any) =>
                  handleVehicleChange('batteryCapacityKwh', event.target.value)
                }
                inputType={InputType.NUMBER}
                validationType={ValidationType.POSITIVE_NUMBER}
                defaultValue={_vehicle?.batteryCapacityKwh}
                width='90px'
                showErrorIcon
                dataTestId='vehicleBattery'
              />
              <div>
                <Label
                  text={t('kwh')}
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                />
              </div>
            </div>
            {renderBatteryCapacityError()}
          </div>

          <div className='flex-1 flex flex-col gap-2'>
            <div className='flex flex-row gap-2 items-baseline'>
              <Label
                text={t('vehicles_home_location')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Label
                text={`(${t('optional')})`}
                type={LabelType.BODY3}
                color={ColorType.GREY6}
              />
            </div>
            <Dropdown
              placeholder={t('vehicles_location_placeholder')}
              headerWidth={500}
              items={locationsDropdown}
              onItemClick={(item: any, index: number) =>
                handleVehicleChange('locationId', item.id)
              }
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='hover:bg-grey2 bg-grey1 border-white rounded border-2'
              headerClassName='hover:bg-grey2 bg-grey1 border-white rounded border-2'
              contentDivHeight={300}
              contentDivWidth={500}
              showFooter={locationsDropdown?.length === 0}
              renderFooter={
                locationsDropdown?.length === 0 && (
                  <div className='flex pl-2 pr-12 h-12 text-left items-center text-sm text-grey4 font-normal'>
                    <Trans i18nKey='vehicles_no_locations_refistered' />
                  </div>
                )
              }
            />
          </div>
          <FormInput
            inputId='vehicles_note_place_holder'
            placeholder={t('vehicles_note_place_holder')}
            label={t('vehicles_note')}
            secondLabel={`(${t('optional')})`}
            onChange={(event: any) =>
              handleVehicleChange('note', event.target.value)
            }
            validationType={ValidationType.NOT_EMPTY}
            defaultValue={_vehicle?.note}
            width='500px'
          />
          {renderRegisterNewRFID()}
          {renderRegisterNewRFIDError()}
          {renderRFID()}
          <div className='mb-[20px] w-[500px]'>
            <Button
              label={t('save')}
              size={ButtonSize.SMALL_FULL}
              type={ButtonType.PRIMARY}
              disabled={!onSaveValidation()}
              isSumbit
              dataTestId='vehicleSave'
            />
          </div>
        </div>
      </div>
    </Form>
  );
});

VehicleEditor.displayName = 'VehicleEditor';
