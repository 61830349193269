import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckMark, ErrorWarningCircle } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { NUMBER } from '../../constant/Number.constant';
import { useCompany } from '../../hooks/useCompany';
import { useFetchTroubleChargersQuery } from '../../services/charger.api';
import { Card, ColorType, Icon, Label, LabelType, Skeleton } from '../_ui';

// interface chargersOverviewProps {
//   status?: string;
//   ports?: ChargerPort[];
//   isLoading?: boolean;
//   timeStamp?: number | string;
// }

export const ChargersOverview = memo(() => {
  const { t } = useTranslation();
  const { userScope } = useCompany();
  const { troubleCount, isTroubleCountFetching } = useFetchTroubleChargersQuery(
    userScope!,
    {
      selectFromResult: (endPoint) => ({
        troubleCount: endPoint.data ? endPoint.data.troubleCount : 0,
        isTroubleCountFetching: endPoint.isFetching,
      }),
    },
  );

  const text =
    troubleCount === 0
      ? t('chargers_status_all_online')
      : `${troubleCount} ${t(
          `charger${troubleCount > 1 ? 's' : ''}_status_has_trouble`,
        )}`;

  return (
    <div>
      <Card className='border-2 border-grey2 mb-6'>
        {isTroubleCountFetching ? (
          <div className='flex flex-col gap-3'>
            <Label
              text={t('chargers_overview')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY5}
            />
            <div className='flex flex-row items-center gap-2'>
              <Skeleton height='h-5' width='w-7' />
              <Skeleton height='h-5' width='w-20' />
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-3'>
            <Label
              text={t('chargers_overview')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
            />
            <div className='flex flex-row items-center gap-2'>
              <Icon
                size={IconSize.SIZE_32x32}
                src={
                  troubleCount > NUMBER.ZERO ? ErrorWarningCircle : CheckMark
                }
                color={
                  troubleCount > NUMBER.ZERO ? undefined : ColorType.POSITIVE
                }
              />
              <Label text={text} type={LabelType.H3} color={ColorType.BLACK} />
            </div>
            {troubleCount > NUMBER.ZERO && (
              <Label
                text={t('chargers_trouble_desc')}
                type={LabelType.BODY3}
                color={ColorType.GREY6}
              />
            )}
            {troubleCount > NUMBER.ZERO && (
              <ul className='list-disc flex flex-col ml-6'>
                <li>
                  <Label
                    text={t('chargers_trouble_solution_1')}
                    type={LabelType.BODY3}
                    color={ColorType.GREY6}
                  />
                </li>
                <li>
                  <Label
                    text={t('chargers_trouble_solution_3')}
                    type={LabelType.BODY3}
                    color={ColorType.GREY6}
                  />
                </li>
                <li>
                  <Label
                    text={t('chargers_trouble_solition_2')}
                    type={LabelType.BODY3}
                    color={ColorType.GREY6}
                  />
                </li>
              </ul>
            )}
          </div>
        )}
      </Card>
    </div>
  );
});

ChargersOverview.displayName = 'ChargersOverview';
