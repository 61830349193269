import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableNoData, User } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { useFetchSupportNoteQuery } from '../../services/supportnote.api';
import { formatDate } from '../../utils/Date.Util';
import { ColorType, Divider, Icon, Label, LabelType } from '../_ui';
import SupportNotesButton from './Support-Notes-Button.component';

interface IProps {
  chargerId: string;
}

// eslint-disable-next-line react/display-name
const SupportNotes = React.memo(({ chargerId }: IProps) => {
  const { t } = useTranslation();
  const { data: note } = useFetchSupportNoteQuery(chargerId);
  const isAdd = useMemo(() => {
    return !note;
  }, [note]);

  const renderNotFound = () => {
    return (
      <div className='flex flex-col justify-center items-center gap-4 py-4'>
        <div className='py-4 flex-col justify-start items-center gap-1 inline-flex'>
          <Icon src={TableNoData} size={IconSize.SIZE_48x48} />
          <div className='flex-col justify-start items-center gap-1 flex'>
            <Label
              text={t('support_notes_not_found')}
              type={LabelType.H4}
              color={ColorType.GREY5}
            />
            <Label
              text={t('support_notes_not_found_desc')}
              type={LabelType.BODY2}
              color={ColorType.GREY5}
            />
          </div>
        </div>
        <SupportNotesButton chargerId={chargerId} />
      </div>
    );
  };

  const renderNote = () => {
    return (
      <div className='flex flex-col justify-start items-start'>
        <div className='flex flex-row justify-between w-full items-center'>
          <div className='justify-start items-center inline-flex'>
            <div className='w-[148px] h-[62px] pt-2.5 justify-start items-start flex'>
              <Label
                text={t('support_notes_last_edited')}
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
              />
            </div>
            <div className='flex pl-3 py-2.5 flex-col justify-start items-start gap-0.5'>
              <div className='justify-start items-center gap-1 inline-flex'>
                <Icon
                  src={User}
                  size={IconSize.SIZE_16x16}
                  color={ColorType.GREY6}
                />
                <Label
                  text={note?.username}
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                />
              </div>
              <div className='pl-5 justify-start items-center gap-1 inline-flex'>
                <Label
                  text={
                    note?.updatedAt
                      ? formatDate(
                          new Date(note.updatedAt),
                          'MMM d, yyyy, h:mm a',
                        )
                      : ''
                  }
                  type={LabelType.BODY4}
                  color={ColorType.GREY6}
                />
              </div>
            </div>
          </div>
          <SupportNotesButton chargerId={chargerId} />
        </div>
        <Divider className='w-full' margin='12px' />
        <div className='justify-start items-start inline-flex'>
          <div className='w-[148px] justify-start items-center gap-2.5 flex'>
            <div className='w-[148px] self-stretch pt-2.5 bg-white justify-start items-start flex'>
              <Label
                text={t('support_notes_note')}
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
              />
            </div>
          </div>
          <div className='pl-3 py-2.5 justify-start items-start gap-2.5 flex break-all'>
            <Label
              text={note?.note}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          </div>
        </div>
        <div className='justify-start items-center inline-flex'>
          <div className='w-[148px] justify-start items-center gap-2.5 flex'>
            <div className='w-[148px] h-10 pt-2.5 bg-white justify-start items-start flex'>
              <Label
                text={t('support_notes_link')}
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
              />
            </div>
          </div>
          <div
            className='pl-3 py-2.5 justify-start items-center gap-2.5 flex cursor-pointer break-all'
            onClick={() => {
              const link = note?.link;
              const formattedLink =
                link?.startsWith('http://') || link?.startsWith('https://')
                  ? link
                  : `https://${link}`;
              window.open(formattedLink);
            }}
          >
            <Label
              text={note?.link}
              type={LabelType.BODY3}
              color={ColorType.BRAND2}
            />
          </div>
        </div>
      </div>
    );
  };

  return <div className='pt-4'>{isAdd ? renderNotFound() : renderNote()}</div>;
});

export default SupportNotes;
