import _ from 'lodash';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight } from '../../../assets/icons';
import { NUMBER } from '../../../constant/Number.constant';
import { Button, ButtonSize, ButtonType } from '../Button.component';
import { Icon } from '../Icon.component';
import { FormInput, InputType } from '../Input.component';
import { Label, LabelType } from '../Label.component';
import { ColorType } from '../shared/Colors.g';

interface InputProps {
  totalPage: number;
  currentPage: number;
  loadPage?: (page: number) => void;
  showPageSearch: boolean;
}
export const GridPager = memo(
  ({ totalPage, currentPage, showPageSearch, loadPage }: InputProps) => {
    const PAGE_COUNT_CURR_PAGE = 1;
    const PAGE_COUNT_IN_MIDDLE_RANGE = 7;
    const PAGE_COUNT_IN_MIDDLE_RANGE_EXCLUDING_CURR_PAGE = 6;
    const PAGE_COUNT_IN_END_RANGE = 9;
    const PAGE_COUNT_IN_END_RANGE_EXCLUDING_CURR_PAGE = 8;
    const NUMERIC_REPRESENTATION_OF_ELLIPSIS = -1;
    const ELLIPSIS = '...';

    const { t } = useTranslation();

    const [goToPage, setGoToPage] = useState<number>();
    const isLeftArrowAvailable = currentPage !== 1;
    const isRightArrowAvailable = currentPage < totalPage;

    const handleLeftArrowClick = () => {
      isLeftArrowAvailable && loadPage && loadPage(currentPage - 1);
    };

    const handleRightArrowClick = () => {
      isRightArrowAvailable && loadPage && loadPage(currentPage + 1);
    };

    const getRange = (start: number, end: number) => {
      return Array(end - start + 1)
        .fill(0)
        .map((v, i) => i + start);
    };

    /**
     * Calculate the start page number for the range that will be displayed on UI
     * Middle range - sequence of page number between two ellipsis [1, ..., 10, 11, 12, 13, 14, 15, 16, ..., 21]
     * End Range - sequence of page number after left ellipsis when there's no right ellipsis [1, ..., 10, 11, 12]
     * @param curPage Current page number
     * @param totalPageCount Total page count
     * @param isMiddleRange True for middle range, false for end range.
     * @returns starting page number to be displayed
     */
    const calculateStartPageNumber = (
      curPage: number,
      totalPageCount: number,
      isMiddleRange: boolean = true,
    ) => {
      // Total num of pages before current page
      // 10 is combination of first 9 pages + current page
      const totalPagesBeforeCurrentPage = curPage - 10;
      // Num of pages before current page in range displayed on UI
      let pagesBeforeCurrentPageInDisplayedRange = totalPagesBeforeCurrentPage;
      if (isMiddleRange) {
        // Max 6 pages can be there before current page in middle range
        while (
          pagesBeforeCurrentPageInDisplayedRange >
          PAGE_COUNT_IN_MIDDLE_RANGE_EXCLUDING_CURR_PAGE
        ) {
          // Remove (if any) previous middle range page count from pagesBeforeCurrentPageInDisplayedRange
          // Note: There will be always 7 page number in middle range as per our design
          pagesBeforeCurrentPageInDisplayedRange -= PAGE_COUNT_IN_MIDDLE_RANGE;
        }
      } else {
        // Max 8 pages can be there before current page in end range
        while (
          pagesBeforeCurrentPageInDisplayedRange + (totalPageCount - curPage) >
          PAGE_COUNT_IN_END_RANGE_EXCLUDING_CURR_PAGE
        ) {
          // Remove (if any) previous middle range page count from pagesBeforeCurrentPageInDisplayedRange
          // Note: There will be always 7 page number in middle range as per our design
          pagesBeforeCurrentPageInDisplayedRange -= PAGE_COUNT_IN_MIDDLE_RANGE;
        }
      }
      // start page number of the range
      return curPage - pagesBeforeCurrentPageInDisplayedRange;
    };

    /**
     * Checks if right ellipsis needs to be shown for pagination list based on user's current page
     * @param curPage Current page number
     * @param totalPageCount Total pages
     * @returns boolean
     */
    const rightEllipsisVisible = (curPage: number, totalPageCount: number) => {
      // As per our design if there > 11 pages, we need to show right ellipsis
      if (curPage <= 9) {
        return true;
      }

      let pagesBeforeCurrentPage = curPage - 10;

      while (pagesBeforeCurrentPage >= PAGE_COUNT_IN_MIDDLE_RANGE) {
        pagesBeforeCurrentPage -= PAGE_COUNT_IN_MIDDLE_RANGE;
      }
      // Gives us start page number of the range
      const startRange = curPage - pagesBeforeCurrentPage;
      return (
        totalPageCount - startRange + PAGE_COUNT_CURR_PAGE >
        PAGE_COUNT_IN_END_RANGE
      );
    };

    const pagination = (currentPage1: number, totalPages: number) => {
      let pagesOfRange: number[] = [] as number[];
      // <= 10 pages -> show no ellipsis
      // 11 pages --> show no ellipsis (as max there could be 11 elements when totalpage > 10)
      if (totalPages <= 11) {
        return getRange(1, totalPages);
      }

      // Left ellipsis: when current page > 9
      const showLeftEllipsis = currentPage1 > 9;

      // Right ellipsis: when total page > 11 and current page <= 9
      // Right ellipsis: when total page > 11 and total pages - start page of range > 9
      const showRightEllipsis = rightEllipsisVisible(currentPage1, totalPages);

      // Example case : [ 1, ..., 10, 11, 12, 13 ]
      if (showLeftEllipsis && !showRightEllipsis) {
        pagesOfRange = getRange(
          calculateStartPageNumber(currentPage1, totalPages, false),
          totalPages,
        );
        pagesOfRange = [1, NUMERIC_REPRESENTATION_OF_ELLIPSIS].concat([
          ...pagesOfRange,
        ]);
      }

      // Example case : [ 1, 2, 3, 4, 5, 6, 7, 8, 9, ..., 13 ]
      if (!showLeftEllipsis && showRightEllipsis) {
        pagesOfRange = getRange(1, 9);
        pagesOfRange = pagesOfRange.concat([
          NUMERIC_REPRESENTATION_OF_ELLIPSIS,
          totalPages,
        ]);
      }

      // Example case : [ 1, ..., 10, 11, 12, 13, 14, 15, 16, ..., 65 ]
      if (showLeftEllipsis && showRightEllipsis) {
        pagesOfRange = getRange(
          calculateStartPageNumber(currentPage1, totalPages),
          calculateStartPageNumber(currentPage1, totalPages) + 6,
        );
        pagesOfRange = [1, NUMERIC_REPRESENTATION_OF_ELLIPSIS]
          .concat([...pagesOfRange])
          .concat([NUMERIC_REPRESENTATION_OF_ELLIPSIS, totalPages]);
      }
      return pagesOfRange;
    };

    const handlePageNumValidation = (val: number) => {
      return val ? val > 0 && val <= totalPage : true;
    };

    const handleButtonVisibility = () => {
      return _.isNumber(goToPage) && !handlePageNumValidation(goToPage);
    };

    const handleGoBtnClick = () => {
      loadPage && goToPage && loadPage(goToPage);
    };

    const handleInputChange = (event: any) => {
      Number(event.target.value) > 0 && setGoToPage(Number(event.target.value));
    };

    const handlePageNumClick = (value: number) => {
      if (value !== NUMERIC_REPRESENTATION_OF_ELLIPSIS && loadPage) {
        setGoToPage(undefined);
        loadPage(value);
      }
    };

    return (
      <>
        <div className='flex items-center justify-between'>
          <div className='flex w-[211px]'>{}</div>
          <div className='flex items-center mt-5'>
            <div
              className={`flex pager-span items-center justify-center w-8 text-sm font-normal ${
                isLeftArrowAvailable
                  ? ' hover:bg-grey2 rounded-full  cursor-pointer'
                  : ' opacity-30'
              }`}
              onClick={handleLeftArrowClick}
            >
              <Icon src={ChevronLeft} />
            </div>
            {pagination(currentPage, totalPage)?.map(
              (value: number, index: number) => (
                <div
                  key={`${index + 1}`}
                  onClick={() => handlePageNumClick(value)}
                  className={`flex pager-span w-8 items-center justify-center text-sm font-normal cursor-pointer ${
                    value !== NUMERIC_REPRESENTATION_OF_ELLIPSIS
                      ? value === currentPage
                        ? 'current-page'
                        : 'hover:bg-grey2 rounded-full text-grey5'
                      : ''
                  }`}
                >
                  {value === NUMERIC_REPRESENTATION_OF_ELLIPSIS
                    ? ELLIPSIS
                    : value}
                </div>
              ),
            )}

            <div
              className={`flex pager-span items-center justify-center w-8 text-sm font-normal ${
                isRightArrowAvailable
                  ? ' hover:bg-grey2 rounded-full cursor-pointer'
                  : ' opacity-30'
              }`}
              onClick={handleRightArrowClick}
            >
              <Icon src={ChevronRight} />
            </div>
          </div>

          <div className='flex items-center gap-1 mt-5 w-[211px]'>
            {totalPage > NUMBER.TEN && showPageSearch && (
              <>
                <Label
                  type={LabelType.LABEL_S_MEDIUM}
                  color={ColorType.GREY5}
                  text={t('grid_page_selector_label')}
                  className='w-[78px]'
                />
                <FormInput
                  defaultValue={goToPage}
                  placeholder={`1-${totalPage}`}
                  inputType={InputType.NUMBER}
                  props={{ style: { width: '72px' } }}
                  onChange={(event: any) => handleInputChange(event)}
                  onValidation={handlePageNumValidation}
                />
                <Button
                  size={ButtonSize.SMALL}
                  type={ButtonType.OUTLINE}
                  label={t('grid_page_selector_btn')}
                  disabled={handleButtonVisibility()}
                  onClick={handleGoBtnClick}
                />
              </>
            )}
          </div>
        </div>
        {totalPage > NUMBER.TEN && (
          <div className='flex flex-row-reverse'>
            {_.isNumber(goToPage) && !handlePageNumValidation(goToPage) && (
              <Label
                type={LabelType.LABEL_S_MEDIUM}
                color={ColorType.NEGATIVE}
                className='pr-[88px]'
                text={t('grid_page_selector_error_msg')}
              />
            )}
          </div>
        )}
      </>
    );
  },
);
