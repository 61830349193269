import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorWarningtraiangle, Info } from '../../assets/icons';
import {
  useCreateCircuitMutation,
  useGetCircuitsQuery,
  useUpdateBreakerMutation,
  useUpdateCircuitMutation,
} from '../../services/pm.api';
import {
  Breaker as BreakerObj,
  Circuit,
} from '../../stores/types/pm.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  FormInput,
  GroupDirection,
  Icon,
  Label,
  LabelType,
  RadioGroup,
  Select,
  SelectItem,
  Switch,
  useGlobalModalContext,
} from '../_ui';
import { Form } from '../_ui/Form.component';
import { InputType, ValidationType } from '../_ui/Input.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';

import { SCOPE_TYPE } from '../../services/utils';
import { ELECTRIC_SUPPLY } from './utils';

interface Props {
  locationID: string;
  breaker?: BreakerObj;
  circuit?: Circuit;
}

const voltageOptions = [208, 240, 415, 480, 600];
const splitPhaseVoltage = 240;
export const BreakerEditor = ({ locationID, breaker, circuit }: Props) => {
  const { t } = useTranslation();
  const [breakerName, setBreakerName] = useState<string>('');
  const [is3Phase, setIs3Phase] = useState(true);
  const [volt, setVolt] = useState<number>();
  const [voltIndex, setVoltIndex] = useState<number>(-1);
  const [isNonStandardVoltage, setIsNonStandardVoltage] =
    useState<boolean>(false);
  const [amp, setAmp] = useState<number>();
  const { data: circuitsData } = useGetCircuitsQuery({
    scope: SCOPE_TYPE.COMPANY,
    filter: {
      locationId: { in: locationID },
    },
  });

  const [triggerCreateCircuit, createCircuitResult] =
    useCreateCircuitMutation();
  const [triggerEditCircuit, editCircuitResult] = useUpdateCircuitMutation();
  const [triggerEditBreaker] = useUpdateBreakerMutation();

  // creating or editing
  const isNew = useMemo(() => {
    return !breaker && !circuit;
  }, [breaker, circuit]);

  useEffect(() => {
    if (!isNew && breaker && circuit) {
      setBreakerName(breaker.name!);
      const isType3Phase =
        circuit.electricalSupply === ELECTRIC_SUPPLY.THREE_PHASE;
      setIs3Phase(isType3Phase);
      if (isType3Phase) {
        setVolt(circuit.lineVoltage);
        const index = voltageOptions.findIndex(
          (option) => option === circuit.lineVoltage,
        );
        // set dropdown index
        setVoltIndex(index);
        // index === -1 means the volt is a custom value, switch this to On.
        setIsNonStandardVoltage(index === -1);
      } else {
        // set fixed volt
        setVolt(splitPhaseVoltage);
      }
      setAmp(breaker.breakerRating);
    }
  }, [isNew, breaker, circuit]);

  const onVoltageSelect = (index: number) => {
    setVoltIndex(index);
    setVolt(voltageOptions[index]);
  };
  const handleNonStandardChange = (val: boolean) => {
    setIsNonStandardVoltage(val);
    if (val) {
      setVolt(0);
    } else {
      setVolt(voltageOptions[voltIndex]);
    }
  };
  const { hideModal } = useGlobalModalContext();

  const handleSave = () => {
    if (isNew) {
      triggerCreateCircuit({
        scope: 'company',
        body: {
          locationId: locationID,
          electricalSupply: is3Phase
            ? (ELECTRIC_SUPPLY.THREE_PHASE as any)
            : (ELECTRIC_SUPPLY.SPLIT_PHASE as any),
          lineVoltage: Number(volt),
          name: breakerName,
          breakerRating: Number(amp),
        },
      });
    } else {
      triggerEditCircuit({
        circuitId: circuit!.id,
        scope: 'company',
        body: {
          id: circuit!.id,
          mainBreakerId: breaker!.id,
          locationId: circuit!.locationId,
          electricalSupply: is3Phase
            ? (ELECTRIC_SUPPLY.THREE_PHASE as any)
            : (ELECTRIC_SUPPLY.SPLIT_PHASE as any),
          lineVoltage: Number(volt),
          name: breakerName,
          breakerRating: Number(amp),
        },
      });
      triggerEditBreaker({
        breakerId: breaker!.id,
        scope: 'company',
        body: {
          id: breaker!.id,
          name: breakerName,
          breakerRating: Number(amp),
          breakerSpace: breaker!.breakerSpace,
        },
      });
    }
  };
  const OnSupplyTupeChange = (event: any) => {
    if (is3Phase && event[0].selected) {
      return;
    }
    setIs3Phase(event[0].selected);
    if (!event[0].selected) {
      setVolt(splitPhaseVoltage);
    } else {
      setVolt(voltageOptions[voltIndex]);
    }
  };

  const hasAnyLoad = (b: BreakerObj | undefined) => {
    if (!b) return false;
    const stack = [b];
    while (stack.length > 0) {
      const currentB = stack.pop() as BreakerObj;
      if (currentB) {
        if (
          currentB.chargers?.length > 0 ||
          currentB.unmanagedLoads?.length > 0
        ) {
          return true;
        }
        if (currentB.subBreakers?.length > 0) {
          stack.push(...currentB.subBreakers);
        }
      }
    }
  };

  const isSupplyTypeDisabled = useMemo(() => {
    return (
      !isNew &&
      circuit &&
      circuit.mainBreakers &&
      hasAnyLoad(circuit.mainBreakers[0])
    );
  }, [isNew, is3Phase, circuit]);

  const supplySwitchData = () => {
    return [
      {
        id: 'YES',
        label: t('pm_3_phase'),
        selected: is3Phase,
        disabled: isSupplyTypeDisabled,
      },
      {
        id: 'NO',
        label: t('pm_split_phase'),
        selected: !is3Phase,
        disabled: isSupplyTypeDisabled,
      },
    ];
  };

  const isAmpValid = useMemo(() => {
    return amp !== undefined && amp > 0 && !editCircuitResult.isError;
  }, [amp, editCircuitResult]);

  const isVoltValid = useMemo(() => {
    return volt !== undefined && volt > 0;
  }, [volt]);

  const ratingError = useMemo(() => {
    const errorObj = editCircuitResult as any;
    if (errorObj?.isError && errorObj?.error?.data?.code === 'ERR-PM-0024') {
      return t('pm_breaker_update_error');
    }
    return errorObj.isError ? t('pm_rate_lower_error') : t('pm_rate_error');
  }, [amp, volt, editCircuitResult]);

  const isNameValid = (val: string) => {
    const res = !circuitsData?.entities.some((mainBreaker) => {
      if (mainBreaker.name.toLowerCase() === val.trim().toLowerCase()) {
        if (!isNew && mainBreaker.id === circuit?.id) {
          return false;
        }
        return true;
      }
      return false;
    });
    return res;
  };

  const onSaveValidation = () => {
    return (
      breakerName === '' ||
      !isVoltValid ||
      !isAmpValid ||
      !isNameValid(breakerName)
    );
  };

  const minRatingValidation = useMemo(() => {
    return (
      amp !== undefined &&
      amp !== null &&
      isAmpValid &&
      amp < (breaker?.loadLimitRequirement || 0)
    );
  }, [amp, isAmpValid]);

  const minRatingWarning = () => {
    return (
      <div className='flex flex-row gap-2 rounded-lg border-2 border-warning p-2 bg-warning0 '>
        <Icon src={ErrorWarningtraiangle} color={ColorType.WARNING} />
        <div>
          <Label
            text={t('rating_warning_part_1')}
            type={LabelType.BODY4}
            color={ColorType.GREY6}
            className='inline'
            /* eslint-disable react/jsx-one-expression-per-line */
          />{' '}
          <Label
            /* eslint-disable react/jsx-wrap-multilines */
            text={
              <Trans
                i18nKey='rating_warning_part_2'
                values={{
                  loadLimitRequirement: breaker?.loadLimitRequirement || 0,
                }}
              />
            }
            type={LabelType.LABEL_XS}
            color={ColorType.GREY6}
            className='inline font-semibold'
            /* eslint-disable react/jsx-one-expression-per-line */
          />{' '}
          <Label
            text={t('rating_warning_part_3')}
            type={LabelType.BODY4}
            color={ColorType.GREY6}
            className='inline'
          />
        </div>
      </div>
    );
  };

  return (
    <Form
      onSubmit={handleSave}
      queryResult={isNew ? createCircuitResult : editCircuitResult}
      onQuerySuccess={() => {
        hideModal();
      }}
      onQueryFailed={() => {}}
    >
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-2'>
            <Label
              type={LabelType.LABEL_M}
              text={t('main_breaker_modal_field_1')}
            />
            <FormInput
              width='100%'
              defaultValue={breakerName}
              onChange={(event: any) => setBreakerName(event.target.value)}
              onValidation={isNameValid}
              errorLabel={t('pm_name_error')}
              dataTestId='mainBreakerName'
              inputType={InputType.CHARGELAB_ACCEPTED_CHARACTER}
            />
          </div>
          <div className='flex flex-col'>
            <div className='flex flex-row gap-1'>
              <Label
                type={LabelType.LABEL_M}
                text={t('main_breaker_modal_field_2')}
              />
              <Tooltip>
                <TooltipTrigger>
                  <Icon src={Info} />
                </TooltipTrigger>
                <TooltipContent>
                  <Label
                    text={t('main_breaker_modal_tooltip')}
                    type={LabelType.BODY3}
                    color={ColorType.WHITE}
                  />
                </TooltipContent>
              </Tooltip>
            </div>
            <Label
              className='mt-1'
              color={ColorType.GREY6}
              type={LabelType.BODY3}
              text={t('pm_power_supply_comment')}
            />
            <div className='mt-2'>
              <RadioGroup
                direction={GroupDirection.Horizontal}
                defaultItems={supplySwitchData()}
                onChange={OnSupplyTupeChange}
                itemHeight='20px'
                dataTestId='powerSupply'
              />
            </div>
            {is3Phase && (
              <div className='flex flex-row gap-2 mt-2 p-2 border-2 border-grey3 rounded-lg'>
                <Icon src={Info} />
                <Label
                  className=''
                  color={ColorType.GREY6}
                  type={LabelType.BODY4}
                  text={t('3_phase_info')}
                />
              </div>
            )}
          </div>

          <div className='flex flex-col gap-2'>
            <div className='flex gap-1'>
              <Label
                type={LabelType.LABEL_M}
                text={t('main_breaker_modal_field_3')}
              />
              <Label
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.GREY6}
                text={t('main_breaker_modal_field_4')}
              />
            </div>
            <div className='flex items-center'>
              {is3Phase ? (
                isNonStandardVoltage ? (
                  <div className='flex flex-col'>
                    <FormInput
                      inputType={InputType.NUMBER}
                      validationType={ValidationType.POSITIVE_NUMBER}
                      defaultValue={volt}
                      onChange={(event: any) => setVolt(event.target.value)}
                      placeholder='0'
                      width='58px'
                    />
                  </div>
                ) : (
                  <Select
                    defaultIndex={voltIndex}
                    defaultLabel=''
                    contentWidth={280}
                    contentHeight={480}
                    placement='bottom-start'
                    onItemSelected={onVoltageSelect}
                    buttonProps={{
                      type: ButtonType.TERTIARY,
                      iconRightAlign: true,
                      className: 'min-w-[80px]',
                      buttonStyle: {
                        height: '44px',
                      },
                    }}
                    dataTestId='voltage'
                  >
                    {voltageOptions.map((number) => {
                      return (
                        <SelectItem
                          key={`voltage-${number}`}
                          label={String(number)}
                          dataTestId='voltageOption'
                        />
                      );
                    })}
                  </Select>
                )
              ) : (
                <Label
                  type={LabelType.BODY3}
                  text={volt}
                  dataTestId='splitPhaseVoltage'
                />
              )}

              <Label
                className='ml-2'
                type={LabelType.BODY3}
                text={t('pm_volts')}
              />
              {is3Phase && (
                <Switch
                  className='ml-4'
                  value={isNonStandardVoltage}
                  enableLabel={t('pm_non_standard_voltage')}
                  disableLabel={t('pm_non_standard_voltage')}
                  onChange={handleNonStandardChange}
                />
              )}
            </div>
            {volt !== undefined && !isVoltValid && (
              <Label
                type={LabelType.BODY3}
                color={ColorType.NEGATIVE}
                text={t('pm_rate_error')}
              />
            )}
          </div>

          <div className='flex flex-col gap-2'>
            <Label
              type={LabelType.LABEL_M}
              text={t('main_breaker_modal_field_5')}
            />
            <div className='flex gap-2 items-center'>
              <FormInput
                inputType={InputType.NUMBER}
                validationType={ValidationType.POSITIVE_NUMBER}
                defaultValue={amp}
                onChange={(event: any) => {
                  editCircuitResult.reset();
                  setAmp(event.target.value);
                }}
                placeholder='0'
                width='58px'
                validaitonQueryResult={!isAmpValid || minRatingValidation}
                invalidErrorClass={
                  minRatingValidation
                    ? 'focus:outline-warning border-2 border-warning'
                    : undefined
                }
                dataTestId='rating'
              />
              <Label type={LabelType.BODY3} text={t('pm_amps')} />
            </div>
            {amp !== undefined && amp !== null && !isAmpValid && (
              <Label
                type={LabelType.BODY3}
                color={ColorType.NEGATIVE}
                text={ratingError}
                dataTestId='ratingError'
              />
            )}
            {minRatingValidation && minRatingWarning()}
          </div>

          <div className='flex flex-col gap-2'>
            <div className='flex gap-1'>
              <Label
                type={LabelType.LABEL_M}
                text={t('main_breaker_modal_field_6')}
              />
              <Label
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.GREY6}
                text={t('main_breaker_modal_field_7')}
              />
            </div>
            <Label
              type={LabelType.BODY3}
              color={amp ? ColorType.BLACK : ColorType.GREY4}
              text={
                amp
                  ? `${Math.round(amp * 0.8)} ${t('pm_amps')}`
                  : t('pm_max_continuous_load_comment')
              }
            />
          </div>
        </div>
        <Button
          className='mb-5'
          type={ButtonType.PRIMARY}
          size={ButtonSize.SMALL_FULL}
          label={t('save')}
          isSumbit
          disabled={onSaveValidation()}
          dataTestId='saveMainBreakerBtn'
        />
      </div>
    </Form>
  );
};
