/* eslint-disable prefer-destructuring */
import { Session, SessionObj } from '../stores/types';
import {
  SimpleStatsRequest,
  Statistics,
} from '../stores/types/stats.interface';
import { formatDateTime, formatIso, getLastWeek } from '../utils/Date.Util';
import { getStartedStatus } from '../utils/session.util';
import { API_KEYS, Api } from './base.api';
import { downloadBlob, providesListTag } from './utils';

// for payout CSV export, we need to use invoicedTime instead of startTime
const formatRecentTransactionsParams = (
  params: any,
  isPayout: boolean = false,
) => {
  const result: Record<string, any> = {};
  Object.entries(params)?.forEach(([key, value]: [string, any]) => {
    switch (key) {
      case 'vehicleId':
        if (value) {
          result['filter_eq[vehicleId]'] = value;
        }
        break;
      case 'locationId':
        if (value) {
          result['filter_eq[locationId]'] = value;
        }
        break;
      case 'chargers':
        if (
          value &&
          value.length > 0 &&
          value.some((charger: any) => charger.selected)
        ) {
          result['filter_in[chargerId]'] = value
            .filter((charger: any) => charger.selected)
            .map((charger: any) => charger.id)
            .join(',');
        }
        break;
      case 'chargerIds':
        if (value && value.length > 0) {
          result['filter_in[chargerId]'] = value.join(',');
        }
        break;
      case 'dateRange': {
        let startDate;
        let endDate;
        if (value && value.length >= 2) {
          startDate = value[0];
          endDate = value[1];
        } else {
          const lastWeek = getLastWeek();
          startDate = lastWeek[0];
          endDate = lastWeek[1];
        }
        if (isPayout) {
          result['filter_ge[invoicedTimeUtc]'] = formatDateTime(
            startDate,
            'yyyy-MM-dd',
          );
          result['filter_le[invoicedTimeUtc]'] = formatDateTime(
            endDate,
            'yyyy-MM-dd',
          );
        } else {
          result['filter_ge[startTime]'] = formatIso(startDate);
          result['filter_le[startTime]'] = formatIso(endDate);
        }

        break;
      }
      case 'filter_hasCompletedTx':
        // skip this
        break;
      case 'usersEmail':
        if (value && value !== '') {
          result.users = value;
        }
        break;
      default:
        result[key] = value;
        break;
    }
  });
  return result;
};

const formatStatParams = (params: any) => {
  if (params.aggregateLocations) {
    const { locationId, ...rest } = params;
    return rest;
  }

  return params;
};

export const historyApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchStatisticsV3: builder.query<Statistics[], SimpleStatsRequest>({
      query: (params) => ({
        url: `/internal/core/v3/${API_KEYS.HISTORICAL}/simplestats`,
        params: params,
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        providesListTag(
          result?.map((stat) => ({ id: stat.transactions })),
          API_KEYS.HISTORICAL,
        ),
    }),
    fetchStatistics: builder.query<Statistics[], any>({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.HISTORICAL}/simplestats`,
        params: formatStatParams(params),
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        providesListTag(
          result?.map((stat) => ({ id: stat.transactions })),
          API_KEYS.HISTORICAL,
        ),
    }),
    fetchStatisticsCSV: builder.query<Blob, any>({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.HISTORICAL}/simplestats`,
        params,
        method: 'GET',
        isCSV: true,
        responseHandler: (response) =>
          response
            .blob()
            .then(
              (blob) =>
                blob.size > 0 && downloadBlob(blob, 'Export Statistics', 'csv'),
            ),
      }),
    }),
    fetchStatisticsCSVV3: builder.query<Blob, any>({
      query: (params) => ({
        url: `/internal/core/v3/${API_KEYS.HISTORICAL}/simplestats`,
        params,
        method: 'GET',
        isCSV: true,
        responseHandler: (response) =>
          response
            .blob()
            .then(
              (blob) =>
                blob.size > 0 && downloadBlob(blob, 'Export Statistics', 'csv'),
            ),
      }),
    }),
    fetchRecentTransactions: builder.query<SessionObj, any>({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.HISTORICAL}/transactions`,
        params: formatRecentTransactionsParams(params),
        method: 'GET',
      }),
      transformResponse: (response: SessionObj) => ({
        totalCount: response.totalCount,
        entities: response.entities.map((session: Session) => {
          return {
            ...session,
            startedVia: getStartedStatus(session),
          };
        }),
      }),
    }),
    fetchTransactionReportCSVForPayout: builder.query<Blob, any>({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.HISTORICAL}/reports/transactions`,
        params: formatRecentTransactionsParams(params, true),
        method: 'GET',
        isCSV: true,
        responseHandler: (response) =>
          response
            .blob()
            .then(
              (blob) =>
                blob.size > 0 &&
                downloadBlob(blob, 'Export Transactions', 'csv'),
            ),
      }),
    }),
    fetchTransactionReportCSV: builder.query<Blob, any>({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.HISTORICAL}/reports/transactions`,
        params: formatRecentTransactionsParams(params),
        method: 'GET',
        isCSV: true,
        responseHandler: (response) =>
          response
            .blob()
            .then(
              (blob) =>
                blob.size > 0 &&
                downloadBlob(blob, 'Export Transactions', 'csv'),
            ),
      }),
    }),
  }),
});

export const {
  useLazyFetchStatisticsV3Query,
  useFetchStatisticsQuery,
  useLazyFetchStatisticsQuery,
  useLazyFetchStatisticsCSVQuery,
  useLazyFetchStatisticsCSVV3Query,
  useFetchRecentTransactionsQuery,
  useLazyFetchRecentTransactionsQuery,
  useLazyFetchTransactionReportCSVQuery,
  useLazyFetchTransactionReportCSVForPayoutQuery,
} = historyApi;
