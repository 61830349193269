/* eslint-disable import/no-useless-path-segments */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable quotes */
import {
  AddChargerApiArg,
  AddChargerApiResponse,
  CreateBreakerApiArg,
  CreateBreakerApiResponse,
  CreateCircuitApiArg,
  CreateCircuitApiResponse,
  CreateUnmanagedLoadApiArg,
  CreateUnmanagedLoadApiResponse,
  DeleteBreakerApiArg,
  DeleteBreakerApiResponse,
  DeleteCircuitApiArg,
  DeleteCircuitApiResponse,
  DeleteLoadApiArg,
  GetCircuitApiArg,
  GetCircuitApiResponse,
  GetCircuitsApiArg,
  GetCircuitsApiResponse,
  UpdateBreakerApiArg,
  UpdateBreakerApiResponse,
  UpdateChargerApiArg,
  UpdateChargerApiResponse,
  UpdateCircuitApiArg,
  UpdateCircuitApiResponse,
  UpdateUnmanagedLoadApiArg,
  UpdateUnmanagedLoadApiResponse,
} from '../../src/stores/types/pm.interface';
import { CheckMark, ErrorWarningtraiangle } from '../assets/icons';
import { ColorType } from '../components/_ui';
import { API_KEYS, Api as api } from '../services/base.api';

export const addTagTypes = ['Circuits', 'Circuit', 'Breakers'] as const;

const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getCircuits: build.query<GetCircuitsApiResponse, GetCircuitsApiArg>({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/circuits`,
          params: {
            expandChildren: queryArg.expandChildren,
            scope: queryArg.scope,
            companyId: queryArg.companyId,
            filter: queryArg.filter,
            offset: queryArg.offset,
            limit: queryArg.limit,
            sort: queryArg.sort,
          },
        }),
        providesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
        ],
      }),
      createCircuit: build.mutation<
        CreateCircuitApiResponse,
        CreateCircuitApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/circuits`,
          method: 'POST',
          body: queryArg.body,
          params: {
            scope: queryArg.scope,
            companyId: queryArg.companyId,
          },
        }),
        invalidatesTags: ['Circuits'],
      }),
      getCircuit: build.query<GetCircuitApiResponse, GetCircuitApiArg>({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/circuits/${queryArg.circuitId}`,
          params: {
            expandChildren: queryArg.expandChildren,
            scope: queryArg.scope,
            companyId: queryArg.companyId,
          },
        }),
        providesTags: ['Circuit'],
      }),
      updateCircuit: build.mutation<
        UpdateCircuitApiResponse,
        UpdateCircuitApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/circuits/${queryArg.circuitId}`,
          method: 'PUT',
          body: queryArg.body,
          params: {
            scope: queryArg.scope,
            companyId: queryArg.companyId,
          },
        }),
        invalidatesTags: ['Circuits'],
      }),
      deleteCircuit: build.mutation<
        DeleteCircuitApiResponse,
        DeleteCircuitApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/circuits/${queryArg.circuitId}`,
          method: 'DELETE',
          params: {
            scope: queryArg.scope,
            companyId: queryArg.companyId,
          },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
      createBreaker: build.mutation<
        CreateBreakerApiResponse,
        CreateBreakerApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/breakers`,
          method: 'POST',
          body: queryArg.body,
          params: {
            scope: queryArg.scope,
            companyId: queryArg.companyId,
          },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
      addChargerLoad: build.mutation<AddChargerApiResponse, AddChargerApiArg>({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/breakers/${queryArg.breakerId}/chargers`,
          method: 'POST',
          body: queryArg.body,
          params: {
            scope: queryArg.scope,
            companyId: queryArg.companyId,
            bulkOperation: queryArg.bulkOperation,
          },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
      updatePMCharger: build.mutation<
        UpdateChargerApiResponse,
        UpdateChargerApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/breakers/${queryArg.breakerId}/chargers`,
          method: 'PUT',
          body: queryArg.body,
          params: {
            scope: queryArg.scope,
            companyId: queryArg.companyId,
          },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
      createUnmanagedLoad: build.mutation<
        CreateUnmanagedLoadApiResponse,
        CreateUnmanagedLoadApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/breakers/${queryArg.breakerId}/unmanagedLoads`,
          method: 'POST',
          body: queryArg.body,
          params: {
            scope: queryArg.scope,
            companyId: queryArg.companyId,
            bulkOperation: queryArg.bulkOperation,
          },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
      updateUnmanagedLoad: build.mutation<
        UpdateUnmanagedLoadApiResponse,
        UpdateUnmanagedLoadApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/breakers/${queryArg.breakerId}/unmanagedLoads`,
          method: 'PUT',
          body: queryArg.body,
          params: {
            scope: queryArg.scope,
            companyId: queryArg.companyId,
          },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
      updateBreaker: build.mutation<
        UpdateBreakerApiResponse,
        UpdateBreakerApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/breakers/${queryArg.breakerId}`,
          method: 'PUT',
          body: queryArg.body,
          params: { scope: queryArg.scope, companyId: queryArg.companyId },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
      deleteBreaker: build.mutation<
        DeleteBreakerApiResponse,
        DeleteBreakerApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/breakers/${queryArg.breakerId}`,
          method: 'DELETE',
          params: { scope: queryArg.scope, companyId: queryArg.companyId },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
      deleteChargerLoad: build.mutation<
        DeleteBreakerApiResponse,
        DeleteLoadApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/breakers/${queryArg.breakerId}/charger/${queryArg.deviceId}`,
          method: 'DELETE',
          params: { scope: queryArg.scope, companyId: queryArg.companyId },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
      deleteUnmanagedLoad: build.mutation<
        DeleteBreakerApiResponse,
        DeleteLoadApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/breakers/${queryArg.breakerId}/unmanagedLoad/${queryArg.deviceId}`,
          method: 'DELETE',
          params: { scope: queryArg.scope, companyId: queryArg.companyId },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
      clearProfile: build.mutation<
        any,
        { scope: string; companyId?: string; chargerId: string }
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/chargers/${queryArg.chargerId}/clearProfile`,
          method: 'POST',
          params: { scope: queryArg.scope, companyId: queryArg.companyId },
          toast: {
            successTrans: 'pm_clear_profile_success',
            failedTrans: 'pm_clear_profile_failed',
            successIcon: { icon: CheckMark, color: ColorType.POSITIVE },
            failedIcon: {
              icon: ErrorWarningtraiangle,
              color: ColorType.WARNING,
            },
          },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: 'Breakers', id: 'LIST' },
          { type: API_KEYS.CHARGERS, id: arg?.chargerId },
          { type: API_KEYS.CHARGERS, id: 'LIST' },
        ],
      }),
      pausePowerManagement: build.mutation<
        any,
        {
          deviceId: string;
          reservePowerWhenOffline: boolean;
        }
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/chargers/${queryArg.deviceId}`,
          method: 'PATCH',
          body: { reservePowerWhenOffline: queryArg.reservePowerWhenOffline },
          toast: {
            failedTrans: 'pause_pm_error',
            failedIcon: {
              icon: ErrorWarningtraiangle,
              color: ColorType.WARNING,
            },
          },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
      resumePowerManagement: build.mutation<
        any,
        {
          deviceId: string;
          reservePowerWhenOffline: boolean;
        }
      >({
        query: (queryArg) => ({
          url: `/internal/powerman/v4/chargers/${queryArg.deviceId}`,
          method: 'PATCH',
          body: { reservePowerWhenOffline: queryArg.reservePowerWhenOffline },
          toast: {
            successTrans: 'resume_pm_success',
            failedTrans: 'resume_pm_error',
            successIcon: { icon: CheckMark, color: ColorType.POSITIVE },
            failedIcon: {
              icon: ErrorWarningtraiangle,
              color: ColorType.WARNING,
            },
          },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: API_KEYS.CIRCUITS, id: 'LIST' },
          { type: API_KEYS.LOCATION, id: 'LIST' },
        ],
      }),
    }),
    overrideExisting: false,
  });

export { injectedRtkApi as pmApi };

export const {
  useGetCircuitsQuery,
  useLazyGetCircuitsQuery,
  useCreateCircuitMutation,
  useGetCircuitQuery,
  useLazyGetCircuitQuery,
  useUpdateCircuitMutation,
  useDeleteCircuitMutation,
  useCreateBreakerMutation,
  useAddChargerLoadMutation,
  useUpdatePMChargerMutation,
  useCreateUnmanagedLoadMutation,
  useUpdateUnmanagedLoadMutation,
  useUpdateBreakerMutation,
  useDeleteBreakerMutation,
  useDeleteChargerLoadMutation,
  useDeleteUnmanagedLoadMutation,
  useClearProfileMutation,
  usePausePowerManagementMutation,
  useResumePowerManagementMutation,
} = injectedRtkApi;
