import {
  GenerateNyserdaReportAsyncRequest,
  GenerateReportSyncRequest,
  GenerateSessionsReportAsyncRequest,
  GenerateStatisticsReportAsyncRequest,
  ReportType,
  Reports,
} from '../stores/types/report.interface';
import { formatDate, getISO8601TimezoneOffset } from '../utils/Date.Util';
import { Api } from './base.api';
import { downloadBlob, extractFilename } from './utils';

const API_KEY = 'reports';

const makeReportUrl = (data: Reports) => {
  let url = `/internal/core/v2/historical/${API_KEY}/session:generate`;
  if (data?.report === ReportType.INTERVALS) {
    url = `/internal/core/v2/historical/${API_KEY}/periodicsession:generate`;
  } else if (data?.report === ReportType.CHARGER_STATS) {
    url = `/internal/core/v2/historical/${API_KEY}/chargerstatistics:generate`;
  } else if (data?.report === ReportType.NYSERDA_ENERGY_USE) {
    url = `/internal/core/v2/historical/${API_KEY}/nyserdaenergyuse:generate`;
  } else if (data?.report === ReportType.NYSERDA_STATUS_HISTORY) {
    url = `/internal/core/v2/historical/${API_KEY}/nyserdastatus:generate`;
  }
  return url;
};

const formatPayload = (data: Reports, requestedAt: Date) => {
  const userTimezoneOffset = getISO8601TimezoneOffset(requestedAt);
  const payload: any = { requestedAt, userTimezoneOffset };
  if (
    data?.report === ReportType.INTERVALS ||
    data?.report === ReportType.FULL_SESSION
  ) {
    if (data.chargerIds && data.chargerIds[0] === 'all') {
      return {
        ...payload,
        fromSessionCompletedDate: data?.fromSessionCompletedDate,
        toSessionCompletedDate: data?.toSessionCompletedDate,
      };
    }
    return {
      ...payload,
      chargerIds: data?.chargerIds,
      fromSessionCompletedDate: data?.fromSessionCompletedDate,
      toSessionCompletedDate: data?.toSessionCompletedDate,
    };
  }
  if (
    data?.report === ReportType.NYSERDA_ENERGY_USE ||
    data?.report === ReportType.NYSERDA_STATUS_HISTORY
  ) {
    if (data.chargerIds && data.chargerIds[0] === 'all') {
      return {
        ...payload,
        reportStartDate: data?.fromSessionCompletedDate,
        reportEndDate: data?.toSessionCompletedDate,
      };
    }
    return {
      ...payload,
      chargerIds: data?.chargerIds,
      reportStartDate: data?.fromSessionCompletedDate,
      reportEndDate: data?.toSessionCompletedDate,
    };
  }
  if (data?.report === ReportType.CHARGER_STATS) {
    if (data.chargerIds && data?.chargerIds[0] === 'all') {
      return {
        ...payload,
        months: data?.months.map((month) => formatDate(month, 'yyyy-MM')),
      };
    }
    return {
      ...payload,
      chargerIds: data?.chargerIds,
      months: data?.months.map((month) => formatDate(month, 'yyyy-MM')),
    };
  }
};

export const reportApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    generateReportSync: builder.mutation<any, GenerateReportSyncRequest>({
      query: (request) => ({
        url: makeReportUrl(request.reportInfo),
        params: { scope: request.scope },
        body: formatPayload(request.reportInfo, request.requestedAt),
        method: 'POST',
        isCSV: true,
        responseHandler: async (response) => {
          let result: { asyncResponse: boolean } | undefined;
          if (response?.status === 200) {
            const blob = await response.blob();
            if (blob.size > 0) {
              const contentDisposition = response.headers.get('Content-Disposition') || '';
              const filename = extractFilename(contentDisposition) || 'Report.csv';
              downloadBlob(blob, filename);
              result = { asyncResponse: false };
            }
          } else if (response?.status === 202) {
           result = { asyncResponse: true };
          }
          return result ?? false;
        },
      }),
    }),
    generateStatisticsReportAsync: builder.mutation<
      void,
      GenerateStatisticsReportAsyncRequest
    >({
      query: (request) => {
        const { scope, companyIds, requestedAt, months } = request;
        const userTimezoneOffset = getISO8601TimezoneOffset(requestedAt);
        const formattedMonths = months.map((month) =>
          formatDate(month, 'yyyy-MM'),
        );
        const filterInCompaniesParam =
          companyIds[0] !== 'all' ? companyIds.join(',') : undefined;

        return {
          url: `/internal/core/v2/historical/${API_KEY}/chargerstatistics:generate-async`,
          params: { scope, 'filter_in[companyId]': filterInCompaniesParam },
          body: { requestedAt, userTimezoneOffset, months: formattedMonths },
          method: 'POST',
        };
      },
    }),
    generateSessionsReportAsync: builder.mutation<
      void,
      GenerateSessionsReportAsyncRequest
    >({
      query: (request) => {
        const { scope, companyIds, requestedAt, ...otherParams } = request;
        const userTimezoneOffset = getISO8601TimezoneOffset(requestedAt);
        const filterInCompaniesParam =
          companyIds[0] !== 'all' ? companyIds.join(',') : undefined;

        return {
          url: `/internal/core/v2/historical/${API_KEY}/session:generate-async`,
          params: { scope, 'filter_in[companyId]': filterInCompaniesParam },
          body: { requestedAt, userTimezoneOffset, ...otherParams },
          method: 'POST',
        };
      },
    }),
    generateSessionIntervalsReportAsync: builder.mutation<
      void,
      GenerateSessionsReportAsyncRequest
    >({
      query: (request) => {
        const { scope, companyIds, requestedAt, ...otherParams } = request;
        const userTimezoneOffset = getISO8601TimezoneOffset(requestedAt);
        const filterInCompaniesParam =
          companyIds[0] !== 'all' ? companyIds.join(',') : undefined;

        return {
          url: `/internal/core/v2/historical/${API_KEY}/periodicsession:generate-async`,
          params: { scope, 'filter_in[companyId]': filterInCompaniesParam },
          body: { requestedAt, userTimezoneOffset, ...otherParams },
          method: 'POST',
        };
      },
    }),
    generateNyserdaEnergyUseReportAsync: builder.mutation<
      void,
      GenerateNyserdaReportAsyncRequest
    >({
      query: (request) => {
        const { scope, companyIds, requestedAt, ...otherParams } = request;
        const userTimezoneOffset = getISO8601TimezoneOffset(requestedAt);
        const filterInCompaniesParam =
          companyIds[0] !== 'all' ? companyIds.join(',') : undefined;

        return {
          url: `/internal/core/v2/historical/${API_KEY}/nyserdaenergyuse:generate-async`,
          params: { scope, 'filter_in[companyId]': filterInCompaniesParam },
          body: { requestedAt, userTimezoneOffset, ...otherParams },
          method: 'POST',
        };
      },
    }),
    generateNyserdaStatusReportAsync: builder.mutation<
      void,
      GenerateNyserdaReportAsyncRequest
    >({
      query: (request) => {
        const { scope, companyIds, requestedAt, ...otherParams } = request;
        const userTimezoneOffset = getISO8601TimezoneOffset(requestedAt);
        const filterInCompaniesParam =
          companyIds[0] !== 'all' ? companyIds.join(',') : undefined;

        return {
          url: `/internal/core/v2/historical/${API_KEY}/nyserdastatus:generate-async`,
          params: { scope, 'filter_in[companyId]': filterInCompaniesParam },
          body: { requestedAt, userTimezoneOffset, ...otherParams },
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGenerateReportSyncMutation,
  useGenerateStatisticsReportAsyncMutation,
  useGenerateSessionsReportAsyncMutation,
  useGenerateSessionIntervalsReportAsyncMutation,
  useGenerateNyserdaEnergyUseReportAsyncMutation,
  useGenerateNyserdaStatusReportAsyncMutation,
} = reportApi;
