/* eslint-disable react/display-name */
import _ from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CheckMark, ErrorWarningCircle, Info } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { NUMBER } from '../../constant/Number.constant';
import { ILLEGAL_KEYWORDS } from '../../constant/Text.constant';
import { useAuth } from '../../hooks';
import {
  useAddChargerMutation,
  useClaimChargerMutation,
  useClaimValidateMutation,
  useUpdateBulkChargersMutation,
} from '../../services/charger.api';
import { useFetchChargerStaticDataQuery } from '../../services/provision';
import { getWhiteLabellingCompanyName } from '../../stores/selectors/env.selector';
import {
  ChargerDetail,
  ChargerSpecificInfo,
  ClaimCharger,
  Connectivity,
} from '../../stores/types/chargers.interface';
import { InternalErrorCode } from '../../stores/types/error.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  CheckBox,
  CheckBoxData,
  ColorType,
  Dropdown,
  FormInput,
  GroupDirection,
  Icon,
  Label,
  LabelType,
  RadioGroup,
  Snackbar,
  useGlobalModalContext,
} from '../_ui';
import { Form } from '../_ui/Form.component';
import { InputType, ValidationType } from '../_ui/Input.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import { AlertPosition, AlertType } from '../_ui/snack-bar/Snack-Bar.component';
import { OCPP_ID_LENGTH, SERIAL_NUMBER_LENGTH } from './utils';

export const ChargerSpecificDetailEditor = memo((props: any) => {
  const { wizardState } = props;
  const { chargerSharedDetails } = wizardState;
  const SIM_CARD_NUMBER_LEN = 22;
  const { t } = useTranslation();
  const auth = useAuth();
  const { hideModal } = useGlobalModalContext();
  const whiteLabelCompanyName: any = useSelector(getWhiteLabellingCompanyName);
  const [triggerAddCharger, addChargerResponse] = useAddChargerMutation();

  const { networkProvider } = useFetchChargerStaticDataQuery(undefined, {
    selectFromResult: (endpoint) => ({
      networkProvider: endpoint.data ? endpoint.data.simCardProviders : [],
    }),
  });

  const [_chargerSpecificArray, setChargerSpecificArray] = useState<
    ChargerSpecificInfo[]
  >([] as ChargerSpecificInfo[]);
  const [_chargerSpecificDetail, setChargerSpecificDetail] =
    useState<ChargerDetail>({} as ChargerDetail);

  const [totalCharger, setTotalCharger] = useState(1);
  const [currentCharger, setCurrentCharger] = useState(1);
  const [ocppFlag, setOcppFlag] = useState<Array<boolean>>([]);
  const [claim, setClaim] = useState(false);
  const [triggerValidate, validateResponse] = useClaimValidateMutation();
  const [triggerClaimChargerApi, claimChargerResponse] =
    useClaimChargerMutation();
  const [updateBulkChargers, updateChargerResponse] =
    useUpdateBulkChargersMutation();

  // Constants
  const companyOwnerType = 'company';
  enum ValidationFields {
    SERIAL_NUM = 'serialNumber',
    DISPLAY_NAME = 'displayName',
  }

  enum ValidationError {
    SERIAL_NUM_ERROR = 'This serial number is already in use',
    DISPLAY_ID_ERROR = 'This display id is already in use',
    VERIFY_ERROR = 'Please verify the provided Serial number and Display ID',
  }

  const noOfChargerDropdownData = [...new Array(20)].map((each, index) => ({
    label: (index + 1).toString(),
    id: index + 1,
    selected: index + 1 === totalCharger,
  }));

  const networkProviderDropdown = useMemo(() => {
    return networkProvider.map((network: any, index: number) => ({
      id: index,
      label: network,
      selected: false,
    }));
  }, [networkProvider]);

  useEffect(() => {
    if (
      _.isEmpty(_chargerSpecificDetail) &&
      chargerSharedDetails?.sharedConnectionType === false
    ) {
      setChargerSpecificDetail({
        ..._chargerSpecificDetail,
        whitelabeledSimCard: true,
      });
    }
  }, [_chargerSpecificDetail, chargerSharedDetails?.sharedConnectionType]);

  // Counts successful objects in response
  const countSuccess = (response: any, bulkOperation: boolean = false) => {
    return bulkOperation
      ? response.isSuccess
        ? response.data.filter((item: any) => item.error === false).length
        : response.error.data.filter((item: any) => item.error === false).length
      : response.isSuccess
      ? 1
      : 0;
  };

  // Counts error in the response
  const countError = (response: any, bulkOperation: boolean = false) => {
    return response.isError
      ? bulkOperation
        ? response?.error?.data.filter((item: any) => item.error === true)
            .length
        : 1
      : 0;
  };

  const displayToast = (successCount: number, errorCount: number) => {
    Snackbar.show({
      message: (
        <span className='text-sm text-black font-medium'>
          <Trans
            i18nKey={
              errorCount === 0
                ? 'chargers_add_success_toast'
                : 'charger_add_failed_toast'
            }
            values={{
              chargerNumber: successCount + errorCount,
              chargerLocation: chargerSharedDetails.locationName,
              failedEntityCount: errorCount,
            }}
            components={{
              bold: <strong />,
            }}
          />
        </span>
      ),
      position: AlertPosition.BOTTOM_LEFT,
      alertType: AlertType.DEFAULT,
      duration: 5000,
      messageLabelType: LabelType.LABEL_S_MEDIUM,
      messageLabelColor: ColorType.BLACK,
      icon: errorCount === 0 ? CheckMark : ErrorWarningCircle,
      iconColor: errorCount === 0 ? ColorType.POSITIVE : undefined,
    });
  };

  // Counts the successful & unsuccessful addition of charger
  useEffect(() => {
    let successCount = 0;
    let errorCount = 0;

    // Case: Only provision chargers & no claim chargers
    if (
      claimChargerResponse.isUninitialized &&
      (addChargerResponse.isSuccess || addChargerResponse.isError)
    ) {
      successCount += countSuccess(
        addChargerResponse,
        addChargerResponse.originalArgs?.bulkOperation,
      );
      errorCount += countError(
        addChargerResponse,
        addChargerResponse.originalArgs?.bulkOperation,
      );
    } else if (
      // Case: No provision chargers & only claim chargers
      addChargerResponse.isUninitialized &&
      (updateChargerResponse.isSuccess || updateChargerResponse.isError)
    ) {
      successCount += countSuccess(
        updateChargerResponse,
        updateChargerResponse.originalArgs?.bulkOperation,
      );
      errorCount += countError(
        updateChargerResponse,
        updateChargerResponse.originalArgs?.bulkOperation,
      );
    } else if (
      // Case: Provision chargers as well as claim chargers
      // we need to wait till PATCH /chargerdetails API is finished.
      (addChargerResponse.isSuccess || addChargerResponse.isError) &&
      (updateChargerResponse.isSuccess || updateChargerResponse.isError)
    ) {
      successCount += countSuccess(
        addChargerResponse,
        addChargerResponse.originalArgs?.bulkOperation,
      );
      successCount += countSuccess(
        updateChargerResponse,
        updateChargerResponse.originalArgs?.bulkOperation,
      );
      errorCount += countError(
        addChargerResponse,
        addChargerResponse.originalArgs?.bulkOperation,
      );
      errorCount += countError(
        updateChargerResponse,
        updateChargerResponse.originalArgs?.bulkOperation,
      );
    }
    if (successCount > 0 || errorCount > 0) {
      displayToast(successCount, errorCount);
    }
  }, [
    addChargerResponse.isSuccess,
    addChargerResponse.isError,
    updateChargerResponse.isSuccess,
    updateChargerResponse.isError,
  ]);

  // Helper method to create payload for updateBulkCharger API
  const createUpdateBulkChargerPayload = (data: any) => {
    const updateBulkChargerPayload = data.map((response: any) => {
      const charger = _chargerSpecificArray?.find(
        (item) =>
          item.isClaim === true &&
          item.chargerSpecificDetail.displayId === response.displayId,
      );
      return {
        deviceId: response.deviceId,
        priceModelId: chargerSharedDetails.priceModelId,
        connectivity: chargerSharedDetails.sharedConnectionType
          ? chargerSharedDetails.connectivity
          : charger?.chargerSpecificDetail?.connectivity,
        whitelabeledSimCard: chargerSharedDetails.sharedConnectionType
          ? chargerSharedDetails?.whitelabeledSimCard
          : charger?.chargerSpecificDetail.whitelabeledSimCard,
        simCardProvider: chargerSharedDetails.sharedConnectionType
          ? chargerSharedDetails?.simCardProvider
          : charger?.chargerSpecificDetail?.simCardProvider,
        wifiName: chargerSharedDetails.sharedConnectionType
          ? chargerSharedDetails?.wifiName
          : charger?.chargerSpecificDetail.wifiName,
        wifiPassword: chargerSharedDetails.sharedConnectionType
          ? chargerSharedDetails?.wifiPassword
          : charger?.chargerSpecificDetail.wifiPassword,
        simCardId: charger?.chargerSpecificDetail.simCardId,
        hiddenOnMap: chargerSharedDetails.hiddenOnMap,
        directions: chargerSharedDetails?.directions,
        internalNote: chargerSharedDetails?.internalNote,
        installer: chargerSharedDetails?.installer,
        installationDate: chargerSharedDetails?.installationDate,
        installerContactEmail: chargerSharedDetails?.installerContactEmail,
        parkingSpot: charger?.chargerSpecificDetail.parkingSpot,
        associatedBuildingUnit:
          charger?.chargerSpecificDetail.associatedBuildingUnit,
      };
    });
    return {
      bulkChargers: updateBulkChargerPayload,
      bulkOperation: updateBulkChargerPayload.length > 1,
      autoPopulate: true,
      hideToast: true,
    };
  };

  // Calls updateBulkCharger API( PATCH /chargerdetails) after claim  API(POST /provision/claim) is completed
  useEffect(() => {
    if (
      claimChargerResponse.status === 'fulfilled' ||
      claimChargerResponse.status === 'rejected'
    ) {
      if (claimChargerResponse.originalArgs?.bulkOperation) {
        const response: any =
          claimChargerResponse.status === 'fulfilled'
            ? claimChargerResponse
            : claimChargerResponse.error;
        const successCharger = response?.data?.filter(
          (item: any) => item.error === false,
        );
        updateBulkChargers(
          createUpdateBulkChargerPayload(
            successCharger.map((item: any) => {
              return { ...item.response };
            }),
          ),
        );
      } else if (
        claimChargerResponse.originalArgs?.bulkOperation === false &&
        claimChargerResponse.isSuccess
      ) {
        updateBulkChargers(
          createUpdateBulkChargerPayload([claimChargerResponse.data]),
        );
      }
    }
  }, [claimChargerResponse.status]);

  const handleChargerDropdownCount = (item: any, index: number) => {
    setTotalCharger(item.id);
  };

  const renderChargerDropdown = () => {
    if (currentCharger === 1) {
      return (
        <div>
          <Label
            text='&nbsp;'
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <div className='flex flex-col gap-2'>
            <Label
              text={t('chargers_number_of_chargers')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
            <Dropdown
              headerWidth='78px'
              items={noOfChargerDropdownData}
              onItemClick={handleChargerDropdownCount}
              labelType={LabelType.BODY3_G4}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
            />
          </div>
        </div>
      );
    }
  };

  const handleButtonLabel = () => {
    if (currentCharger === totalCharger) {
      return t('chargers_add_save');
    }
    return t('chargers_add_continue_next_charger');
  };

  const handleInputChange = (key: string, value: any) => {
    setChargerSpecificDetail({ ..._chargerSpecificDetail, [key]: value });
  };

  const handleValidateDisplayId = (value: any) => {
    const regex = /^([a-zA-Z0-9*-_|@])+$/;
    return (
      regex.test(value) &&
      ILLEGAL_KEYWORDS.indexOf(value?.toLowerCase()) === NUMBER.MINUS_ONE
    );
  };

  const handleValidateId = (value: any) => {
    const regex = /^([a-zA-Z0-9-*_=+.|@])+$/;
    return (
      regex.test(value) &&
      ILLEGAL_KEYWORDS.indexOf(value?.toLowerCase()) === NUMBER.MINUS_ONE
    );
  };

  const validateSerialNum = () => {
    if (validateResponse.isError) {
      const errorObj: any = validateResponse?.error;
      return (
        errorObj?.data?.code === InternalErrorCode.ERR_CS_0041 &&
        (errorObj?.data?.errors[0]?.field === ValidationFields.SERIAL_NUM ||
          errorObj?.data?.message === ValidationError.VERIFY_ERROR)
      );
    }
    return false;
  };

  const validateDisplayId = () => {
    if (validateResponse.isError) {
      const errorObj: any = validateResponse?.error;
      return (
        !handleValidateDisplayId(_chargerSpecificDetail.displayId) ||
        (errorObj?.data?.code === InternalErrorCode.ERR_CS_0041 &&
          (errorObj?.data?.errors[0]?.field === ValidationFields.DISPLAY_NAME ||
            errorObj?.data?.message === ValidationError.VERIFY_ERROR))
      );
    }
    return false;
  };

  const onButtonValidation = () => {
    if (
      !_chargerSpecificDetail.ocppId ||
      !_chargerSpecificDetail.displayId ||
      !_chargerSpecificDetail.serialNumber
    ) {
      return false;
    }
    if (!handleValidateId(_chargerSpecificDetail.ocppId)) {
      return false;
    }
    if (!handleValidateDisplayId(_chargerSpecificDetail.displayId)) {
      return false;
    }
    if (validateSerialNum()) {
      return false;
    }
    if (validateDisplayId()) {
      return false;
    }
    if (!chargerSharedDetails.sharedConnectionType) {
      if (!_chargerSpecificDetail.connectivity) {
        return false;
      }
    }
    return true;
  };

  const handleConnectionType = (items: CheckBoxData[]) => {
    const selectedConnection = items.find((item) => item.selected === true);
    if (selectedConnection?.id !== Connectivity.SIM_CARD) {
      const { whitelabeledSimCard, ...updateChargerSpecificDetail } =
        _chargerSpecificDetail;
      setChargerSpecificDetail({
        ...updateChargerSpecificDetail,
        connectivity: selectedConnection?.id,
      });
    } else {
      handleInputChange('connectivity', selectedConnection?.id);
    }
  };

  const renderDivForSimCardNumber = () => {
    if (
      chargerSharedDetails?.connectivity === Connectivity.SIM_CARD ||
      _chargerSpecificDetail.connectivity === Connectivity.SIM_CARD
    ) {
      return (
        <div className='flex flex-col gap-y-2'>
          <FormInput
            inputId='chargers_sim_card_number'
            defaultValue={_chargerSpecificDetail.simCardId}
            label={t('chargers_sim_card_number')}
            secondLabel={`(${t('chargers_optional')})`}
            secondLabelType={LabelType.LABEL_M_MEDIUM}
            secondLabelColor={ColorType.GREY6}
            placeholder={t('chargers_sim_card_number')}
            inputType={InputType.NUMBER}
            props={{ style: { width: '500px' } }}
            onChange={(event: any) =>
              handleInputChange('simCardId', event.target.value)
            }
            showErrorIcon
            maxLen={String(SIM_CARD_NUMBER_LEN)}
          />
        </div>
      );
    }
  };

  const renderDivForConnectionType = () => {
    if (!chargerSharedDetails?.sharedConnectionType) {
      const connectionTypeData = () => {
        return [
          {
            id: Connectivity.SIM_CARD,
            label: t('chargers_sim_card'),
            selected:
              _chargerSpecificDetail.connectivity === Connectivity.SIM_CARD,
            disabled: addChargerResponse.isLoading,
          },
          {
            id: Connectivity.WIFI,
            label: t('chargers_wifi'),
            selected: _chargerSpecificDetail.connectivity === Connectivity.WIFI,
            disabled: addChargerResponse.isLoading,
          },
          {
            id: Connectivity.ETHERNET,
            label: t('chargers_ethernet'),
            selected:
              _chargerSpecificDetail.connectivity === Connectivity.ETHERNET,
            disabled: addChargerResponse.isLoading,
          },
        ];
      };

      return (
        <div className='flex flex-col gap-y-2'>
          <Label
            text={t('chargers_connection_type')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <RadioGroup
            direction={GroupDirection.Horizontal}
            defaultItems={connectionTypeData()}
            onChange={handleConnectionType}
          />
        </div>
      );
    }
    return null;
  };

  const renderDivForSIMConnection = () => {
    if (
      !chargerSharedDetails?.sharedConnectionType &&
      _chargerSpecificDetail.connectivity === Connectivity.SIM_CARD
    ) {
      const handleSimCardProvidedBy = (updatedItems: CheckBoxData[]) => {
        const selectedOption = updatedItems.find(
          (item) => item.selected === true,
        );
        handleInputChange('whitelabeledSimCard', selectedOption?.id === 'YES');
      };

      const simCardProvidedByData = () => {
        return [
          {
            id: 'YES',
            label: t('chargers_yes'),
            selected: _chargerSpecificDetail?.whitelabeledSimCard,
            disabled: addChargerResponse.isLoading,
          },
          {
            id: 'NO',
            label: t('chargers_no'),
            selected: !_chargerSpecificDetail?.whitelabeledSimCard,
            disabled: addChargerResponse.isLoading,
          },
        ];
      };

      return (
        <div className='flex flex-col gap-y-2'>
          <div className='text-base font-semibold font-inter text-black'>
            <Trans
              i18nKey='chargers_sim_card_provided_question'
              values={{ companyName: whiteLabelCompanyName }}
            />
          </div>
          <RadioGroup
            direction={GroupDirection.Horizontal}
            defaultItems={simCardProvidedByData()}
            onChange={handleSimCardProvidedBy}
          />
          <div className='flex flex-col gap-y-2'>
            <div className='flex flex-row gap-1'>
              <Label
                text={t('chargers_network_provider')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
              <Label
                text={`(${t('chargers_optional')})`}
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.GREY6}
              />
            </div>
            <Dropdown
              items={networkProviderDropdown}
              onItemClick={(selectedItem: any, index: number) =>
                handleInputChange('simCardProvider', selectedItem.label)
              }
              headerWidth='500px'
              placeholder={t('chargers_network_provider')}
              placeholderLabelType={LabelType.DROPDOWN_HEADER}
              labelType={LabelType.BODY3_G4}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              contentDivWidth={500}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
            />
          </div>
        </div>
      );
    }
  };

  const renderDivForWifiConnection = () => {
    if (
      !chargerSharedDetails?.sharedConnectionType &&
      _chargerSpecificDetail.connectivity === Connectivity.WIFI
    ) {
      return (
        <div className='flex flex-row gap-4'>
          <div className='flex flex-col gap-y-2'>
            <FormInput
              inputId='chargers_wifi_network_name'
              label={t('chargers_wifi_network_name')}
              secondLabel={`(${t('chargers_optional')})`}
              secondLabelType={LabelType.LABEL_M_MEDIUM}
              secondLabelColor={ColorType.GREY6}
              defaultValue={chargerSharedDetails?.wifiName}
              placeholder={t('chargers_wifi_network_name_placeholder')}
              inputType={InputType.TEXT}
              onChange={(event: any) =>
                handleInputChange('wifiName', event.target.value)
              }
            />
          </div>
          <div className='flex flex-col gap-y-2'>
            <FormInput
              inputId='chargers_wifi_network_password'
              label={t('chargers_wifi_network_password')}
              secondLabel={`(${t('chargers_optional')})`}
              secondLabelType={LabelType.LABEL_M_MEDIUM}
              secondLabelColor={ColorType.GREY6}
              defaultValue={chargerSharedDetails?.wifiPassword}
              placeholder={t('chargers_wifi_network_password_placeholder')}
              inputType={InputType.TEXT}
              onChange={(event: any) =>
                handleInputChange('wifiPassword', event.target.value)
              }
            />
          </div>
        </div>
      );
    }
  };

  const handlePrevButtonClick = () => {
    const prevStep = currentCharger - 1;
    if (prevStep >= 1) {
      setCurrentCharger(prevStep);
      const prevDetails = _chargerSpecificArray[currentCharger - 2];
      setChargerSpecificDetail(
        prevDetails?.chargerSpecificDetail || ({} as ChargerDetail),
      );
      setClaim(prevDetails.isClaim);
    }
  };

  const handleChangeCharger = () => {
    const nextCharger = _chargerSpecificArray?.[currentCharger];
    const currentCharge = _chargerSpecificArray?.[currentCharger - 1];

    if (!nextCharger) {
      const newFlag = [...ocppFlag];
      newFlag[currentCharger + 1] = false;
      setOcppFlag(newFlag);
    }

    setChargerSpecificDetail(
      nextCharger?.chargerSpecificDetail || ({} as ChargerDetail),
    );
    setClaim(nextCharger?.isClaim || false);

    if (currentCharge) {
      const updatedChargers = [..._chargerSpecificArray];
      updatedChargers[currentCharger - 1].chargerSpecificDetail =
        _chargerSpecificDetail;
      updatedChargers[currentCharger - 1].isClaim = claim;
      setChargerSpecificArray(updatedChargers);
    } else {
      setChargerSpecificArray([
        ..._chargerSpecificArray,
        {
          isClaim: claim,
          chargerSpecificDetail: _chargerSpecificDetail,
        },
      ]);
    }
  };

  // Filters the claimable chargers & create the payload for claim charger request
  const createClaimChargerPayload = (
    chargerSpecificArray: ChargerSpecificInfo[],
  ) => {
    const filteredChargers = chargerSpecificArray.filter(
      (item: ChargerSpecificInfo) => item.isClaim === true,
    );
    const claimCharger: ClaimCharger[] = filteredChargers.map(
      (item: ChargerSpecificInfo) => ({
        ownerType: companyOwnerType,
        companyId: auth.user.attributes.profile?.lastActiveCompanyId,
        locationId: chargerSharedDetails.locationId,
        serialNumber: item.chargerSpecificDetail.serialNumber || '',
        displayId: item.chargerSpecificDetail.displayId,
      }),
    );
    return {
      bulkOperation: claimCharger.length > 1,
      chargers: claimCharger,
    };
  };

  // Filters the non-claimable chargers & create the payload for post charger request
  const createPostChargerPayload = (
    chargerSpecificArray: ChargerSpecificInfo[],
  ) => {
    const filteredChargers = chargerSpecificArray.filter(
      (item: ChargerSpecificInfo) => item.isClaim === false,
    );

    const chargerDetail: ChargerDetail[] = filteredChargers.map(
      (item: ChargerSpecificInfo) => item.chargerSpecificDetail,
    );

    return {
      bulkOperation: chargerDetail.length > 1,
      chargerSharedDetails,
      chargerSpecificDetails: chargerDetail,
    };
  };

  const handleNextButtonClick = () => {
    if (currentCharger === totalCharger) {
      const updatedChargerSpecificArray = [
        ..._chargerSpecificArray,
        {
          isClaim: claim,
          chargerSpecificDetail: _chargerSpecificDetail,
        },
      ];
      setChargerSpecificArray(updatedChargerSpecificArray);
      const claimChargerPayload = createClaimChargerPayload(
        updatedChargerSpecificArray,
      );
      const postChargerPayload = createPostChargerPayload(
        updatedChargerSpecificArray,
      );

      if (claimChargerPayload.chargers.length > 0) {
        triggerClaimChargerApi(claimChargerPayload);
      }

      if (postChargerPayload.chargerSpecificDetails.length > 0) {
        triggerAddCharger(postChargerPayload);
      }
    } else {
      handleChangeCharger();
      setCurrentCharger(currentCharger + 1);
    }
  };

  // Creates request & triggers claim validate api(/provision/claim:validate)
  const handleTriggerValidationApi = (validationOn: string, value: string) => {
    if (value && validationOn) {
      let request: ClaimCharger = {
        ownerType: companyOwnerType,
        serialNumber: _chargerSpecificDetail.serialNumber || '',
        displayId: _chargerSpecificDetail.displayId || '',
        companyId: auth.user.attributes.profile?.lastActiveCompanyId,
        locationId: chargerSharedDetails.locationId,
      };
      if (validationOn === ValidationFields.SERIAL_NUM) {
        request = { ...request, serialNumber: value };
      }
      if (validationOn === ValidationFields.DISPLAY_NAME) {
        request = { ...request, displayId: value };
      }
      triggerValidate(request);
    }
  };

  // Sets claim to true when validate api responds with success
  // validate api returns success only when serialNum and/or DisplayId is claimable
  useEffect(() => {
    if (validateResponse) {
      if (validateResponse.isSuccess) {
        setClaim(true);
      } else {
        setClaim(false);
      }
    }
  }, [validateResponse]);

  const renderSerialNumErrorLabel = () => {
    if (_chargerSpecificDetail.serialNumber && validateResponse.isError) {
      const errorObj: any = validateResponse?.error;
      if (errorObj?.data?.code === InternalErrorCode.ERR_CS_0041) {
        if (errorObj?.data?.message === ValidationError.SERIAL_NUM_ERROR) {
          return t('claim_charger_serial_number_in_use_error');
        }
        if (errorObj?.data?.message === ValidationError.VERIFY_ERROR) {
          return t('claim_charger_verify_error');
        }
      }
    }
  };

  const renderDisplayIdErrorLabel = () => {
    if (_chargerSpecificDetail.displayId && validateResponse.isError) {
      const errorObj: any = validateResponse?.error;
      if (
        errorObj?.data?.code === InternalErrorCode.ERR_CS_0041 &&
        errorObj?.data?.message === ValidationError.DISPLAY_ID_ERROR
      ) {
        return t('claim_charger_display_id_in_use_error');
      }
    }
  };

  const handleSameOcppId = (isCopy: boolean) => {
    const ocppId = isCopy ? _chargerSpecificDetail.serialNumber : '';
    const newFlag = [...ocppFlag];
    newFlag[currentCharger] = isCopy;
    setOcppFlag(newFlag);
    handleInputChange('ocppId', ocppId);
  };

  return (
    <Form
      onSubmit={handleNextButtonClick}
      queryResult={
        claimChargerResponse.isUninitialized
          ? addChargerResponse
          : [claimChargerResponse, updateChargerResponse]
      }
      onQuerySuccess={() => {
        hideModal();
      }}
      onQueryFailed={() => {
        hideModal();
      }}
    >
      <div className='divide-y divide-grey2 h-full'>
        <div className='pb-4'>
          <Label
            text={t('chargers_add_step_2_description')}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
          {renderChargerDropdown()}
        </div>
        <div className='pt-4'>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-row gap-1'>
              <div className='text-lg text-grey6 font-inter font-semibold'>
                <Trans
                  i18nKey='chargers_specific_detail_title_1'
                  values={{
                    currentCharger: currentCharger || 1,
                  }}
                />
              </div>
              <div className='text-lg text-grey4 font-inter font-semibold'>
                <Trans
                  i18nKey='chargers_specific_detail_title_2'
                  values={{
                    totalCharger: totalCharger || 1,
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col gap-y-2'>
              <div className='flex flex-row gap-1 items-center'>
                <Label
                  text={t('chargers_serial_number')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                  labelHtmlFor='chargers_serial_number'
                />
                <Tooltip>
                  <TooltipTrigger>
                    <Icon src={Info} size={IconSize.SIZE_24x24} />
                  </TooltipTrigger>
                  <TooltipContent>
                    <Label
                      text={t('chargers_serial_number_tip')}
                      type={LabelType.BODY3}
                      color={ColorType.WHITE}
                    />
                  </TooltipContent>
                </Tooltip>
              </div>
              <div>
                <FormInput
                  inputId='chargers_serial_number'
                  defaultValue={_chargerSpecificDetail.serialNumber}
                  inputType={InputType.TEXT}
                  placeholder={t('chargers_serial_number')}
                  props={{ style: { width: '500px' } }}
                  onChange={(event: any) => {
                    handleTriggerValidationApi(
                      ValidationFields.SERIAL_NUM,
                      event.target.value,
                    );
                    if (ocppFlag[currentCharger]) {
                      setChargerSpecificDetail({
                        ..._chargerSpecificDetail,
                        serialNumber: event.target.value,
                        ocppId: event.target.value,
                      });
                    } else {
                      handleInputChange('serialNumber', event.target.value);
                    }
                  }}
                  validaitonQueryResult={validateSerialNum()}
                  errorLabel={renderSerialNumErrorLabel()}
                  showErrorIcon
                  showOutline
                  maxLen={String(SERIAL_NUMBER_LENGTH)}
                  dataTestId='add-chargers-serial-number'
                />
              </div>
            </div>
            <div className='flex flex-row gap-4'>
              <div className='flex flex-col gap-y-2 flex-1'>
                <div className='flex flex-row gap-1 items-center'>
                  <Label
                    text={t('chargers_ocpp_id')}
                    type={LabelType.LABEL_M}
                    color={ColorType.BLACK}
                    labelHtmlFor='chargers_ocpp_id'
                  />
                  <Tooltip>
                    <TooltipTrigger>
                      <Icon src={Info} size={IconSize.SIZE_24x24} />
                    </TooltipTrigger>
                    <TooltipContent>
                      <Label
                        text={t('chargers_ocpp_id_tip')}
                        type={LabelType.BODY3}
                        color={ColorType.WHITE}
                      />
                    </TooltipContent>
                  </Tooltip>
                </div>
                <div>
                  <CheckBox
                    selected={ocppFlag[currentCharger]}
                    label={t('same_as_serial_number')}
                    onChange={handleSameOcppId}
                  />
                </div>
                {!ocppFlag[currentCharger] && (
                  <div>
                    <FormInput
                      inputId='chargers_ocpp_id'
                      width='100%'
                      defaultValue={_chargerSpecificDetail.ocppId}
                      placeholder={t('chargers_ocpp_id')}
                      inputType={InputType.TEXT}
                      onChange={(event: any) => {
                        handleInputChange('ocppId', event.target.value);
                      }}
                      validationType={ValidationType.NOT_EMPTY}
                      onValidation={handleValidateId}
                      showErrorIcon
                      maxLen={String(OCPP_ID_LENGTH)}
                      dataTestId='add-chargers-ocpp-id'
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='flex flex-row gap-4'>
              <div className='flex flex-col gap-y-2 flex-1'>
                <div className='flex flex-row gap-1 items-center'>
                  <Label
                    text={t('chargers_display_id')}
                    type={LabelType.LABEL_M}
                    color={ColorType.BLACK}
                    labelHtmlFor='chargers_display_id'
                  />
                  <Tooltip>
                    <TooltipTrigger>
                      <Icon src={Info} size={IconSize.SIZE_24x24} />
                    </TooltipTrigger>
                    <TooltipContent>
                      <Label
                        text={t('chargers_display_id_tip')}
                        type={LabelType.BODY3}
                        color={ColorType.WHITE}
                      />
                    </TooltipContent>
                  </Tooltip>
                </div>
                <div>
                  <FormInput
                    inputId='chargers_display_id'
                    width='100%'
                    defaultValue={_chargerSpecificDetail.displayId}
                    placeholder={t('chargers_display_id')}
                    inputType={InputType.TEXT}
                    onChange={(event: any) => {
                      handleTriggerValidationApi(
                        ValidationFields.DISPLAY_NAME,
                        event.target.value,
                      );
                      handleInputChange('displayId', event.target.value);
                    }}
                    validaitonQueryResult={validateDisplayId()}
                    showOutline
                    showErrorIcon
                    errorLabel={renderDisplayIdErrorLabel()}
                    dataTestId='add-chargers-display-id'
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-row gap-4'>
              <div className='flex flex-col gap-y-2 flex-1'>
                <FormInput
                  inputId='chargers_unit_number'
                  width='100%'
                  defaultValue={_chargerSpecificDetail.associatedBuildingUnit}
                  label={t('chargers_unit_number')}
                  secondLabel={`(${t('chargers_optional')})`}
                  secondLabelType={LabelType.LABEL_M_MEDIUM}
                  secondLabelColor={ColorType.GREY6}
                  placeholder={t('chargers_unit_number')}
                  inputType={InputType.TEXT}
                  onChange={(event: any) =>
                    handleInputChange(
                      'associatedBuildingUnit',
                      event.target.value,
                    )
                  }
                />
              </div>
              <div className='flex flex-col gap-y-2 flex-1'>
                <FormInput
                  inputId='chargers_parking_space_number'
                  width='100%'
                  defaultValue={_chargerSpecificDetail.parkingSpot}
                  label={t('chargers_parking_space_number')}
                  secondLabel={`(${t('chargers_optional')})`}
                  secondLabelType={LabelType.LABEL_M_MEDIUM}
                  secondLabelColor={ColorType.GREY6}
                  placeholder={t('chargers_parking_space_number')}
                  inputType={InputType.TEXT}
                  onChange={(event: any) =>
                    handleInputChange('parkingSpot', event.target.value)
                  }
                />
              </div>
            </div>
            {renderDivForConnectionType()}
            {renderDivForSIMConnection()}
            {renderDivForWifiConnection()}
            {renderDivForSimCardNumber()}
            <div className='flex pt-[16px] pb-[20px] sticky bottom-0 bg-white'>
              <div className='w-[50%] mr-[8px]'>
                <Button
                  label={t('previous_charger')}
                  type={ButtonType.TERTIARY}
                  disabled={currentCharger === 1}
                  size={ButtonSize.SMALL_FULL}
                  onClick={handlePrevButtonClick}
                />
              </div>
              <div className='w-[50%] ml-[8px]'>
                <Button
                  label={handleButtonLabel()}
                  type={ButtonType.PRIMARY}
                  disabled={!onButtonValidation()}
                  size={ButtonSize.SMALL_FULL}
                  isSumbit
                  dataTestId='save-chargers'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
});
