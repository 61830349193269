import React from 'react';
import { ExitDeleteRemoveClose } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { Icon } from '../Icon.component';
import { Label, LabelType } from '../Label.component';
import { ColorType } from '../index';
import './Snack-Bar.css';

export enum AlertPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  BOTTOM_LEFT = 'bottom-left',
}

export enum AlertType {
  SUCCESS = 'bg-positive',
  INFO = 'bg-brand2',
  ERROR = 'bg-negative',
  DEFAULT = 'bg-white',
}
interface props {
  message?: any;
  duration?: number;
  position?: AlertPosition;
  alertType?: AlertType;
  height?: string;
  messageLabelType?: LabelType;
  messageLabelColor?: ColorType;
  icon?: string;
  iconColor?: string; // hex color
  component?: string;
}
export class Snackbar extends React.Component {
  static Instance: any;

  static close() {
    Snackbar.Instance.setState({
      open: false,
    });
  }

  static show({
    message,
    duration = 0,
    position = AlertPosition.BOTTOM,
    alertType = AlertType.SUCCESS,
    height = 'h-[61px]',
    messageLabelType = LabelType.DROPDOWN_HEADER_SELECTED,
    messageLabelColor = ColorType.WHITE,
    icon = '',
    iconColor,
    component,
  }: props) {
    if (Snackbar.Instance) {
      Snackbar.Instance.setState(
        {
          open: true,
          message,
          duration,
          position,
          alertType,
          height,
          always: duration === 0 ? 'always' : '',
          messageLabelType,
          messageLabelColor,
          icon,
          iconColor,
          component,
        },
        () => {
          if (duration !== 0) {
            setTimeout(() => {
              Snackbar.Instance.setState({ open: false });
            }, duration);
          }
        },
      );
    } else {
      console.warn('No Snackbar found');
    }
  }

  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
    };

    Snackbar.Instance = this;
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      open,
      message,
      position,
      alertType,
      always,
      messageLabelType,
      messageLabelColor,
      icon,
      iconColor,
      height,
      component,
    } = this.state as any;

    return (
      <div
        className={`flex w-[400px] ${height} justify-between rounded ${
          component ? 'items-start' : 'items-center'
        } z-50 p-5 ${
          open
            ? ['snackbar', `show-${position}`, alertType, always].join(' ')
            : 'snackbar'
        }`}
        data-testid='snackBar'
      >
        {icon && <Icon src={icon} color={iconColor} />}
        <div className={`flex overflow-hidden w-[324px]  ${height}`}>
          <Label
            text={message}
            type={messageLabelType}
            color={messageLabelColor}
            className='px-4 text-left text-ellipsis place-self-center truncate'
            style={{ textOverflow: 'unset', textWrap: 'balance' }}
          />
        </div>

        <div className='cursor-pointer'>
          <Icon
            src={ExitDeleteRemoveClose}
            size={IconSize.SIZE_20x20}
            onClick={this.handleClose}
            alt='icon_alt_close_toast_message'
            isTranslationOn
          />
        </div>
      </div>
    );
  }
}
