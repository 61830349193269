import { Company } from '../stores/types';
import {
  BankInfoProvided,
  sendBankInfoReminderPayload,
} from '../stores/types/company.interface';
import { API_KEYS, Api } from './base.api';
import { SCOPE_TYPE, providesListTag, sortCompanies } from './utils';

export const companyApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchCompanies: builder.query<Company[], SCOPE_TYPE>({
      query: (scope) => ({
        url: `/internal/core/v2/${API_KEYS.COMPANIES}`,
        params: { scope },
        method: 'GET',
        needCompanyId: false,
      }),
      providesTags: (result, error, arg) =>
        providesListTag(result, API_KEYS.COMPANIES),
      // Based on how BE is designed, if bankInfoProvided field is not present, FE should treat value of field as MISSING
      transformResponse: (response: Company[]) => {
        const companies = response.map((company) => ({
          ...company,
          bankInfoProvided:
            company?.bankInfoProvided || BankInfoProvided.MISSING,
        }));
        return sortCompanies(companies);
      },
    }),
    checkCompanyName: builder.query<Company[], string>({
      query: (name: string) => ({
        url: `/internal/core/v2/${API_KEYS.COMPANIES}?filter_eq[name]=${name}`,
        method: 'GET',
        needCompanyId: false,
      }),
    }),
    fetchCompanyDetail: builder.query<
      Company,
      { scope: SCOPE_TYPE; id: string }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.COMPANIES}/${payload.id}`,
        params: { scope: payload.scope },
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.COMPANIES, id: arg.id },
      ],
      // Based on how BE is designed, if bankInfoProvided field is not present, FE should treat value of field as MISSING
      transformResponse: (response: Company) => {
        return {
          ...response,
          bankInfoProvided:
            response?.bankInfoProvided || BankInfoProvided.MISSING,
        } as Company;
      },
    }),
    updateCompany: builder.mutation<
      Company,
      { company: any; scope: SCOPE_TYPE }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.COMPANIES}/${payload.company.id}`,
        body: payload.company,
        params: { scope: payload.scope },
        method: 'PATCH',
        toast: {
          successTrans: 'update_company_success_msg',
          failedTrans: 'update_company_error_msg',
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.COMPANIES, id: 'LIST' },
        { type: API_KEYS.COMPANIES, id: result?.id },
      ],
    }),
    addCompany: builder.mutation<any, Company>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.COMPANIES}`,
        params: { scope: SCOPE_TYPE.ALL },
        body: payload,
        method: 'POST',
        toast: {
          successTrans: 'company_add_success',
          values: {
            name: payload.name,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.COMPANIES, id: 'LIST' },
        { type: API_KEYS.COMPANIES, id: result?.id },
      ],
    }),
    sendBankInfoReminder: builder.mutation<sendBankInfoReminderPayload, any>({
      query: (payload: sendBankInfoReminderPayload) => ({
        url: `/internal/core/v2/${API_KEYS.COMPANIES}/${payload.companyId}/sendBankInfoReminder`,
        method: 'POST',
        needCompanyId: false,
        toast: {
          successTrans: 'banking_info_reminder_email_success',
          failedTrans: 'banking_info_reminder_email_error',
          values: {
            companyName: payload.companyName,
          },
        },
      }),
    }),
  }),
});

export const {
  useLazyCheckCompanyNameQuery,
  useFetchCompaniesQuery,
  useLazyFetchCompaniesQuery,
  useFetchCompanyDetailQuery,
  useUpdateCompanyMutation,
  useLazyFetchCompanyDetailQuery,
  useAddCompanyMutation,
  useSendBankInfoReminderMutation,
} = companyApi;
