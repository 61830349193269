import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ErrorWarningCircle, percent } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { NUMBER } from '../../../constant/Number.constant';
import { TEXT } from '../../../constant/Text.constant';
import { getWhiteLabellingCompanyName } from '../../../stores/selectors/env.selector';
import { DiscountFormType } from '../../../stores/types/discount.interface';
import { formatPercent } from '../../Vehicles/utils';
import {
  Button,
  ButtonSize,
  ButtonType,
  CheckBoxData,
  ColorType,
  Dropdown,
  DropdownType,
  FormInput,
  GroupDirection,
  Icon,
  Label,
  LabelType,
  RadioGroup,
  Segment,
  useGlobalModalContext,
} from '../../_ui';
import { Form } from '../../_ui/Form.component';
import { InputType, ValidationType } from '../../_ui/Input.component';
import { validateDomainName, validateEmail } from '../../_ui/modal/utils';

interface DiscountFormFieldsProp {
  discountDetails: DiscountFormType;
  handleSwitchChanges: any;
  discountType: string;
  checkIsFormValid: Function;
  emailDoesNotExist: boolean;
  handleChange: Function;
  discountOptions: CheckBoxData[];
  handleDiscountSelection: Function;
  setDiscountDetails: Function;
  showPreExistDiscountMessage: boolean;
  handleChargersChange: Function;
  chargerDropdownList: any;
  handleSaveClick: any;
  setIsValidDiscount?: Function;
  isValidDiscount?: boolean;
  isEditMode?: boolean;
  addDiscountQueryResult?: any;
  editDiscountQueryResult?: any;
}

export const DiscountFormFields = ({
  discountDetails,
  handleSwitchChanges,
  discountType,
  checkIsFormValid = () => {},
  emailDoesNotExist,
  handleChange = () => {},
  discountOptions,
  handleDiscountSelection = () => {},
  setDiscountDetails = () => {},
  showPreExistDiscountMessage,
  handleChargersChange = () => {},
  chargerDropdownList,
  setIsValidDiscount = () => {},
  isValidDiscount = true,
  handleSaveClick,
  isEditMode = false,
  editDiscountQueryResult,
  addDiscountQueryResult,
}: DiscountFormFieldsProp) => {
  const { t } = useTranslation();
  const { userGroupSelector, adjustmentPercentage } = discountDetails;
  const { INDIVIDUAL, CUSTOM, DOMAIN_NAME } = TEXT;
  const { hideModal } = useGlobalModalContext();
  const whiteLabelCompanyName: any = useSelector(getWhiteLabellingCompanyName);

  const message =
    userGroupSelector.type === INDIVIDUAL ? t('discount_driver') : t('company');

  const checkIsValidDiscount = (val: number) => {
    setIsValidDiscount(val > 0 && val <= 100);
  };

  const checkCompanyDomain = (event: any) => {
    if (event.target.value.includes('@')) {
      return;
    }
    handleChange(event);
  };

  return (
    <Form
      onSubmit={handleSaveClick}
      queryResult={
        isEditMode ? editDiscountQueryResult : addDiscountQueryResult
      }
      onQuerySuccess={() => hideModal()}
      onQueryFailed={() => hideModal()}
    >
      <div className='flex flex-col justify-between h-full'>
        <div className='divide-y divide-grey2'>
          <div className='flex flex-col pb-2'>
            <div className='flex w-full'>
              <div className='flex w-full'>
                <Segment
                  className='whitespace-nowrap w-full'
                  segmentedItemWidth='w-1/2'
                  options={[t('by_driver'), t('by_company')]}
                  segmentedDefault={
                    userGroupSelector.type === INDIVIDUAL
                      ? t('by_driver')
                      : t('by_company')
                  }
                  onChange={handleSwitchChanges}
                />
              </div>
            </div>
            {userGroupSelector.type === INDIVIDUAL ? (
              <div className='flex flex-col gap-2 mt-5' key={INDIVIDUAL}>
                <Label
                  text={t('discount_add_drivers_email')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                />
                <div className='flex flex-row gap-2'>
                  <FormInput
                    validationType={ValidationType.EMAIL}
                    placeholder={t('discount_add_drivers_email')}
                    defaultValue={userGroupSelector.value}
                    width='100%'
                    onChange={handleChange}
                    showErrorIcon={
                      emailDoesNotExist ||
                      !validateEmail(userGroupSelector.value)
                    }
                    validaitonQueryResult={emailDoesNotExist}
                    errorLabel={
                      !validateEmail(userGroupSelector.value) ||
                      emailDoesNotExist
                        ? t('discount_email_validation', {
                            company: whiteLabelCompanyName,
                          })
                        : ''
                    }
                    dataTestId='driverEmail'
                  />
                </div>
              </div>
            ) : (
              <div className='flex flex-col gap-2 mt-5' key={DOMAIN_NAME}>
                <Label
                  text={t('discount_add_companies_email')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                />
                <div className='flex flex-row gap-2'>
                  <div
                    className='flex font-medium'
                    style={{ fontSize: '14px', paddingTop: '10px' }}
                  >
                    @
                  </div>
                  <FormInput
                    validationType={ValidationType.DOMAIN_NAME}
                    placeholder='companydomain.com'
                    defaultValue={userGroupSelector.value}
                    onChange={(event: any) => checkCompanyDomain(event)}
                    inputType={InputType.DOMAIN}
                    width='100%'
                    showErrorIcon={!validateDomainName(userGroupSelector.value)}
                    validaitonQueryResult={
                      !validateDomainName(userGroupSelector.value)
                    }
                    errorLabel={
                      !validateDomainName(userGroupSelector.value)
                        ? t('discount_domain_validation_message')
                        : ''
                    }
                    dataTestId='companyDomain'
                  />
                </div>
              </div>
            )}
            <div className='flex flex-col mt-5 mb-2' key={CUSTOM}>
              <Label
                text={t('discount')}
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
              />
            </div>
            <RadioGroup
              direction={GroupDirection.Vertical}
              defaultItems={discountOptions}
              onChange={handleDiscountSelection}
              itemHeight='28px'
              padding='pr-2'
            />
            {discountType === CUSTOM && (
              <div className='flex flex-col'>
                <div className='flex'>
                  <FormInput
                    label=''
                    validationType={ValidationType.NUMBER}
                    showErrorIcon
                    placeholder='0.00'
                    onChange={(event: any) => {
                      setDiscountDetails({
                        ...discountDetails,
                        adjustmentPercentage: formatPercent(event.target.value),
                      });
                      checkIsValidDiscount(event.target.value);
                    }}
                    errorLabel=''
                    inputType={InputType.PERCENT}
                    defaultValue={adjustmentPercentage}
                    suffixIcon={percent}
                    sufficIconSize={IconSize.SIZE_14x14}
                    width='118px'
                    dataTestId='discountPrice'
                  />
                  <div className='flex pl-2'>
                    <Label
                      className='pt-2'
                      text={t('discount_off_total_session_cost')}
                      type={LabelType.BODY3}
                      color={ColorType.BLACK}
                    />
                  </div>
                </div>
                {!isValidDiscount && (
                  <Label
                    type={LabelType.LABEL_S}
                    className='pt-1 block'
                    color={ColorType.NEGATIVE}
                    text={t('discount_valid_amount')}
                  />
                )}
              </div>
            )}
            <Label
              className='mt-4 mb-2'
              text={t('pricing_chargers')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
            <Dropdown
              placeholder={t('pricing_chargers_holder')}
              placeholderLabelType={LabelType.DROPDOWN_HEADER}
              headerWidth='100%'
              items={chargerDropdownList}
              contentDivHeight={300}
              type={DropdownType.CHECKBOX_TREE}
              showPillHeader
              onItemClick={(items: any, pIndex: Number, cIndex: Number) =>
                handleChargersChange(items, pIndex, cIndex)
              }
              showFooter={chargerDropdownList?.length === NUMBER.ZERO}
              placement='top-start'
              dataTestId='selectChargers'
            />
          </div>
        </div>
        {showPreExistDiscountMessage && (
          <div className='flex flex-row items-center gap-2'>
            <Icon src={ErrorWarningCircle} />
            <div className='text-xs text-black font-normal leading-5'>
              <Trans
                i18nKey='pre_existing_discount_validation'
                values={{
                  user: message,
                }}
              />
            </div>
          </div>
        )}
        <div className='pb-[20px] pt-4 sticky bottom-0 bg-white'>
          <Button
            label={t('save')}
            type={ButtonType.PRIMARY}
            disabled={!checkIsFormValid()}
            size={ButtonSize.SMALL_FULL}
            isSumbit
            dataTestId='discountRuleSave'
          />
        </div>
      </div>
    </Form>
  );
};
