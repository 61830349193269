import { Session } from '../stores/types';
import { SESSION_TYPES } from '../stores/types/sessions.interface';

export const getStartedStatus = (session: Session) => {
  const { startedVia } = session;

  let status = startedVia;
  if (startedVia === 'PAYMENT_TERMINAL') {
    status = SESSION_TYPES.PAYMENT_TERMINAL;
  } else if (startedVia === 'SYSTEM') {
    status = SESSION_TYPES.DRIVER_APP;
  } else if (startedVia === 'AUTOSTART') {
    status = SESSION_TYPES.CHARGER_AUTO_START;
  }
  return status;
};
