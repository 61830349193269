import { memo, useMemo, useState } from 'react';

import { formatInTimeZone } from 'date-fns-tz';
import { Trans, useTranslation } from 'react-i18next';
import {
  AddPlus2,
  ChargerEV,
  ErrorWarningtraiangle,
  NoVehicles,
  RFIDCard,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { useCompany } from '../../hooks/useCompany';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { RoutePath } from '../../routes';
import { useFetchVehiclesQuery } from '../../services/vehicles.api';
import { Vehicle } from '../../stores/types/vehicles.interface';
import { getLocale } from '../../utils/Date.Util';
import { displayEneryKwh } from '../../utils/Number.Util';
import {
  Card,
  ColorType,
  Grid,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  Pill,
  Skeleton,
  useGlobalModalContext,
} from '../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import { GridColumnType } from '../_ui/grid/enums/Grid-Column-Type.enum';
import { VehicleEditor } from './VehicleEditor.component';

const ROW_PER_PAGE = 10;
export const Vehicles = memo(() => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLocalTimezone, setIsLocalTimezone] = useState(true);
  const { showModal } = useGlobalModalContext();
  const { navigateWithCompanyId } = useNavigateWithSearchParam();
  const { data, isVehicleLoading } = useFetchVehiclesQuery(undefined, {
    pollingInterval: 15000,
    selectFromResult: (endPoint) => ({
      data: endPoint.data ? endPoint.data : [],
      isVehicleLoading: endPoint.isFetching,
    }),
  });
  const { companyId: companyIdSearchParam } = useCompany();

  const vehicles = useMemo(() => {
    if (!data) return [];
    return [...data].sort((a, b) =>
      a.vehicleName.toLowerCase() < b.vehicleName.toLowerCase() ? -1 : 1,
    );
  }, [data]);

  const renderNoVehicles = () => {
    if (!isVehicleLoading && (!vehicles || vehicles.length === 0)) {
      return (
        <div className='flex flex-col items-center gap-2 mt-[16px] mb-[28px] '>
          <Icon src={NoVehicles} size={IconSize.SIZE_48x48} />
          <Label
            text={t('vehicles_no_vehicles_add')}
            type={LabelType.H4}
            color={ColorType.GREY5}
          />
          <Label
            text={t('vehicles_no_vehicles_message')}
            type={LabelType.BODY2}
            color={ColorType.GREY5}
          />
        </div>
      );
    }

    return null;
  };

  const handleAddVehicle = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('vehicles_add_vehicle'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => <VehicleEditor vehicles={vehicles} />,
      shouldCloseOnOverlayClick: false,
    });
  };

  const getColumnTitle = () => {
    return [
      {
        key: 'name_unit_id',
        title: t('vehicles_name_unit_id'),
        component: (row: Vehicle) => (
          <div className='flex flex-col justify-start max-w-[198px]'>
            {isVehicleLoading && <Skeleton height='h-5' />}
            {!isVehicleLoading && (
              <div>
                <div className='h-[20px]'>
                  <Label
                    text={row.vehicleName}
                    type={LabelType.BODY3}
                    color={ColorType.BLACK}
                  />
                </div>
                <div className='h-[20px]'>
                  <Label
                    text={row.vin}
                    type={LabelType.BODY3}
                    color={ColorType.GREY5}
                  />
                </div>
              </div>
            )}
          </div>
        ),
      },
      {
        key: 'make_model',
        title: t('vehicles_make_model'),
        component: (row: Vehicle) => (
          <div className='flex flex-col justify-start max-w-[198px]'>
            {isVehicleLoading && <Skeleton height='h-5' />}
            {!isVehicleLoading && (
              <div>
                <div className='h-[20px]'>
                  <Label
                    text={row.make}
                    type={LabelType.BODY3}
                    color={ColorType.BLACK}
                  />
                </div>
                <div className='h-[20px]'>
                  <Label
                    text={row.model}
                    type={LabelType.BODY3}
                    color={ColorType.GREY5}
                  />
                </div>
              </div>
            )}
          </div>
        ),
      },
      {
        key: 'chargerInUse',
        title: t('vehicles_charger_in_use'),
        component: (row: Vehicle) => {
          if (isVehicleLoading) {
            return <Skeleton height='h-5' />;
          }

          if (row.chargerInUse) {
            return (
              <div className='flex flex-row max-w-[140px]'>
                <Pill
                  label={row.chargerInUse}
                  bgColor={ColorType.GREY2}
                  iconLeft={ChargerEV}
                />
              </div>
            );
          }
          return (
            <div className='max-w-[140px]'>
              <Label
                text='&mdash;'
                type={LabelType.LABEL_XS_MEDIUM}
                color={ColorType.GREY4}
                className='items-center'
              />
            </div>
          );
        },
      },
      {
        key: 'energyUsedKwh',
        title: t('vehicles_last_energy_used'),
        component: (row: Vehicle) => {
          if (isVehicleLoading) {
            return <Skeleton height='h-5' />;
          }
          if (row.energyUsedKwh !== undefined) {
            return (
              <div className='max-w-[148px]'>
                <Label
                  text={displayEneryKwh(row.energyUsedKwh, t('kwh'))}
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                />
              </div>
            );
          }
          return (
            <div className='max-w-[148px]'>
              <Label
                text='&mdash;'
                type={LabelType.LABEL_XS_MEDIUM}
                color={ColorType.GREY4}
                className='items-center'
              />
            </div>
          );
        },
      },
      {
        key: 'lastPluggedInTimestampMillis',
        title: t('vehicles_last_plugged_in'),
        type: GridColumnType.TIMEZONE,
        component: (row: Vehicle) => {
          if (isVehicleLoading) {
            return <Skeleton height='h-5' />;
          }
          if (row.lastPluggedInTimestampMillis) {
            return (
              <div className='max-w-[198px]'>
                <Label
                  text={`${formatInTimeZone(
                    new Date(row.lastPluggedInTimestampMillis),
                    isLocalTimezone
                      ? Intl.DateTimeFormat().resolvedOptions().timeZone
                      : '',
                    'LLL d, h:mm a',
                    getLocale(),
                  )}`}
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                />
              </div>
            );
          }
          return (
            <div className='max-w-[198px]'>
              <Label
                text='&mdash;'
                type={LabelType.LABEL_XS_MEDIUM}
                color={ColorType.GREY4}
                className='items-center'
              />
            </div>
          );
        },
      },
      {
        key: 'rfids',
        title: t('vehicles_rfids'),
        component: (row: Vehicle) => (
          <div>
            {isVehicleLoading && <Skeleton height='h-5' />}
            {!isVehicleLoading && (
              <div className='flex flex-col gap-[4px] justify-start max-w-[198px]'>
                {row.rfids?.length > 0 ? (
                  row.rfids.map((rfid) => {
                    return (
                      <Pill
                        iconLeft={RFIDCard}
                        key={rfid.cardNumber}
                        label={rfid.cardNumber}
                        iconLeftAlt={t('icon_alt_rfid_card')}
                      />
                    );
                  })
                ) : (
                  <div className='flex flex-row items-center gap-[6px]'>
                    <Tooltip placement='top'>
                      <TooltipTrigger>
                        <Icon
                          src={ErrorWarningtraiangle}
                          color={ColorType.WARNING}
                          size={IconSize.SIZE_20x20}
                        />
                      </TooltipTrigger>
                      <TooltipContent>
                        <Label
                          text={t('vehicles_no_rfid_tip')}
                          type={LabelType.BODY3}
                          color={ColorType.WHITE}
                        />
                      </TooltipContent>
                    </Tooltip>
                    <Label
                      text={t('vehicles_no_rfid_assigned')}
                      type={LabelType.LABEL_S_MEDIUM}
                      color={ColorType.GREY6}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ),
      },
    ];
  };

  const handleRowClick = (rowData: any) => {
    navigateWithCompanyId(
      `../${RoutePath.VEHICLES}/${rowData.vehicleId}`,
      companyIdSearchParam,
    );
  };

  const handleToggleChange = (value: boolean) => {
    setIsLocalTimezone(value);
  };

  const renderVehicles = useMemo(() => {
    if (!isVehicleLoading && (!vehicles || vehicles.length === 0)) {
      return null;
    }

    return (
      <div className='mt-[16px]'>
        <Grid
          onRowClick={handleRowClick}
          pageIndex={currentPage}
          loadPage={(page: number) => setCurrentPage(page)}
          columns={getColumnTitle()}
          data={vehicles}
          primaryKey='vehicleId'
          totalPage={Math.ceil((vehicles.length || 0) / ROW_PER_PAGE)}
          local
          rowCount={ROW_PER_PAGE}
          isLoading={isVehicleLoading}
          dataTestId='vehicles'
          setIsLocalTimezone={handleToggleChange}
          isLocalTimezone={isLocalTimezone}
          switchClass='w-5 h-2.5'
          switchBtnClass='w-2 h-2 top-px left-px'
          translateClass='translate-x-2.5'
        />
      </div>
    );
  }, [currentPage, vehicles, isLocalTimezone]);

  const renderCardTitle = () => {
    return (
      <div className='flex flex-row items-baseline gap-2'>
        <Label
          type={LabelType.H4}
          color={ColorType.BLACK}
          text={t('vehicles')}
        />
        <div className='text-sm font-normal font-inter text-grey5'>
          {(vehicles?.length || 0) < ROW_PER_PAGE ? (
            <Trans
              i18nKey='vehicles_showing_number_10'
              values={{
                total: vehicles?.length || 0,
              }}
            />
          ) : (
            <Trans
              i18nKey='vehicles_showing_number'
              values={{
                start: (currentPage - 1) * ROW_PER_PAGE + 1,
                end:
                  currentPage * ROW_PER_PAGE > (vehicles?.length || 0)
                    ? vehicles?.length || 0
                    : ROW_PER_PAGE,
                total: vehicles?.length || 0,
              }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Card
        OnRenderTitle={renderCardTitle}
        headerButtonLabel={t('vehicles_add_vehicle')}
        headerButtonOnClick={handleAddVehicle}
        headerButtonIcon={AddPlus2}
        headerButtonIconAlt={t('icon_alt_add_vehicle')}
        dataTestId='addVehicle'
      >
        {renderNoVehicles()}
        {renderVehicles}
      </Card>
    </div>
  );
});

Vehicles.displayName = 'Vehicles';
