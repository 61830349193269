import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { AddPlus } from '../../../../assets/icons';
import { getCurrentTheme } from '../../../../stores/selectors/theme.selector';
import { Icon, Label, LabelType } from '../../../_ui';

interface IconProps {
  handleTierSize: () => void;
}

const AddTierButton = ({ handleTierSize }: IconProps) => {
  const theme = useSelector(getCurrentTheme);
  return (
    <tr>
      <td colSpan={2}>
        <div
          onClick={handleTierSize}
          className='pl-[12px] flex flex-row flex-1 h-[40px] items-center gap-[6px] cursor-pointer'
        >
          <Icon color={theme.colorPalettes.primary} src={AddPlus} />
          <Label
            text={t('create_new_tier_procing')}
            type={LabelType.BODY3}
            color={theme.colorPalettes.primary}
          />
        </div>
      </td>
    </tr>
  );
};

export default AddTierButton;
