/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAuth, useKeyPress } from '../../hooks';
import { Country } from '../../lib/country-state';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import {
  ColorType,
  Dropdown,
  FormInput,
  Label,
  LabelType,
  Snackbar,
} from '../_ui';
import { ValidationType } from '../_ui/Input.component';
import { validateEmail } from '../_ui/modal/utils';
import { getHexColorByType, getTypeByHexColor } from '../_ui/shared/Colors.g';
import { AlertPosition, AlertType } from '../_ui/snack-bar/Snack-Bar.component';
import { emailErroLabel } from './utils';

export const LoginUserNameStep = memo((props: any) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isEnterPress = useKeyPress('Enter');
  const auth = useAuth();
  const [loginUsername, setLoginUsername] = useState<string>(props.email || '');
  const emailChanged = async (evt: any) => {
    setLoginUsername(evt?.target?.value);
  };
  // the country code is coming from the dropdown
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] =
    useState<string>('1');
  const [countryPhoneList, setCountryPhoneList] = useState<any[]>([]);
  const theme = useSelector(getCurrentTheme);
  const [isLoading, setLoading] = useState(false);

  const isPhoneNumber = () => {
    return /^\d{2,}$/.test(loginUsername);
  };

  const showSnackBar = (message: string) => {
    Snackbar.show({
      message,
      position: AlertPosition.BOTTOM,
      alertType: AlertType.ERROR,
      duration: 3000,
    });
  };

  // This function determines whether a login code
  // should be sent to the user's email.
  // 1. If the loginUsername is a valid email, send login code to email.
  // 2. If the loginUsername is a phone number with a country code other than '1', send login code to email.
  const sendLoginCodeToEmail = () => {
    return (
      validateEmail(loginUsername) ||
      (isPhoneNumber() && phoneNumberCountryCode !== '1')
    );
  };

  const goNext = () => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    auth.signIn(
      validateEmail(loginUsername)
        ? loginUsername
        : `+${phoneNumberCountryCode}${loginUsername}`,
      sendLoginCodeToEmail(),
    );
  };

  useEffect(() => {
    if (auth.error && props.isActive) {
      setLoading(false);
      showSnackBar(auth.error);
      auth.cleanError('');
    }
  }, [auth.error]);

  useEffect(() => {
    if (!props.isActive) return;
    setLoading(false);
    if (auth.cognitoUser) {
      props.nextStep();
    }
  }, [auth.cognitoUser]);

  useEffect(() => {
    if (isEnterPress && props.isActive) {
      goNext();
    }
  }, [isEnterPress]);

  const isPhone = isPhoneNumber();
  // TODO: change display style
  const inputVal = loginUsername;
  // isPhone && parsePhoneNumber(`${phoneNumberCountryCode}${email}`).isValid()
  //   ? parsePhoneNumber(`${phoneNumberCountryCode}${email}`).formatNational()
  //   : email;
  const countryList = useMemo(() => {
    return Country.getAllCountries().map((obj) => ({
      ...obj,
      flagAndCode: `${obj.flag} +${obj.phonecode}`,
      id: obj.isoCode,
      selected: obj.isoCode === 'CA',
    }));
  }, []);

  if (!props.isActive) return null;
  return (
    <div>
      <div className='mt-[0.5rem] pb-8'>
        <Label
          type={LabelType.H3}
          color={ColorType.BLACK}
          text={t('login_login')}
          className='mb-1'
        />
        <Label
          type={LabelType.BODY2}
          color={ColorType.GREY6}
          text={t('login_enter_email_or_phone')}
        />
      </div>
      <div className='pb-10 flex'>
        {isPhone && (
          <div className='mr-2 h-12'>
            <Dropdown
              headerWidth={100}
              headerHeight='48px'
              label='flagAndCode'
              items={countryList}
              onItemClick={(item: any, index: number) => {
                setPhoneNumberCountryCode(item.phonecode);
              }}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-12'
              headerClassName='bg-grey1 border-grey3 rounded h-12'
              dataTestId='country-code-dropdown-button'
            />
          </div>
        )}
        <FormInput
          placeholder={t('login_email_placeholder')}
          onChange={emailChanged}
          props={{
            autoFocus: true,
            readOnly: !!props.email,
            value: inputVal,
          }}
          validationType={!isPhone ? ValidationType.EMAIL : undefined}
          showOutline={!!inputVal}
          errorLabel={t(emailErroLabel(loginUsername))}
          height='48px'
          width='100%'
          disabled={isLoading}
          dataTestId='login-email'
        />
      </div>
      <button
        disabled={isLoading}
        className={`flex rounded justify-center h-12 items-center w-full pt-3 pb-3 mb-[0.5rem] 
          ${
            isLoading
              ? 'bg-grey0 hover:bg-grey0'
              : `bg-${getTypeByHexColor(
                  theme.colorPalettes.primary,
                )} hover:bg-${getTypeByHexColor(theme.colorPalettes.hover)}`
          }`}
        onClick={goNext}
        data-testid='login-button'
      >
        <Label
          type={LabelType.LABEL_M}
          color={isLoading ? ColorType.GREY3 : ColorType.WHITE}
          text={t('login_login')}
        />
      </button>
      <div>
        <Link
          to={`/register${location.search || ''}`}
          data-testid='signup-link'
        >
          <span
            className='text-sm font-medium underline font-inter'
            style={{
              color: getHexColorByType(ColorType.DENIM),
            }}
          >
            {t('login_create_new_account')}
          </span>
        </Link>
      </div>
    </div>
  );
});

LoginUserNameStep.displayName = 'LoginUserNameStep';
