export enum EXPORT_TYPE {
  PDF = 'PDF',
  CSV = 'CSV',
}
export enum EXPORT_BY_FILTER {
  USERS = 'BY_USERS',
  CHARGERS = 'BY_CHARGERS',
  SESSIONS = 'BY_SESSION',
}

export const exportTypeDropdownData = [
  {
    label: 'pdf',
    selected: true,
    id: EXPORT_TYPE.PDF,
  },
  {
    label: 'csv',
    selected: false,
    id: EXPORT_TYPE.CSV,
  },
];

export const exportFilterDropdownData = [
  {
    label: 'by_users',
    selected: true,
    id: EXPORT_BY_FILTER.USERS,
  },
  {
    label: 'by_chargers',
    selected: false,
    id: EXPORT_BY_FILTER.CHARGERS,
  },
  {
    label: 'by_session',
    selected: false,
    id: EXPORT_BY_FILTER.SESSIONS,
  },
];

export const getEmailLabel = (label: string) => {
  return label === 'Payment Terminal'
    ? 'PAYMENT_TERMINAL'
    : label === 'Auto-start'
      ? 'AUTOSTART'
      : label;
};
