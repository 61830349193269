import { t } from 'i18next';
import _ from 'lodash';
import { useMemo } from 'react';
import { DelimitedArrayParam, useQueryParams } from 'use-query-params';

import {
  AddPlus2,
  CheckMark,
  CompanyBuilding,
  LockOpen,
  LockPrivacy,
  NoChargers,
  User,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { CommaArrayParam } from '../../constant/UrlSearchParams.constant';
import { useCompany } from '../../hooks/useCompany';
import {
  ACCESS_EDIT_TYPE,
  useUpdateAccessMutation,
} from '../../services/access.api';
import { useFetchChargersQuery } from '../../services/charger.api';
import { Charger } from '../../stores/types';
import { LocationWithChargers } from '../../stores/types/access/locationWithChargers.interface';
import { UserGroupType } from '../../stores/types/access/userGroupType.enum';
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  ColorType,
  Dropdown,
  DropdownType,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  Pill,
  Skeleton,
  Snackbar,
  getHexColorByType,
  useGlobalModalContext,
} from '../_ui';
import { AlertPosition, AlertType } from '../_ui/snack-bar/Snack-Bar.component';
import { AccessEditor } from './AccessEditor.component';
import PrivateChargerWithLocation from './PrivateChargerWithLocation';

export const Access = () => {
  const [chargerListQuery, setChargerListQuery] = useQueryParams({
    chargerId: CommaArrayParam,
    userId: DelimitedArrayParam,
  });

  // Refetch & get charger id from url (if present) when url query changes
  const selectedFilter = useMemo(() => {
    let filterList: any[] = [];
    if (chargerListQuery.chargerId && chargerListQuery.chargerId.length) {
      filterList = chargerListQuery.chargerId.map((item) => {
        return { type: 'CHARGER', id: item };
      });
    }
    if (chargerListQuery.userId && chargerListQuery.userId.length) {
      const usersList = chargerListQuery.userId.map((item: any) => {
        return {
          type: item.startsWith('@')
            ? UserGroupType[UserGroupType.DOMAIN_NAME]
            : UserGroupType[UserGroupType.INDIVIDUAL],
          id: item,
        };
      });
      filterList = [...filterList, ...usersList];
    }
    return filterList;
  }, [chargerListQuery]);

  const { chargers, isChargerFetchingSuccess, isChargerLoading } =
    useFetchChargersQuery(
      { includeAcl: true },
      {
        selectFromResult: ({ data, isSuccess, isFetching }) => {
          return {
            chargers: data ? data.entities : [],
            isChargerFetchingSuccess: isSuccess,
            isChargerLoading: isFetching,
          };
        },
      },
    );
  const [triggerEditAccess, { isLoading, isSuccess }] =
    useUpdateAccessMutation();

  const { showModal } = useGlobalModalContext();

  const chargersGroupByLocation = _.groupBy(chargers, 'location.name');

  const showToast = (message: string) => {
    Snackbar.show({
      message,
      position: AlertPosition.BOTTOM_LEFT,
      alertType: AlertType.DEFAULT,
      duration: 5000,
      messageLabelType: LabelType.LABEL_S_MEDIUM,
      messageLabelColor: ColorType.BLACK,
      icon: CheckMark,
      iconColor: getHexColorByType(ColorType.POSITIVE),
    });
  };

  const chargersTotalCountByLocation = Object.fromEntries(
    Object.entries(chargersGroupByLocation).map((item) => [
      item[0],
      item[1].length,
    ]),
  );

  const publicChargersWithLocation = Object.entries(chargersGroupByLocation)
    .map((item) => ({
      ...item,
      chargers: item[1].filter((child) => child.access === 'Public'),
    }))
    .filter((item) => item.chargers.length > 0);

  const privateChargersWithLocation = Object.entries(chargersGroupByLocation)
    .map((item) => ({
      ...item,
      chargers: item[1].filter((child) => child.access === 'Private'),
    }))
    .filter((item) => item.chargers.length > 0);

  const accessRulesMap = new Map<string, LocationWithChargers[]>();
  const accessRulesMapWithAutoStartEnabled = new Map<
    string,
    LocationWithChargers[]
  >();

  privateChargersWithLocation.forEach((pCWL) => {
    pCWL.chargers.forEach((charger) => {
      const { location } = charger;
      const accessRules = JSON.stringify({ accessRules: charger.accessRules });
      if (charger.autoStartEnabled) {
        const existingAccessRuleWithAutoStartEnabled =
          accessRulesMapWithAutoStartEnabled.get(accessRules);

        if (existingAccessRuleWithAutoStartEnabled) {
          const existingLocation = existingAccessRuleWithAutoStartEnabled.find(
            (locationWithChargers: any) =>
              locationWithChargers.locationName === location?.name,
          );
          if (existingLocation) {
            existingLocation.chargers.push(charger);
          } else {
            existingAccessRuleWithAutoStartEnabled.push({
              // @ts-ignore
              locationName: location?.name,
              chargers: [charger],
            });
          }
        } else {
          accessRulesMapWithAutoStartEnabled.set(accessRules, [
            { locationName: location?.name, chargers: [charger] },
          ] as LocationWithChargers[]);
        }
      } else {
        const existingAccessRule = accessRulesMap.get(accessRules);
        if (existingAccessRule) {
          const existingLocation = existingAccessRule.find(
            (locationWithChargers: any) =>
              locationWithChargers.locationName === location?.name,
          );
          if (existingLocation) {
            existingLocation.chargers.push(charger);
          } else {
            existingAccessRule.push({
              // @ts-ignore
              locationName: location?.name,
              chargers: [charger],
            });
          }
        } else {
          accessRulesMap.set(accessRules, [
            { locationName: location?.name, chargers: [charger] },
          ] as LocationWithChargers[]);
        }
      }
    });
  });

  const chargersFilterDropdownList = useMemo(() => {
    return privateChargersWithLocation.map((key: any) => {
      const children = key.chargers.map((charger: Charger) => {
        return {
          id: charger.name,
          label: charger.name,
          selected: selectedFilter
            .filter((item) => item.type === 'CHARGER')
            .map((item) => item.id)
            .includes(charger.name as string),
        };
      });

      return {
        id: key[0],
        label: key[0],
        selected: children?.every((child: any) => child.selected) || false,
        children: children || [],
      };
    });
  }, [selectedFilter, privateChargersWithLocation]);

  const handleChargersFilterChange = (items: any) => {
    const existingSelectedFilter = [...selectedFilter].filter(
      (ele) => ele.type === 'CHARGER',
    );
    const selectedChargerFilters: any[] = [];
    items.forEach((item: any) => {
      item.children.forEach((child: any) => {
        const filterObj = { type: 'CHARGER', id: child.id };
        if (child.selected) {
          if (!_.some(selectedFilter, filterObj)) {
            selectedChargerFilters.push(filterObj);
          }
        } else if (_.some(selectedFilter, filterObj)) {
          _.remove(existingSelectedFilter, { id: child.id });
        }
      });
    });
    setChargerListQuery({
      chargerId: [...existingSelectedFilter, ...selectedChargerFilters].map(
        (ele) => ele.id,
      ),
    });
  };

  const usersGroupedByUserGroupType: any =
    accessRulesMap &&
    _.groupBy(
      Array.from(accessRulesMap.keys()).flatMap((key) =>
        JSON.parse(key).accessRules.flatMap((ar: any) =>
          ar.userGroups.map((ug: any) => ug),
        ),
      ),
      'userGroupSelector.type',
    );

  const userFilterDropdownList = useMemo(() => {
    return (
      usersGroupedByUserGroupType &&
      Object.entries(usersGroupedByUserGroupType).map(([key, value]) => {
        // @ts-ignore
        const children = _.uniqWith(value, _.isEqual).map((userGroup: any) => {
          return {
            id: userGroup.userGroupSelector?.value,
            label: userGroup.userGroupSelector?.value,
            selected: selectedFilter
              .filter((item) => item.type !== 'CHARGER')
              .map((item) => item.id)
              .includes(userGroup.userGroupSelector?.value),
          };
        });

        return {
          id: key,
          label:
            key === UserGroupType[UserGroupType.INDIVIDUAL]
              ? t('access_drivers')
              : t('access_company'),
          selected: children?.every((child: any) => child.selected) || false,
          children: children || [],
        };
      })
    );
  }, [selectedFilter, usersGroupedByUserGroupType]);

  const handleUserFilterChange = (items: any) => {
    const existingSelectedFilter = [...selectedFilter].filter(
      (ele) => ele.type !== 'CHARGER',
    );
    const selectedUserFilters: any[] = [];
    items.forEach((item: any) => {
      item.children.forEach((child: any) => {
        const filterObj = {
          type: child.id.startsWith('@')
            ? UserGroupType[UserGroupType.DOMAIN_NAME]
            : UserGroupType[UserGroupType.INDIVIDUAL],
          id: child.id,
        };
        if (child.selected) {
          if (!_.some(selectedFilter, filterObj)) {
            selectedUserFilters.push(filterObj);
          }
        } else if (_.some(selectedFilter, filterObj)) {
          _.remove(existingSelectedFilter, { id: child.id });
        }
      });
    });

    setChargerListQuery({
      userId: [...existingSelectedFilter, ...selectedUserFilters].map(
        (ele) => ele.id,
      ),
    });
  };

  const filteredResults = (): Map<string, LocationWithChargers[]> => {
    const chargerFilter = selectedFilter
      .filter((i) => i.type === 'CHARGER')
      .map((i) => i.id);
    const userFilter = selectedFilter
      .filter((i) => i.type !== 'CHARGER')
      .map((i) => i.id);
    if (accessRulesMap) {
      if (chargerFilter.length === 0 && userFilter.length === 0) {
        return accessRulesMap;
      }
      if (chargerFilter.length > 0 && userFilter.length > 0) {
        return new Map(
          // @ts-ignore
          [...accessRulesMap].filter(([k, v]) => {
            const accessRules = JSON.parse(k).accessRules.filter((it: any) => {
              const users = it.userGroups.filter((c: any) =>
                userFilter.includes(c.userGroupSelector.value),
              );
              return users.length > 0;
            });
            const locationList = v.filter((it: any) => {
              const chargerList = it.chargers.filter((c: any) =>
                chargerFilter.includes(c.name),
              );
              return chargerList.length > 0;
            });
            return locationList.length > 0 && accessRules.length > 0;
          }),
        );
      }
      if (chargerFilter.length > 0) {
        return new Map(
          // @ts-ignore
          [...accessRulesMap].filter(([k, v]) => {
            const locationList = v.filter((it: any) => {
              const chargerList = it.chargers.filter((c: any) =>
                chargerFilter.includes(c.name),
              );
              return chargerList.length > 0;
            });
            return locationList.length > 0;
          }),
        );
      }
      if (userFilter.length > 0) {
        return new Map(
          // @ts-ignore
          [...accessRulesMap].filter(([k, v]) => {
            const accessRules = JSON.parse(k).accessRules.filter((it: any) => {
              const users = it.userGroups.filter((c: any) =>
                userFilter.includes(c.userGroupSelector.value),
              );
              return users.length > 0;
            });
            return accessRules.length > 0;
          }),
        );
      }
    }
    return new Map<string, LocationWithChargers[]>();
  };
  const filteredAccessRulesMapWithAutoStartEnabled = (): Map<
    string,
    LocationWithChargers[]
  > => {
    const chargerFilter = selectedFilter
      .filter((i) => i.type === 'CHARGER')
      .map((i) => i.id);
    const userFilter = selectedFilter
      .filter((i) => i.type !== 'CHARGER')
      .map((i) => i.id);
    if (accessRulesMapWithAutoStartEnabled) {
      if (chargerFilter.length === 0 && userFilter.length === 0) {
        return accessRulesMapWithAutoStartEnabled;
      }
      if (chargerFilter.length > 0 && userFilter.length > 0) {
        return new Map(
          // @ts-ignore
          [...accessRulesMapWithAutoStartEnabled].filter(([k, v]) => {
            const accessRules = JSON.parse(k).accessRules.filter((it: any) => {
              const users = it.userGroups.filter((c: any) =>
                userFilter.includes(c.userGroupSelector.value),
              );
              return users.length > 0;
            });
            const locationList = v.filter((it: any) => {
              const chargerList = it.chargers.filter((c: any) =>
                chargerFilter.includes(c.name),
              );
              return chargerList.length > 0;
            });
            return locationList.length > 0 && accessRules.length > 0;
          }),
        );
      }
      if (chargerFilter.length > 0) {
        return new Map(
          // @ts-ignore
          [...accessRulesMapWithAutoStartEnabled].filter(([k, v]) => {
            const locationList = v.filter((it: any) => {
              const chargerList = it.chargers.filter((c: any) =>
                chargerFilter.includes(c.name),
              );
              return chargerList.length > 0;
            });
            return locationList.length > 0;
          }),
        );
      }
      if (userFilter.length > 0) {
        return new Map(
          // @ts-ignore
          [...accessRulesMapWithAutoStartEnabled].filter(([k, v]) => {
            const accessRules = JSON.parse(k).accessRules.filter((it: any) => {
              const users = it.userGroups.filter((c: any) =>
                userFilter.includes(c.userGroupSelector.value),
              );
              return users.length > 0;
            });
            return accessRules.length > 0;
          }),
        );
      }
    }
    return new Map<string, LocationWithChargers[]>();
  };

  const handleEditClick = (chargerIds: string[], accessRules: any) => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('access_update_modal_title'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => (
        <AccessEditor
          chargersGroupByLocation={chargersGroupByLocation}
          chargerIds={chargerIds}
          accessRules={accessRules}
          isAdd={false}
        />
      ),
      shouldCloseOnOverlayClick: false,
    });
  };

  const handleAddClick = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('access_add_modal_title'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => (
        <AccessEditor
          chargersGroupByLocation={chargersGroupByLocation}
          chargerIds={[]}
          accessRules={[]}
          isAdd
        />
      ),
      shouldCloseOnOverlayClick: false,
    });
  };

  const { userScope } = useCompany();
  const onDeleteAccess = (chargerIds: string[]) => {
    triggerEditAccess({
      body: {
        batch: [
          {
            chargerIds,
            accessRules: [
              {
                actions: [{ action: 'charger-start-session' }],
                userGroups: [{ userGroupId: 'everyone' }],
              },
            ],
          },
        ],
      },
      scope: userScope!,
      updateType: ACCESS_EDIT_TYPE.DELETE,
    });
  };

  const handleDeleteAccess = (chargerIds: string[]) => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      title: t('access_delete_group_title'),
      width: '400px',
      height: '220px',
      message: t('access_delete_group_body'),
      iconColor: getHexColorByType(ColorType.NEGATIVE),
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          dataTestId: 'cancel-access-delete-button',
        },
        {
          label: t('delete'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          onClick: () => onDeleteAccess(chargerIds),
          dataTestId: 'delete-access-button',
        },
      ],
    });
  };

  const handleFilterItemClick = (item: any) => {
    const filterCharger = [...selectedFilter].filter((i) => i.id !== item.id);
    const chargersList = filterCharger.filter((ele) => ele.type === 'CHARGER');
    const usersList = filterCharger.filter((ele) => ele.type !== 'CHARGER');
    setChargerListQuery({
      chargerId: chargersList.length
        ? chargersList.map((ele) => ele.id)
        : undefined,

      userId: usersList.length ? usersList.map((ele) => ele.id) : undefined,
    });
  };

  const handleClearAllClick = () => {
    setChargerListQuery({
      chargerId: undefined,
      userId: undefined,
    });
  };

  const renderClearAllButton = () => {
    if (selectedFilter.length > 0) {
      return (
        <button
          type='button'
          className='pl-4 text-left leading-3'
          onClick={handleClearAllClick}
        >
          <Label
            type={LabelType.DROPDOWN_ITEM_SELECTED}
            text={t('session_clear_all')}
            color={ColorType.DENIM}
          />
        </button>
      );
    }
    return null;
  };

  const renderSelectedFilter = () => {
    if (selectedFilter.length === 0) {
      return <div />;
    }
    return (
      <div className='flex flex-row gap-2 mt-5 flex-wrap'>
        {selectedFilter.map((item: any) => {
          return (
            <Pill
              key={item.id}
              iconLeft={
                item.type === UserGroupType[UserGroupType.INDIVIDUAL]
                  ? User
                  : item.type === 'CHARGER'
                  ? null
                  : CompanyBuilding
              }
              label={item.id}
              hasCloseButton
              onClick={() => handleFilterItemClick(item)}
              translationOn
            />
          );
        })}
        {renderClearAllButton()}
      </div>
    );
  };

  const renderDropdownFilters = () => {
    return (
      <div className='flex flex-col'>
        <div className='flex gap-3'>
          <Dropdown
            placeholder={t('access_chargers_dropdown_title')}
            placeholderLabelType={LabelType.DROPDOWN_HEADER}
            headerWidth='auto'
            items={chargersFilterDropdownList}
            onItemClick={handleChargersFilterChange}
            type={DropdownType.CHECKBOX_TREE}
            showFooter
            iconAlt={t('icon_alt_open_all_chargers_dropdown')}
          />
          <Dropdown
            placeholder={t('access_users_dropdown_title')}
            placeholderLabelType={LabelType.DROPDOWN_HEADER}
            type={DropdownType.CHECKBOX_TREE}
            headerWidth='auto'
            items={userFilterDropdownList}
            onItemClick={handleUserFilterChange}
            showFooter
            iconAlt={t('icon_alt_open_all_user_dropdown')}
          />
        </div>
        <div className='flex flex-row'>{renderSelectedFilter()}</div>
      </div>
    );
  };

  const renderPrivateChargersHeader = () => {
    return (
      <div className='flex flex-row items-center'>
        <div className='flex'>
          <Label
            text={t('access_private_chargers')}
            type={LabelType.H4}
            color={ColorType.BLACK}
          />
        </div>
        <div className='flex ml-auto'>
          <Button
            dataTestId='button-make-private-charger'
            icon={AddPlus2}
            label={t('access_add_rule')}
            type={ButtonType.TERTIARY}
            size={ButtonSize.SMALL}
            onClick={handleAddClick}
          />
        </div>
      </div>
    );
  };

  const renderPrivateChargerSkeleton = () => {
    return (
      <div>
        {Array(4)
          .fill(0)
          .map((item) => (
            <div className='grid grid-cols-2 border-b border-grey2'>
              <div className='pt-2'>
                <div>
                  <div className='items-center px-2 py-0'>
                    <div className='flex flex-row gap-2'>
                      <Skeleton height='h-5' width='w-20' />
                      <Skeleton height='h-5' width='w-20' />
                    </div>
                  </div>
                </div>
                <div className='flex flex-row gap-1 py-2 px-2 flex-wrap'>
                  <Pill isLoading={isChargerLoading} />
                  <Pill isLoading={isChargerLoading} />
                </div>
              </div>
              <div className='flex flex-row'>
                <div>
                  <div className='flex flex-row gap-1 py-2 px-2 flex-wrap'>
                    <Pill isLoading={isChargerLoading} />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const renderPrivateChargerWithLocation = () => {
    const items: any = [];
    filteredResults().forEach((value: LocationWithChargers[], key: string) => {
      items.push(
        <PrivateChargerWithLocation
          value={value}
          mapKey={key}
          chargersTotalCountByLocation={chargersTotalCountByLocation}
          handleDeleteAccess={handleDeleteAccess}
          handleEditClick={handleEditClick}
          autoStartEnabled={false}
        />,
      );
    });
    filteredAccessRulesMapWithAutoStartEnabled().forEach(
      (value: LocationWithChargers[], key: string) => {
        items.push(
          <PrivateChargerWithLocation
            value={value}
            mapKey={key}
            chargersTotalCountByLocation={chargersTotalCountByLocation}
            handleDeleteAccess={handleDeleteAccess}
            handleEditClick={handleEditClick}
            autoStartEnabled
          />,
        );
      },
    );
    return items;
  };

  const renderPrivateChargersGrid = () => {
    if (isChargerFetchingSuccess && chargers && chargers.length === 0) {
      return null;
    }

    if (
      isChargerFetchingSuccess &&
      privateChargersWithLocation.length === 0 &&
      accessRulesMap.size === 0
    ) {
      return (
        <div className='pt-4'>
          {renderPrivateChargersHeader()}
          <div className='flex flex-col justify-center h-52'>
            <div className='flex justify-center'>
              <Icon
                className='grey5'
                src={LockOpen}
                size={IconSize.SIZE_48x48}
              />
            </div>
            <div className='flex justify-center my-2'>
              <Label
                text={t('access_no_private_chargers')}
                type={LabelType.H4}
                color={ColorType.GREY5}
              />
            </div>
            <div className='flex justify-center mt-2'>
              <Label
                text={t('access_no_private_chargers_message')}
                type={LabelType.BODY3}
                color={ColorType.GREY5}
                className='text-base'
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className='pt-5'>
        {renderPrivateChargersHeader()}
        <div className='flex flex-col mt-4'>
          <div className='flex flex-row'>{renderDropdownFilters()}</div>
          <div className='mt-6'>
            <div className='grid grid-cols-2'>
              <Label
                text={t('chargers')}
                className='p-2 border-t border-b border-grey2'
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
              />
              <Label
                text={t('access_who_can_access')}
                className='p-2 border-t border-b border-grey2'
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
              />
            </div>
            {isChargerLoading
              ? renderPrivateChargerSkeleton()
              : renderPrivateChargerWithLocation()}
          </div>
        </div>
      </div>
    );
  };

  const rendePublicChargerSkeleton = () => {
    return (
      <div>
        {Array(4)
          .fill(0)
          .map((item) => (
            <>
              <div className='items-center px-2 py-0'>
                <div className='flex flex-row gap-2'>
                  <Skeleton height='h-5' width='w-40' />
                </div>
              </div>
              <div className='flex flex-row gap-1 py-2 px-2 flex-wrap'>
                <Pill isLoading={isChargerLoading} />
                <Pill isLoading={isChargerLoading} />
              </div>
            </>
          ))}
      </div>
    );
  };

  const renderChargerWithLocation = () => {
    const items: any = [];
    publicChargersWithLocation.forEach((key) => {
      items.push(
        <div>
          <div className='items-center px-2 py-0'>
            <div className='flex flex-row gap-2'>
              <Label
                text={key[0]}
                type={LabelType.LABEL_S_MEDIUM}
                color={ColorType.BLACK}
              />
              <Label
                text={`(${key.chargers.length}/${
                  chargersTotalCountByLocation[key[0]]
                })`}
                type={LabelType.LABEL_S_MEDIUM}
                color={ColorType.GREY5}
              />
            </div>
          </div>
          <div className='flex flex-row gap-1 py-2 px-2 flex-wrap'>
            {key.chargers.map((charger: Charger) => {
              return (
                <Pill
                  key={charger.name}
                  label={charger.name}
                  labelColor={ColorType.GREY6}
                />
              );
            })}
          </div>
        </div>,
      );
    });
    return items;
  };

  const renderPublicChargers = () => {
    if (isChargerFetchingSuccess && chargers && chargers.length === 0) {
      return (
        <div className='flex flex-col justify-center h-52'>
          <div className='flex justify-center'>
            <Icon
              className='grey5'
              src={NoChargers}
              size={IconSize.SIZE_48x48}
            />
          </div>
          <div className='flex justify-center my-2'>
            <Label
              text={t('access_no_chargers')}
              type={LabelType.H4}
              color={ColorType.GREY5}
            />
          </div>
          <div className='flex justify-center mt-2'>
            <Label
              text={t('access_no_chargers_message')}
              type={LabelType.BODY3}
              color={ColorType.GREY5}
              className='text-base'
            />
          </div>
        </div>
      );
    }

    if (
      isChargerFetchingSuccess &&
      publicChargersWithLocation.length === 0 &&
      accessRulesMap.size > 0
    ) {
      return (
        <div className='flex flex-col justify-center h-52'>
          <div className='flex justify-center'>
            <Icon
              className='grey5'
              src={LockPrivacy}
              size={IconSize.SIZE_48x48}
            />
          </div>
          <div className='flex justify-center my-2'>
            <Label
              text={t('access_no_public_chargers')}
              type={LabelType.H4}
              color={ColorType.GREY5}
            />
          </div>
          <div className='flex justify-center mt-2'>
            <Label
              text={t('access_no_public_chargers_message')}
              type={LabelType.BODY3}
              color={ColorType.GREY5}
              className='text-base'
            />
          </div>
        </div>
      );
    }

    return (
      <div className='grid grid-cols-2'>
        <div>
          <Label
            text={t('chargers')}
            className='p-2 border-t border-b border-grey2'
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
          />
          <div className='pt-2'>
            {isChargerLoading
              ? rendePublicChargerSkeleton()
              : renderChargerWithLocation()}
          </div>
        </div>
        <div>
          <Label
            text={t('access_who_can_access')}
            className='p-2 border-t border-b border-grey2'
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
          />
          <div className='p-2'>
            <Label
              text={t('access_everyone_label')}
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.BLACK}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card>
      <div className='divide-y divide-grey2'>
        <div className='p-0'>
          <div className='flex flex-row items-center mb-4'>
            <div className='flex'>
              <Label
                text={t('access_public_chargers')}
                type={LabelType.H4}
                color={ColorType.BLACK}
                dataTestId='access-public-charger-title'
              />
            </div>
          </div>
          {renderPublicChargers()}
        </div>
        {renderPrivateChargersGrid()}
      </div>
    </Card>
  );
};
